import BaseButton from "Engine/base/ui/BaseButton";
import MoneyFormat from 'Engine/base/moneyFormat/MoneyFormat';

export default class RespinButton extends BaseButton {
    constructor(data, displayData) {
        super(data, displayData);

        this._value = 0;
    }

    init() {
        this.id = +this.name.replace('respinButton_', '');
        this.valueText = this.getChildByName('valueText');
        this.titleText = this.getChildByName('ui_respinButton_label');

        super.init();
    }

    setSate(frameIndex) {
        super.setSate(frameIndex);
        let alpha = frameIndex === 4 ? 0.5 : 1;
        this.valueText.alpha = alpha;
        this.titleText.alpha = alpha;
    }

    get value() {
        return this._value;
    }

    set value(value) {
        this._value = value;
        this.valueText.text = this._value ? MoneyFormat.format(this.value, false, false) : '';
    }
}
