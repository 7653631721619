import BaseController from './basePlayerController';
import { OP_WRAPPER_LOCALIZATION_PREFIX } from '../../../utils/constants';

export default class ControllerWin extends BaseController {
  constructor({ container, ...props }) {
    super({ ...props });
    this._eElementsTypes = {
      EET_LABEL: 'label',
      EET_VALUE: 'value',
    };
    this._localizationKeys = {
      WIN: `${OP_WRAPPER_LOCALIZATION_PREFIX}.userInterface.win_label`,
      LAST_WIN: `${OP_WRAPPER_LOCALIZATION_PREFIX}.userInterface.win_label_last_win`
    };
    this._localizations = {
      [this._eElementsTypes.EET_LABEL]: this._localizationKeys.WIN,
    };
    this._adaptiveElements = [this._eElementsTypes.EET_LABEL, this._eElementsTypes.EET_VALUE];

    this._lastWin = '';
    this.init(container);
  }

  setValue(value) {
    const numValue = Number(value);

    if (numValue) {
      this._showWin(numValue);
    } else {
      this._showLastWin();
    }
  }

  _showLastWin() {
    this._localizations[this._eElementsTypes.EET_LABEL] = this._localizationKeys.LAST_WIN;
    this.set(this._eElementsTypes.EET_LABEL, this._getLocalization(this._localizations[this._eElementsTypes.EET_LABEL]));
    this.set(this._eElementsTypes.EET_VALUE, this._lastWin);
  }

  _showWin(value) {
    const formattedValue = this._moneyFormat(value);
    this._lastWin = formattedValue;
    this._localizations[this._eElementsTypes.EET_LABEL] = this._localizationKeys.WIN;
    this.set(this._eElementsTypes.EET_LABEL, this._getLocalization(this._localizations[this._eElementsTypes.EET_LABEL]));
    this.set(this._eElementsTypes.EET_VALUE, formattedValue);
  }

  set lastWin(value) {
    this._lastWin = value ? this._moneyFormat(value) : '';
  }

  _getMarkup() {
    return `<div class="ui-container__title-desc-col ui-container__win_container">
          <label id=${this.interactiveElementsIds[this._eElementsTypes.EET_LABEL]}>
            ${this._getLocalization(this._localizations[this._eElementsTypes.EET_LABEL])}
          </label>
          <span id=${this.interactiveElementsIds[this._eElementsTypes.EET_VALUE]}>
          </span>
        </div>`
  }
}
