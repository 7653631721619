import FlashLib from 'flashlib_onlyplay';
import GlobalDispatcher from 'Engine/events/GlobalDispatcher';
import StatesManager from 'Engine/base/states/StatesManager';
import EntryPoint from '../libs/game-engine/src/EntryPoint';
import * as PIXI from 'pixi.js';

export default class GameInfoContainer extends FlashLib.MovieClip {
  constructor(data, displayData) {
    super(data, displayData);

    this.init();
    this.addListeners();
    this.hide();
  }

  init() {
    this.container = this.getChildByName('container');

    this.content = this.container ? this.container.getChildByName('content') : this.getChildByName('content');

    this.controls = this.getChildByName('controls');
    this.pagesCount = this.content.layersData[0].frameCount;

    let range = [];
    for (let i = 1; i <= this.pagesCount; i++) {
      range.push(i);
    }

    this.controls.setRange(range);
  }

  addListeners() {
    GlobalDispatcher.add('ui:paytableClick', this.show.bind(this));
    GlobalDispatcher.add('gameInfo:backClick', this.hide.bind(this));
    GlobalDispatcher.add('ui:spinClick', this.hide.bind(this));
    window.OPWrapperService.eventManager.add(
      window.OPWrapperService.eventManager.types.EET_SCALE_MANAGER_RESIZE,
      this.onGameResized,
      this
    );

  }

  onGameResized(data) {
    if (this.container) {
      const isMobile = PIXI.isMobile.any;
      const newFrameIndex = !isMobile || data.isLandscape ? 2 : 1;
      this.container.goToFrame(newFrameIndex);
      this.init();
    }
  }

  show() {
    this.visible = true;
  }

  hide() {
    this.visible = false;
  }
}
