import FlashLib from "flashlib_onlyplay";
import GlobalDispatcher from "Engine/events/GlobalDispatcher";

export default class RespinInReelInfoContainer extends FlashLib.MovieClip {
    constructor(data, displayData) {
        super(data, displayData);

        this.inReelInfos = [];

        this.init();
        this.addListeners();
    }

    init() {
        for (let i = 1; i <= 5; i++) {
            let inReelInfo = this.getChildByName(`inReelInfo_${i}`);
            inReelInfo.on('mouseover', this.onMouseOver, this);
            inReelInfo.on('mouseout', this.onMouseOut, this);
            //inReelInfo.on('pointertap', this.onMouseClick, this);
            inReelInfo.hide();
            inReelInfo.value = 10;
            this.inReelInfos.push(inReelInfo);
        }
    }

    addListeners() {
        GlobalDispatcher.add('model:respinEnabled', this.onRespinEnabledChanged, this);
    }

    onRespinEnabledChanged(event) {
        this.setActive(event.params);
    }

    onMouseOver(event) {
        event.currentTarget.show();
        GlobalDispatcher.dispatch('respinInReelInfo:show', event.currentTarget.id);
    }

    onMouseOut(event) {
        event.currentTarget.hide();
        GlobalDispatcher.dispatch('respinInReelInfo:hide', event.currentTarget.id);
    }

    onMouseClick(event) {
        event.currentTarget.hide();
        GlobalDispatcher.dispatch('startRespin', event.currentTarget.id - 1);
    }

    setData(data) {
        this.inReelInfos.forEach((inReelInfo, index)=>{
            inReelInfo.value = data[index];
        });
    }

    setActive(value) {
        this.inReelInfos.forEach((button)=>{
            button.enabled = value;
            button.hide();
        });
    }
}
