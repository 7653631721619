export default class EventEmitter {
  constructor() {
    this._listeners = {};
    this._callStack = [];
  }

  on(event, callback) {
    return this._addListener(event, { callback, once: false });
  }

  once(event, callback) {
    return this._addListener(event, { callback, once: true });
  }

  emit(event, data) {
    this._checkToCreateEventArray(event);
    for (let i = 0; i < this._listeners[event].length; i++) {
      this._callStack.push(this._listeners[event][i].callback);

      if (this._listeners[event][i].once) {
        this._removeListener(event, this._listeners[event][i]);
        i--;
      }
    }

    this._callStack.forEach(callback => callback(data));
    this._callStack = [];
  }

  removeAllListeners() {
    this._listeners = {};
  }

  _removeListener(event, callback) {
    this._listeners[event] = this._listeners[event].filter(listener => listener !== callback);
  }

  _addListener(event, callback) {
    this._checkToCreateEventArray(event);

    this._listeners[event].push(callback);
    return () => this._removeListener(event, callback);
  }

  _checkToCreateEventArray(event) {
    if (!this._listeners.hasOwnProperty(event)) this._listeners[event] = [];
  }
}
