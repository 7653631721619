export const errorTypes = {
  NO_CONNECTION: {
    CODE: '0000',
    SHOW_OK_BUTTON: true,
    NEED_RELOAD: true,
  },
  ASSETS_ERROR: {
    CODE: '0001',
    SHOW_OK_BUTTON: true,
    NEED_RELOAD: true,
  },
  SOCKET_DISCONNECTED: {
    CODE: '0002',
    SHOW_OK_BUTTON: true,
    NEED_RELOAD: true,
  },
  SOCIAL_FALLBACK_NOT_SPECIFIED: {
    CODE: '0003',
    SHOW_OK_BUTTON: true,
    NEED_RELOAD: true,
  },
  URL_INIT_PARAMS: {
    CODE: '0004',
    SHOW_OK_BUTTON: true,
    NEED_RELOAD: true,
  },
  BRANDED_ASSETS_NOT_FOUND: {
    CODE: '0005',
    SHOW_OK_BUTTON: true,
    NEED_RELOAD: true,
  },
  INCORRECT_GAME_VERSION: {
    CODE: '0006',
    SHOW_OK_BUTTON: true,
    NEED_RELOAD: true,
  },
  REQUEST_FAILED: {
    CODE: '0007',
    SHOW_OK_BUTTON: true,
    NEED_RELOAD: true,
  },
  TO_MANY_ACTIVE_WEBGL_CONTEXTS: {
    CODE: '0008',
    SHOW_OK_BUTTON: true,
    NEED_RELOAD: true,
  },
  INSUFFICIENT_BALANCE_CLIENT: {
    CODE: '9001',
    SHOW_OK_BUTTON: true,
    NEED_RELOAD: false,
  },
  SESSION_EXPIRED: {
    CODE: 2401,
    SHOW_OK_BUTTON: true,
    NEED_RELOAD: true,
  },
  SESSION_BUSY: {
    CODE: 2402,
    SHOW_OK_BUTTON: true,
    NEED_RELOAD: true,
  },
  INSUFFICIENT_BALANCE: {
    CODE: 9001,
    SHOW_OK_BUTTON: true,
    NEED_RELOAD: true,
  },
  MAX_BET_LIMIT: {
    CODE: 9002,
    SHOW_OK_BUTTON: true,
    NEED_RELOAD: true,
  },
  OPERATOR_SESSION_EXPIRED: {
    CODE: 9109,
    SHOW_OK_BUTTON: false,
    NEED_RELOAD: true,
    SHOW_HOME_BUTTON: true
  },
  SESSION_EXPIRED_IGHub: {
    CODE: 8456,
    SHOW_OK_BUTTON: false,
    NEED_RELOAD: true,
    SHOW_HOME_BUTTON: true
  }
};
