import FlashLib from 'flashlib_onlyplay';
import EntryPoint from 'Engine/EntryPoint';
import { makeHitArea } from 'Engine/utils/makeHitArea';

export default class BaseButton extends FlashLib.MovieClip {
    constructor(data, displayItemData) {
        super(data, displayItemData);
        this._pointerPosition = {
            x: 0,
            y: 0
        };
        this._events = {
            POINTER_OVER: 'pointerover',
            POINTER_OUT: 'pointerout',
            POINTER_DOWN: 'pointerdown',
            POINTER_UP: 'pointerup',
            POINTER_TAP: 'pointertap', // 'pointertap' returning 'click' type
        };

        this._backwardCapabilityEvents = {
            [this._events.POINTER_OUT]: 'mouseout',
            [this._events.POINTER_OVER]: 'mouseover',
            [this._events.POINTER_DOWN]: 'mousedown',
            [this._events.POINTER_UP]: 'mouseup',
            [this._events.POINTER_TAP]: 'click',
        };

        this.frames = {
            DEFAULT: 1,
            HOVER: 2,
            PRESSED: 3,
            DISABLED: 4,
            [this._events.POINTER_OUT]: 1,
            [this._events.POINTER_OVER]: 2,
            [this._events.POINTER_DOWN]: 3,
            ['click']: EntryPoint.mobile ? 1 : 2, //hack because 'pointertap' returning 'click' type
        };

        this._actions = {};
        this._skippedEvent = null;

        this.init();
        this.addListeners();
        this.enabled = false;
        makeHitArea(this);
    }

    init() {
        this.interactive = true;
        this.cursor = 'pointer';
    }

    addListeners() {
        for (const key in this._events) {
            this.addEventListener(this._events[key], this._eventHandler.bind(this));
        }

        window.addEventListener('pointermove', this._savePointerPosition.bind(this));
    }

    _savePointerPosition(e) {
        this._pointerPosition = {
            x: e.pageX,
            y: e.pageY,
        };
    }

    _eventHandler(event) {
        if (!this._enabled) return;

        const frame = this.frames[event.type] || this.frames.DEFAULT;
        this.goToFrame(frame);
        this._handleActions(event);
    }

    _handleActions(e) {
        let actions = [this.actions[e.type], this.actions[this._backwardCapabilityEvents[e.type]]];
        actions.forEach(actionData => {
            if (actionData) {
                if (actionData.callback) {
                    actionData.callback.call(actionData.context, actionData.params);
                }
            }
        });
    }

    _getMouseCollision() {
        let intersect = false;
        const bounds = this.getBounds();
        if (bounds.x < this._pointerPosition.x && this._pointerPosition.x < (bounds.x + bounds.width) &&
          bounds.y < this._pointerPosition.y && this._pointerPosition.y < (bounds.y + bounds.height)) {
            intersect = true;
        }
        // console.log(`${this.displayData.name + ' intersecting:   %c' +  intersect}`, `background-color: ${intersect ? 'green' : 'crimson'}; padding: 4px`);
        return intersect;
    }

    get actions() {
        return this._actions;
    }

    set actions(value) {
        this._actions = value;
    }

    get enabled() {
        return this._enabled;
    }

    set enabled(value) {
        if (this._enabled === value) return;

        if (value) {
            this.cursor = 'pointer';
            this.interactive = true;
            const collision = this._getMouseCollision();
            this.goToFrame(collision ? this.frames.HOVER : this.frames.DEFAULT);
        } else {
            this.interactive = false;
            this.cursor = null;
            this.goToFrame(this.frames.DISABLED);
        }
        this._enabled = value;
    }
}
