import BaseState from "Engine/base/states/BaseState";
import GlobalDispatcher from "Engine/events/GlobalDispatcher";
import EntryPoint from 'Engine/EntryPoint';

export default class FreeSpinsOutState extends BaseState {

    constructor(stateData) {
        super(stateData);
    }

    addListeners() {
        super.addListeners();
        GlobalDispatcher.add('freeSpinsWindowContainer:stopState', this.stopNow, this)
    }

    complete() {
        EntryPoint.GameModel.isFreeSpinsInActiveState = false;
        super.complete();
    }

    stopNow() {
        if(this.active) {
            OPWrapperService.infoPanel.setGameState(OPWrapperService.infoPanel.gameStateTypes.EGST_REGULAR);
            this.complete();
        }
    }

}
