import { getUrlParam } from '../utils/url';

export default class UrlParser {
  constructor() {
    this._urlParams = this._parseUrlParams();
  }

  getParam(key) {
    return this._urlParams[key];
  }

  _parseUrlParams() {
    let result = {};

    try {
      let params = getUrlParam('params');
      if (params) {
        result = JSON.parse(window.atob(params));
      } else {
        result.api = getUrlParam('api');
        result.sid = getUrlParam('sid');
        result.gid = getUrlParam('gid');
      }
    } catch (e) {
      console.error(e);
    }

    return result;
  }

  get params() {
    return this._urlParams;
  }
}
