import { errorTypes } from '../utils/errorTypes';
import BaseManagerHttp from './base/baseManagerHttp';

export default class ManagerBackgroundRequests extends BaseManagerHttp {
  constructor(props) {
    super(props);

    this._checkStatusConfig = null;
    this._checkStatusTimer = null;
    this._checkStatusStartTime = null;

    this._startCheckSessionStatusTimer = this._startCheckSessionStatusTimer.bind(this);
    this.closeGame = this.closeGame.bind(this);

    window.onunload = this.closeGame;
  }

  async checkSessionStatus(inactivity_timeout) {
    const res = await this.postRequest(
      `https://${this.api}/check_session_status`,
      {
        sid: this.sid,
        gid: this.gid,
        inactivity_timeout,
      }
    );
    if (res && res.status === 'closed') {
      window.OPWrapperService.showError(errorTypes.SESSION_EXPIRED.CODE, res.message)
    }
    return res;
  }

  async closeGame() {
    const data = {
      sid: this.sid,
      gid: this.gid,
      gameTitle: document.title,
      statistic: window.OPWrapperService.ControllerStatistic.statistic
    }
    window.OPWrapperService.eventManager.dispatch(
      window.OPWrapperService.eventManager.postMessageTypes.PMT_CLOSED,
      data
    );
    navigator.sendBeacon(
      `https://${this.api}/game_closed`,
      JSON.stringify(data)
    );
    return false;
  }

  async _validateResponse(response) {
    if (!response.success) {
      window.OPWrapperService.showError(response.code, response.message);
      throw response;
    }
  }

  _startCheckSessionStatusTimer() {
    clearInterval(this._checkStatusTimer)
    if (this._checkStatusConfig.checkStatusInterval) {
      this._checkStatusStartTime = Date.now();
      this._checkStatusTimer = setInterval(() => this.checkSessionStatus((Date.now() - this._checkStatusStartTime) / 1000), this._checkStatusConfig.checkStatusInterval * 1000)
    }
  }

  set statusCheckConfig(config) {
    this._checkStatusConfig = config;

    if (this._checkStatusConfig.checkStatus) {
      document.addEventListener('click', this._startCheckSessionStatusTimer);
      document.addEventListener('touchstart', this._startCheckSessionStatusTimer);
      this._startCheckSessionStatusTimer()
    }
  }

}
