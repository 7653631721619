import BaseSystemUi from './baseSystemUi';
import '../../webComponents/tournaments-logo';

import InterMediumWoff2 from '../../../assets/fonts/Inter/Inter-Medium.woff2'
import InterMediumWoff from '../../../assets/fonts/Inter/Inter-Medium.woff'
import InterMediumTtf from '../../../assets/fonts/Inter/Inter-Medium.ttf'
import InterSemiBoldWoff2 from '../../../assets/fonts/Inter/Inter-SemiBold.woff2'
import InterSemiBoldWoff from '../../../assets/fonts/Inter/Inter-SemiBold.woff'
import InterSemiBoldTtf from '../../../assets/fonts/Inter/Inter-SemiBold.ttf'
import InterBoldWoff2 from '../../../assets/fonts/Inter/Inter-Bold.woff2'
import InterBoldWoff from '../../../assets/fonts/Inter/Inter-Bold.woff'
import InterBoldTtf from '../../../assets/fonts/Inter/Inter-Bold.ttf'
import InterExtraBoldWoff2 from '../../../assets/fonts/Inter/Inter-ExtraBold.woff2'
import InterExtraBoldWoff from '../../../assets/fonts/Inter/Inter-ExtraBold.woff'
import InterExtraBoldTtf from '../../../assets/fonts/Inter/Inter-ExtraBold.ttf'
import { preloadFontFace } from '../../../utils/preloadFontFace';
import UserAgentDetector from '../../../utils/UserAgentDetector';

preloadFontFace('Inter', [InterMediumWoff2, InterMediumWoff, InterMediumTtf], 500);
preloadFontFace('Inter', [InterSemiBoldWoff2, InterSemiBoldWoff, InterSemiBoldTtf], 600);
preloadFontFace('Inter', [InterBoldWoff2, InterBoldWoff, InterBoldTtf], 700);
preloadFontFace('Inter', [InterExtraBoldWoff2, InterExtraBoldWoff, InterExtraBoldTtf], 800);

export default class SystemUi extends BaseSystemUi {
  constructor(props) {
    super(props);
    this.init();
    this._onResize({
      innerWidth: visualViewport.width,
      innerHeight: visualViewport.height,
      orientation: visualViewport.width < visualViewport.height && UserAgentDetector.isMobile.any ? 'V' : 'H'
    });
    this._addResizeListener();
  }

  _addResizeListener() {
    window.OPWrapperService.eventManager.add(
      window.OPWrapperService.eventManager.types.EET_SCALE_MANAGER_RESIZE,
      this._onResize, this
    );
  }

  _onResize(scaleData) {
    this.scaleData = scaleData;
  }

  _getMarkup() {
    return `
      <div id=${this.controllersContainersIds[this.controllerTypes.ECT_SIDEBAR]}>
      </div>
      
      <div id=${this.controllersContainersIds[this.controllerTypes.ECT_ROUNDS_HISTORY]}>
      </div>
      
      <div id=${this.controllersContainersIds[this.controllerTypes.ECT_NOTIFICATIONS]}>
      </div>
      
      <div id=${this.controllersContainersIds[this.controllerTypes.ECT_TOURNAMENTS_START_SCREEN]}>
      </div>
      
      <div id=${this.controllersContainersIds[this.controllerTypes.ECT_MODAL_TOURNAMENT_END]}>
      </div>
      
      <div id=${this.controllersContainersIds[this.controllerTypes.ECT_TOURNAMENTS_UPCOMING_SCREEN]}>
      </div>
      
      <div id=${this.controllersContainersIds[this.controllerTypes.ECT_HONESTY]}>
      </div>
      
      <div id=${this.controllersContainersIds[this.controllerTypes.ECT_TOURNAMENTS]}>
      </div>`
  }
}
