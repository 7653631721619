import BaseState from 'Engine/base/states/BaseState';
import GlobalDispatcher from 'Engine/events/GlobalDispatcher';
import eBaseEventTypes from 'Engine/enums/eBaseEventTypes';

export default class ShowBigWinState extends BaseState {
  constructor(stateData) {
    super(stateData);

    this.timeout = null;
  }

  addListeners() {
    super.addListeners();
    GlobalDispatcher.add('bigWinContainer:stopState', this.stopNow, this);
  }

  start() {
    super.start();
    GlobalDispatcher.dispatch(eBaseEventTypes.EBET_BIG_WIN_ANIMATION_VISIBLE_CHANGE, true);
  }

  stopNow() {
    if (this.active) {
      this.stop();
    }
  }

  stop() {
    // GlobalDispatcher.dispatch(eBaseEventTypes.EBET_BIG_WIN_ANIMATION_VISIBLE_CHANGE, false);
    super.stop();
  }

  onEnd() {
    clearTimeout(this.timeout);
    super.onEnd();
  }
}
