import winController from './winController';
import  gsap  from 'gsap';
import SoundManager from 'Engine/soundManager/SoundManager';
import { eSounds } from '../../../enums/eSounds';

export default class BigWinController extends winController {
  constructor(props) {
    super(props);
    this._moveCounterDuration = 2;
    // this._scaleCounterDuration = 3;
    this._moveCounterValue = 0;
    // this._scaleCounterValue = 1.3;

  }

  onStart() {
    this.startValue = 0;
    // gsap.to(this.winAmountContainer, { y: `+=${this._moveCounterValue}`, duration: this._moveCounterDuration, ease: 'power4.out' });
      // gsap.to(this.counter.scale, {
      //   x: this._scaleCounterValue,
      //   y: this._scaleCounterValue,
      //   duration: this._scaleCounterDuration
      // }));

    this.ControllerPopup.showOverlay(1000);
    this.coinParticles.visible = true;
    this.coinParticles.start();
    this._dirty = true;
  }

  onSkip() {
    this.onStart();
    gsap.killTweensOf(this.winAmountContainer)
    this.winAmountContainer.y += this._moveCounterValue;
  }

  cleanup() {
    super.cleanup();
    if (!this._dirty) return;
    // SoundManager.play(eSounds.EST_WIN_END.soundName, eSounds.EST_WIN_END.volume, false, eSounds.EST_WIN_END.group);

    gsap.killTweensOf(this.winAmountContainer)
    this.winAmountContainer.y = 0;
    if (this.coinParticles) {
      this.coinParticles.stop();
      this.coinParticles.visible = false;
    }
    // this.counter.scale.set(1);
    this._dirty = false;
  }
}
