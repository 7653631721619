import EntryPoint from 'Engine/EntryPoint';

export default class WinAmountAnimated {
  constructor(element, style = {}, fontName = 'bob-font') {

    this._decimals = 2;
    this.label = new PIXI.BitmapText('0', { ...element.style, ...style, fontName });
    this.label.anchor.set(0.5);
    // this.label.x = element.displayData.width / 2;
    this.label.y = element.displayData.height / 2;
    this.label.visible = false;
    element.removeChildren();
    element.addChild(this.label);

    this.onStop = () => {
    };
    this.onComplete = () => {
    };
  }

  animate(startValue = 0, endValue, duration) {
    this.needStop = false;
    this.set(startValue);

    this.label.visible = true;
    this.startValue = startValue;
    this.endValue = endValue;
    this.duration = duration;
    this.startTime = Date.now();
    requestAnimationFrame(this._animateValueChanged.bind(this));
  }

  set(value) {
    this.label.text = this._formatValue(value);
  }

  stop() {
    this.needStop = true;
  }

  _onStop() {
    this.onStop();
    this.onComplete();
  };

  _animateValueChanged() {
    if (this.needStop) return;

    const time = Date.now() - this.startTime;

    if (time >= this.duration) {
      // this.label.text = MoneyFormat.formatCurrency(this.endValue, this._decimals, false);
      this.label.text = this._formatValue(this.endValue);
      return this._onStop();
    }

    const progress = time / this.duration;
    const amount = (this.endValue - this.startValue) * progress + this.startValue;
    // this.label.text = amount.toFixed(this._decimals);
    this.label.text = this._formatValue(amount);
    requestAnimationFrame(this._animateValueChanged.bind(this));
  };

  _formatValue(value) {
    this._decimals = EntryPoint.GameModel.currencyInfo.decimals;
    const splitValue = value.toFixed(this._decimals).split('.');
    let mainValue = splitValue[0];
    let result = [];
    for (let i = mainValue.length - 1; i >= 0; i--) {
      if (i !== mainValue.length - 1 && (mainValue.length - 1 - i) % 3 === 0) result.unshift(' ');
      result.unshift(mainValue[i]);
    }

    if (splitValue[1] !== undefined) {
      result.push(...['.', splitValue[1]]);
    }

    return result.join('');
  }

  set decimals(value) {
    this._decimals = value;
  }

  set fontSize(value) {
    this.label.fontSize = value;
  }

  set y(value) {
    value = Number(value);
    let y = this.label.parent.displayData.height / 2;
    if (value) y += value;
    this.label.y = y;
  }

  get scale() {
    return this.label.scale;
  }
}
