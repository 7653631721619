import BaseController from './base';

export default class BaseStatefulController extends BaseController {
  constructor(...props) {
    super(...props);
    this._data = null;
  }

  updateData(data) {
    const nextData = this._extractRelevantData(data);
    if (this._data === nextData) return;

    this._data = nextData;
    this._onDataUpdated();
  }

  beforeRemove() {
    super.beforeRemove();
    this._data = null;
  }

  _extractRelevantData(data) {
    const selector = this.dataSelector;
    if (!selector) return;

    return selector(data);
  }

  _onDataUpdated() {}

  get dataSelector() {}

  get data() {
    return this._data;
  }
}
