import animationCreator from '../animations/animationCreator';

export default class Particle {
  constructor(container, config, timeStart, particleInfo) {
    this.config = config;
    this.container = container;
    this.particleInfo = particleInfo;
    this.timeStart = timeStart;

    this.angle0difference = this.config.angle0To - this.config.angle0From;

    this.lifetime = this.getRandomValueBetween(this.config.lifetimeMin, this.config.lifetimeMax);
    this.timeStartParticle = this.lifetime * Math.random() + this.timeStart;

    this.gravityX = this.config.gravityX;
    this.gravityY = this.config.gravityY;

    this.drag = this.config.drag;

    this.mass = this.config.mass;

    this.x0 = this.getRandomValueBetween(this.config.x0min, this.config.x0max);
    this.y0 = this.getRandomValueBetween(this.config.y0min, this.config.y0max);
    this.v0 = this.getRandomValueBetween(this.config.v0min, this.config.v0max);
    this.angleVelocity = this.getRandomValueBetween(this.config.angleVelocityMin, this.config.angleVelocityMax);

    const angle0min = Math.min(this.config.angle0From, this.config.angle0To);
    this.angle0 = angle0min + this.angle0difference * Math.random();

    this.vy0 = this.v0 * Math.sin(this.angle0);
    this.vx0 = this.v0 * Math.cos(this.angle0);

    this.x = this.x0;
    this.y = this.y0;
    this.rotation = this.angle0;
    this.vy = this.vy0;
    this.vx = this.vx0;

    this.isPaused = false;
    this.isStopped = false;

    this.update();
  }

  getRandomValueBetween(min, max) {
    const difference = max - min;
    return (min + (difference * Math.random()));
  }

  getVelocityY(dt) {
    this.vy = this.vy + (this.gravityY * this.mass - this.vy * this.drag) * (dt / 16);
  };

  calcYByVelocity(dt) {
    const dy = (this.vy * dt);
    this.sprite.y = this.y + dy;
    this.y = this.sprite.y;
  };

  getVelocityX(dt) {
    this.vx = this.vx + (this.gravityX * this.mass - this.vx * this.drag) * (dt / 16);
  };

  calcXByVelocity(dt) {
    const dx = (this.vx * dt);
    this.sprite.x = this.x + dx;
    this.x = this.sprite.x;
  };

  setRotationByAngleVelocity(dt) {
    this.rotation += this.angleVelocity * dt;
    this.sprite.rotation = this.rotation;
  }

  reset() {
    this.x = this.x0;
    this.y = this.y0;
    this.vy = this.vy0;
    this.vx = this.vx0;
    this.sprite.y = this.y;
    this.sprite.x = this.x;
    this.sprite.scale.set(this.config.scaleStart);
  }

  update() {
    if (this.isStopped) {
      if (this.sprite) this.sprite.destroy();
      return;
    }

    if (!this.prevTime) {
      this.prevTime = Date.now();
    }
    const nowTime = Date.now();
    let deltaTime = nowTime - this.prevTime;
    this.prevTime = nowTime;
    if (deltaTime > 100) {
      this.timeStartParticle += deltaTime;
      deltaTime = 0;
    }

    if (nowTime >= this.timeStartParticle) {
      if (!this.sprite) {
        this._createParticleSprite();
      }

      const progress = (nowTime - this.timeStartParticle) / this.lifetime;

      this.sprite.scale.set(this.config.scaleStart + (this.config.scaleEnd - this.config.scaleStart) * progress);
      this.getVelocityY(deltaTime);
      this.calcYByVelocity(deltaTime);
      this.getVelocityX(deltaTime);
      this.calcXByVelocity(deltaTime);
      this.setRotationByAngleVelocity(deltaTime);

      const isBound = (this.config.leftBound && this.sprite.x < 0) ||
        (this.config.topBound && this.sprite.y < 0) ||
        (this.config.rightBound && this.sprite.x > this.config.box.width) ||
        (this.config.bottomBound && this.sprite.y > this.config.box.height);

      if (isBound || nowTime > this.lifetime + this.timeStartParticle) {
        if (this.isPaused) {
          this.sprite.destroy();
          return;
        } else {
          this.reset();
          this.timeStartParticle = nowTime;
        }
      }
    }
    requestAnimationFrame(this.update.bind(this));
  };

  pause() {
    this.isPaused = true;
  }

  stop() {
    this.isStopped = true;
  }

  _createParticleSprite() {
    if (this.particleInfo.texture) {
      this.sprite = new PIXI.Sprite(this.particleInfo.texture);
      if (this.config.blendMode) this.sprite.blendMode = this.config.blendMode;
      this.sprite.scale.set(this.config.scaleStart);
      this.sprite.anchor.set(0.5);
    } else if (this.particleInfo.animationType && this.particleInfo.animationName) {
      this.sprite = animationCreator.createAnimation(this.particleInfo.animationType);
      this.sprite.state.setAnimation(0, this.particleInfo.animationName, true);
      this.sprite.scale.set(this.config.scaleStart);
    } else {
      console.error('Invalid parameter in "ParticleSystem": "particleInfo" ');
    }
    this.container.addChild(this.sprite);
  }
}
