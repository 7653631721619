import BaseState from "Engine/base/states/BaseState";
import GlobalDispatcher from "Engine/events/GlobalDispatcher";
import GameModel from "../model/GameModel";

export default class ExpandSymbolsState extends BaseState {

    constructor(stateData) {
        super(stateData);
    }

    addListeners() {
        super.addListeners();
        GlobalDispatcher.add('allExpandedSymbolShowed', this.onAllExpandedSymbolShowed, this);
    }

    onAllExpandedSymbolShowed() {
        this.complete();
    }
}