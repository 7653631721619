export default class BaseManagerHttp {
  constructor({ api, sid, gid }) {
    this.api = api;
    this.sid = sid;
    this.gid = gid;
    this.mode = /\.dev\./.test(this.api) ? 'dev' : /\.stage\./.test(this.api) ? 'stage' : '';
  }

  async postRequest (url, data, options) {
    try {
      const rawResponse = await fetch(url, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          'Content-type': 'application/json',
        },
        ...options,
      });

      if (rawResponse.status === 500) {
        throw new Error('Server Down');
      }

      const response = await this._parseResponse(rawResponse);
      await this._validateResponse(response);

      return response;
    } catch (e) {
      await this._handleError(e);
    }
  };

  async getRequest (url, options) {
    try {
      const rawResponse = await fetch(url, {
        method: 'GET',
        ...options,
      });

      if (rawResponse.status === 500) {
        throw new Error('Server Down');
      }

      const response = await this._parseResponse(rawResponse);
      await this._validateResponse(response);

      return response;
    } catch (e) {
      await this._handleError(e);
    }
  };

  async _parseResponse(rawResponse) {
    return await rawResponse.json()
      .catch((error) => {
        console.error('Response: ', rawResponse);
        throw error;
      });
  }

  async _validateResponse(response) {
    if (!response.success) throw response;
  }

  async _handleError(error) {
    console.error(error);
  }
}
