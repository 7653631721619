import * as PIXI from 'pixi.js';
import constants from '../Constants';
import bonusTypes from './bonusTypes';
import FlashLib from 'flashlib_onlyplay';
import GameModel from '../model/GameModel';
import animationCreator from 'Engine/animations/animationCreator';
import animationTypes from '../enums/animationTypes';
import GlobalDispatcher from 'Engine/events/GlobalDispatcher';
import EntryPoint from 'Engine/EntryPoint';
import { makeHitArea } from 'Engine/utils/makeHitArea';

export default class PurchaseContainer extends FlashLib.MovieClip {
  constructor(data, displayData) {
    super(data, displayData);

    this.interactive = true;

    this.init();
    this.initCards();
    this.addListeners();
    this.onResize();
    this.hide();
  }

  init() {
    this.confirmModal = this.getChildByName('confirmModal');
    this.btnClose = this.getChildByName('btnClose');
    makeHitArea(this.btnClose);
    this.btnClose.enabled = true;
    this.btnClose.displayData.x = 1900;
    this.btnClose.displayData.y = 100;
    this.btnClose.x = this.btnClose.displayData.x;
    this.btnClose.y = this.btnClose.displayData.y;
    this.animationContainer = this.getChildByName('animationContainer');
    this.description = this.getChildByName('purchase_description');

    this.betSelector = this.getChildByName('cashBet');
    this.betSelector.setRange(EntryPoint.GameModel.betPossible);
    this.betSelector.setValue(EntryPoint.GameModel.bet);
    this.betSelector.enableButtons();
  }

  initCards() {
    this.cards = [];
    const bonusTypesArray = Object.keys(bonusTypes);
    for (let i = 0; i < bonusTypesArray.length; i++) {
      const card = this.getChildByName(`card_${i}`);
      card.type = bonusTypesArray[i];
      // card.showParticles();
      card.price = GameModel.bet * GameModel.bonusCostMultiplier;
      this.cards.push(card);
    }
  }

  initTitleAnimation() {
    this.titleAnimation = animationCreator.createAnimation(animationTypes.PURCHASE);
    if (!this.titleAnimation) return;

    this.titleAnimation.state.setAnimation(0, 'title_start', false);
    this.titleAnimation.state.addAnimation(0, 'title_idle', true, 0);
    this.animationContainer.addChild(this.titleAnimation);
  }

  addListeners() {
    GlobalDispatcher.add('ui:openPurchaseClick', this.show.bind(this));
    GlobalDispatcher.add('model:betChanged', this.onBetChanged, this);
    GlobalDispatcher.add('ui:spinClick', this.hide.bind(this));
    GlobalDispatcher.add('bonusGame:buy', this.hide.bind(this));
    window.addEventListener('resize', () => setTimeout(this.onResize.bind(this), 1));
    this.btnClose.addListener('pointertap', this.hide.bind(this));
  }

  onResize() {
    const width = Math.min(window.innerWidth, window.outerWidth);
    const height = Math.min(window.innerHeight, window.outerHeight);
    const isHorizontalOrientation = (width - height) > 0;

    if (PIXI.utils.isMobile.any && isHorizontalOrientation) {

      this.animationContainer.y = this.animationContainer.displayData.y;

      this.btnClose.x = this.btnClose.displayData.x;
      this.btnClose.y = this.btnClose.displayData.y;

      this.betSelector.y = this.betSelector.displayData.y;

    } else if (PIXI.utils.isMobile.any) {
      const viewWidthGame = constants.GAME_HEIGHT * width / height;
      const scaleGame = viewWidthGame / constants.GAME_WIDTH;


      this.animationContainer.y = -50;

      this.btnClose.x = 1500;
      this.btnClose.y = -296;

      this.betSelector.y = this.betSelector.displayData.y + 250;
    }

  }

  async show() {
    if (!PIXI.Assets.cache.has(animationTypes.PURCHASE)) {
      window.OPPreloader.showDelayedPreloader();
      await PIXI.Assets.load(animationTypes.PURCHASE);
      window.OPPreloader.hideDelayedPreloader();
    }
    this.cards.forEach(card => card.show());
    this.initTitleAnimation();
    this.onResize();
    this.visible = true;
    EntryPoint.GameModel.isPurchaseOpen = true;
  }

  hide() {
    if (!this.visible) return;

    if (this.confirmModal.visible) {
      this.confirmModal.hide();
    }

    this.cards.forEach(card => card.beforeUnload());
    if (this.titleAnimation) this.titleAnimation.destroy({ children: true });
    this.visible = false;

    EntryPoint.GameModel.isPurchaseOpen = false;
    GlobalDispatcher.dispatch('ui:closePurchase');
  }

  onBetChanged(event) {
    this.betSelector.setValue(EntryPoint.GameModel.bet);
    this.cards.forEach(card => {
      card.price = EntryPoint.GameModel.bet * GameModel.bonusCostMultiplier;
    })
  }
}
