import LocalizationManager from '../i18n/LocalizationManager';
import { formatCurrency } from '../utils/formatCurrency';
import { isEqual } from '../utils/isEqual';
import { OP_WRAPPER_GAME_LIMITS_STORAGE_KEY, OP_WRAPPER_LOCALIZATION_PREFIX } from '../utils/constants';

export default class GameLimitsWindow {
  constructor(container) {
    this.container = container;
    this.init = this.init.bind(this);
    this.show = this.show.bind(this);
    this._onKeydown = this._onKeydown.bind(this);
    this.init();
  }

  init() {
    const view = document.getElementById('gameLimitsView');
    if (view) {
      view.remove();
      this.view.removeEventListener('click', this.hide.bind(this));
      this.close.removeEventListener('click', this.hide.bind(this));
    }
    this.container.insertAdjacentHTML('beforeend',
      `
      <div id="gameLimitsView" class="blocker">
        <div id="gameLimitsContainer">
          <div class="gameLimits__head">
            <div id="gameLimitsTitle">${LocalizationManager.getLocalizedText(`${OP_WRAPPER_LOCALIZATION_PREFIX}.gameLimits.title`)}</div>
            <div id="gameLimitsClose">&#10006;</div>
          </div>
          <div class="gameLimitsTable">
            <div class="row">
              <div>${LocalizationManager.getLocalizedText(`${OP_WRAPPER_LOCALIZATION_PREFIX}.gameLimits.minBet`)}</div>
              <div class="gameLimits__amount" id="gameLimits__minBet"></div>
            </div>
            <div class="row">
              <div>${LocalizationManager.getLocalizedText(`${OP_WRAPPER_LOCALIZATION_PREFIX}.gameLimits.maxBet`)}</div>
              <div class="gameLimits__amount" id="gameLimits__maxBet"></div>
            </div>
            <div class="row">
              <div>${LocalizationManager.getLocalizedText(`${OP_WRAPPER_LOCALIZATION_PREFIX}.gameLimits.maxWin`)}</div>
              <div class="gameLimits__amount" id="gameLimits__maxWin"></div>
            </div>
          </div>
        </div>
      </div>`
    );

    this.view = document.getElementById('gameLimitsView');
    this.title = document.getElementById('gameLimitsTitle');
    this.close = document.getElementById('gameLimitsClose');
    this.minBet = document.getElementById('gameLimits__minBet');
    this.maxBet = document.getElementById('gameLimits__maxBet');
    this.maxWin = document.getElementById('gameLimits__maxWin');

    this.view.addEventListener('click', this.onViewClick.bind(this));
    this.view.addEventListener('touchend', this.onViewClick.bind(this));
    this.close.addEventListener('click', this.hide.bind(this));
    this.show = this.show.bind(this);
  }

  show() {
    const config = window.OPWrapperService.config;
    this.minBet.textContent = formatCurrency(config.available_bets[0], config.currency);
    this.maxBet.textContent = formatCurrency(config.available_bets[config.available_bets.length - 1], config.currency);
    this.maxWin.textContent = formatCurrency(config.maxWin, config.currency);
    this.view.style.setProperty('visibility', 'visible');
    this.view.style.setProperty('opacity', '1');
    document.addEventListener('keydown', this._onKeydown, true);
  }

  tryShowGameLimits (config) {
    if (!config.showLimitsOnStart || !config.available_bets || !config.maxWin) return;
    if (!localStorage) return this.show();

    const limits = {
      minBet: config.available_bets[0],
      maxBet: config.available_bets[ config.available_bets.length - 1],
      maxWin: config.maxWin,
    };

    try {
      const lastGameLimits = localStorage.getItem(OP_WRAPPER_GAME_LIMITS_STORAGE_KEY);
      let isSameLimits;

      if (lastGameLimits) {
        const parsedLimits = JSON.parse(lastGameLimits);
        isSameLimits = isEqual(limits, parsedLimits);
      }

      if (!isSameLimits || !lastGameLimits) {
        localStorage.setItem(OP_WRAPPER_GAME_LIMITS_STORAGE_KEY, JSON.stringify(limits));
        this.show();
      }
    } catch (e) {
      console.error(e);
    }
  }

  onViewClick(e) {
    e.stopPropagation();
    if (e.target !== this.view) return;
    this.hide();
  }

  hide() {
    document.removeEventListener('keydown', this._onKeydown, true);
    this.view.style.setProperty('visibility', 'hidden');
    this.view.style.setProperty('opacity', '0');
    window.OPWrapperService.initConfig.onButtonClick();
  }

  _onKeydown(e) {
    if (e.keyCode !== 32) return;
    e.stopPropagation();
  }
}
