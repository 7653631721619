import GameModel from "../model/GameModel";
import DataProvider from "./XXXData";
import XXXDataParser from './XXXDataParser';
import EntryPoint from 'Engine/EntryPoint';

export default new class ServerManager  {
    constructor() {
        this.dataProvider = null;
        this.timeout = null;
        this.dataProvider = new DataProvider();

    }

    addDataProvider() {
        this.dataProvider = new DataProvider();
    }

    // addListeners() {
    //     GlobalDispatcher.add('rollingState:start', this.onStartSpin, this);
    //     GlobalDispatcher.add('respinRollingState:start', this.onStartRespin, this);
    //     GlobalDispatcher.add('model:init', this.onInit, this);
    //     GlobalDispatcher.add('bonusGame:buy', this.onBuyBonusGame, this);
    //     GlobalDispatcher.add('dataProvider:hasResponse', this.onResponse, this);
    //     GlobalDispatcher.add('dataProvider:updateBalance', () => {
    //         this.dataProvider.action({ action: 'balance' });
    //     })
    // }

    async onInit() {
        let request = {
            action: 'init'
        };
        // this.dataProvider.action(request);
        const data = await this.dataProvider.sendInit(request);
        GameModel.setNewData(XXXDataParser.parseInit(data));
    }
    async onStartSpin() {
        let request = {
            betIndex: GameModel.betIndex,
        };
        // const data = await this.dataProvider.sendMessage(request, 'spin');
        const data = await this.dataProvider.sendMessage(request, 'bet');

        GameModel.setNewData(XXXDataParser.parseSpin(data));
        return data;
    }
    async onStartRespin() {
        let request = {
            type: 'respin',
            respin_reel_number: GameModel.respinReelIndex,
        };
        const data = await this.dataProvider.sendMessage(request, 'bet');
        GameModel.setNewData(XXXDataParser.parseSpin(data));
        return data;
    }

    async onBuyBonusGame() {
        let request = {
            betIndex: GameModel.betIndex,
        };
        this.dataProvider.action(request);
        const data = await this.dataProvider.sendMessage(request, 'buy_bonus');
        GameModel.setNewData(XXXDataParser.parsePurchaseBonusGame(data));
        return data;
    }

    onResponse(data) {
        switch (data.params.action) {
            case 'init':
            case 'reconnect':
                this.hasInitData(data.params);
                break;
            case 'spin':
                this.hasSpinData(data.params);
                break;
            case 'respin':
                this.hasRespinData(data.params);
                break;
            case 'buy_bonus':
                this.hasBonusGameData(data.params);
        }
    }

    hasInitData(data) {
        GameModel.setNewData(data);
    }

    hasSpinData(data) {
        GameModel.setNewData(data);
    }

    hasRespinData(data) {
        GameModel.setNewData(data);
    }

    hasBonusGameData(data) {
        GameModel.setNewData(data);
    }
}
