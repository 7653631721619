import GlobalDispatcher from 'Engine/events/GlobalDispatcher';
import SoundManager from 'Engine/soundManager/SoundManager';

export default new class DropConfig {
    constructor() {
        this.dropArea = null;
    }

    init() {
        this.dropArea = document.getElementById('container');
        this.dropArea.addEventListener('dragenter', this.handlerFunction.bind(this), false);
        this.dropArea.addEventListener('dragleave', this.handlerFunction.bind(this), false);
        this.dropArea.addEventListener('dragover', this.handlerFunction.bind(this), false);
        this.dropArea.addEventListener('drop', this.handlerFunction.bind(this), false);
        this.dropArea.addEventListener('drop', this.onDropFile.bind(this), false);
    }

    handlerFunction(event) {
        event.preventDefault();
        event.stopPropagation();
    }

    onDropFile(event) {
        let dataTransfer = event.dataTransfer;
        let files = dataTransfer.files;

        this.handleFiles(files);
    }

    handleFiles(files) {
        let file = files[0];
        file.text().then(this.onFileLoaded);
    }

    onFileLoaded(data) {
        let json = JSON.parse(data);

        SoundManager.soundsConfig = json;

        GlobalDispatcher.dispatch('dropConfig:fileLoaded', json);
    }
}
