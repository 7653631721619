const getAssetsScale = ({ availableScales, safeWidthPortrait, safeHeightPortrait, safeWidthLandscape, safeHeightLandscape }) => {
  const isPortrait = screen.height >= screen.width;

  const resolution = (window.devicePixelRatio < 2 ? window.devicePixelRatio : 2);

  const landscapeScales = [];
  if (safeWidthLandscape) landscapeScales.push((isPortrait ? screen.height : screen.width) * resolution / safeWidthLandscape);
  if (safeHeightLandscape) landscapeScales.push((isPortrait ? screen.width : screen.height) * resolution / safeHeightLandscape);
  const minLandscapeScale = landscapeScales.length ? Math.min(...landscapeScales) : null;

  const portraitScales = [];
  if (safeWidthPortrait) portraitScales.push((isPortrait ? screen.width : screen.height) * resolution / safeWidthPortrait);
  if (safeHeightPortrait) portraitScales.push((isPortrait ? screen.height : screen.width) * resolution / safeHeightPortrait);
  const minPortraitScale = portraitScales.length ? Math.min(...portraitScales) : null;

  const gameScales = [];
  if (minLandscapeScale) gameScales.push(minLandscapeScale);
  if (minPortraitScale) gameScales.push(minPortraitScale);

  const gameMaxScale = gameScales.length ? Math.max(...gameScales) : 1;
  return availableScales.find(scale => gameMaxScale <= parseFloat(scale)) || '1.0';
};

export {
  getAssetsScale,
};
