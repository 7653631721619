export default class BaseEventManager {
  get listeners() {
    if (!this._listeners) {
      this._listeners = [];
    }
    return this._listeners;
  }

  get eventsStack() {
    if (!this._eventsStack) {
      this._eventsStack = [];
    }
    return this._eventsStack;
  }

  set inProgress(value) {
    this._inProgress = value;
  }

  get inProgress() {
    if (!this._inProgress) {
      this._inProgress = false;
    }
    return this._inProgress;
  }

  add($event, $callback, $context, $priority) {
    let listener = { event: $event, callback: $callback, context: $context, priority: $priority || 1, once: false };
    this.addToListeners(listener);
  }

  addOnce($event, $callback, $context, $priority) {
    let listener = { event: $event, callback: $callback, context: $context, priority: $priority || 1, once: true };
    this.addToListeners(listener);
  }

  addToListeners($listener) {
    this.listeners.push($listener);
    this.listeners.sort(function (a, b) {
      return b.priority - a.priority;
    });
  }

  dispatch($event, $params, $callback) {
    this.eventsStack.push({ event: $event, params: $params, callback: $callback || null });
    if (!this.inProgress) {
      this.executeFromStack();
    }
  }

  remove($event, $callback, $context) {
    this.listeners.forEach((listener, index, array) => {
      if (listener.event === $event && listener.callback === $callback && listener.context === $context) {
        array.splice(index, 1);
      }
    });
  }

  removeAll($event) {
    this.listeners.forEach((listener, index, array) => {
      if (listener.event === $event) {
        array.splice(index, 1);
      }
    });
  }

  executeFromStack() {
    this.inProgress = true;
    let object = null;
    while (this.eventsStack.length > 0) {
      object = this.eventsStack.shift();
      this.execute(object.event, object.params, object.callback);
    }
    this.inProgress = false;
  }

  execute($event, $params, $callback) {
    this.listeners.forEach((listener, index, array) => {
      if (listener.event === $event) {
        if (listener.context) {
          listener.callback.call(listener.context, $params);
        } else {
          listener.callback($params);
        }
        if (listener.once) {
          array.splice(index, 1);
        }
      }
    });
    if($callback) $callback();
  }
}
