import BaseTournamentController from './baseTournamentController';
import { OP_WRAPPER_LOCALIZATION_PREFIX } from '../../../../utils/constants';

export default class ControllerArchiveCard extends BaseTournamentController {
  constructor(props) {
    super(props);
    this._eButtonsTypes = {
      EBT_DETAILS: 'button_details',
    };
    this._eEventTypes = {
      DETAILS_CLICK: this.getEventName(this._eButtonsTypes.EBT_DETAILS),
    };
    this._eElementsTypes = {
      EET_TITLE: 'title',
      EET_DATES: 'dates',
      EET_LABEL_END: 'label_end',
      EET_BANNER: 'banner',
      EET_BUTTON_DETAILS: this._eButtonsTypes.EBT_DETAILS,
      EET_BUTTON_DETAILS_LABEL: `${this._eButtonsTypes.EBT_DETAILS}_label`,
      EET_ROOT: `container_root`,
    };
    this._localizations = {
      [this._eElementsTypes.EET_BUTTON_DETAILS_LABEL]: `${OP_WRAPPER_LOCALIZATION_PREFIX}.tournaments.details.button_details`,
      [this._eElementsTypes.EET_LABEL_END]: `${OP_WRAPPER_LOCALIZATION_PREFIX}.tournaments.common.label_end`,
    };
  }

  remove() {
    this.beforeRemove();
    this.root.remove();
  }

  _afterInit() {
    super._afterInit();
    this.root = this.interactiveElements[this._eElementsTypes.EET_ROOT];
    this._updateBanner();
  }

  _updateBanner() {
    const banner = this.interactiveElements[this._eElementsTypes.EET_BANNER];
    const orientationKey = visualViewport.width <= visualViewport.height ? 'Port' : 'Land';
    const key = 'img'.concat(orientationKey);
    const background = `url(${this._data[key]}) center / cover no-repeat`;
    if (banner.style.background === background) return;

    banner.style.background = background;
  }

  _formatDates(startDate, endDate) {
    const regexp = /\d\d\d\d-\d\d-\d\d/;
    const start = startDate.match(regexp)[0];
    const end = endDate.match(regexp)[0];
    if (!start || !end) return;

    return `${start.split('-').join('/')} -\n ${end.split('-').join('/')}`;
  }

  get elementsIdSuffix() {
    return this._data.uniqueId;
  }

  set scaleData({ scaleData, rootScale }) {
    this._updateBanner();
  }

  get dataSelector() {
    return (data) => data;
  }

  _getMarkup() {
    return `<div class="system-ui-container__tournaments_archive_list__card" id=${this.interactiveElementsIds[this._eElementsTypes.EET_ROOT]}>
              <div class="system-ui-container__tournaments_archive_list__card_banner" id=${this.interactiveElementsIds[this._eElementsTypes.EET_BANNER]}>
                <div class="system-ui-container__tournaments_archive_list__card_banner_label" id=${this.interactiveElementsIds[this._eElementsTypes.EET_LABEL_END]}>
                  ${this._getLocalization(this._localizations[this._eElementsTypes.EET_LABEL_END])}
                </div>
                <div class="system-ui-container__tournaments_archive_list__card_banner_overlay">
                  <span id=${this.interactiveElementsIds[this._eElementsTypes.EET_TITLE]}>${this._data.title}</span>
                  <span id=${this.interactiveElementsIds[this._eElementsTypes.EET_DATES]}>${this._formatDates(this._data.startAt, this._data.endAt)}</span>
                  <button class="secondary" id=${this.interactiveElementsIds[this._eElementsTypes.EET_BUTTON_DETAILS]}>
                    <span id=${this.interactiveElementsIds[this._eElementsTypes.EET_BUTTON_DETAILS_LABEL]}>
                    ${this._getLocalization(this._localizations[this._eElementsTypes.EET_BUTTON_DETAILS_LABEL])}
                    </span>
                  </button>
                </div>
              </div>
            </div>`;
  }
}
