import BaseState from 'Engine/base/states/BaseState';
import GlobalDispatcher from 'Engine/events/GlobalDispatcher';
import EntryPoint from 'Engine/EntryPoint';

export default class ShowStartScreenState extends BaseState {

  constructor(stateData) {
    super(stateData);
  }

  addListeners() {
    super.addListeners();

    GlobalDispatcher.add('startScreen:hide', this.stop, this);
  }

  start() {
    super.start();
    GlobalDispatcher.dispatch('startScreen:show');

    if (EntryPoint.GameModel.isFreeSpinsMode) {
      OPWrapperService.infoPanel.setGameState(OPWrapperService.infoPanel.gameStateTypes.EGST_FREESPINS_GAME);
    }
  }

  stop() {
    if (!this.active) return;

    this.complete();
  }
}
