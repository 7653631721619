import BaseTournamentController from './baseTournamentController';
import { OP_WRAPPER_LOCALIZATION_PREFIX } from '../../../../utils/constants';
import { getTimeLeftTillTimestamp } from '../../../../utils/getTimeLeftTillTimestamp';
import eTournamentStatus from './eTournamentStatus';

export default class ControllerBanner extends BaseTournamentController {
  constructor({ container, ...props }) {
    super({ ...props });
    this._eElementsTypes = {
      EET_BANNER: `banner`,
      EET_BANNER_PANEL: 'banner_panel',
      EET_ENDS_LABEL: 'ends_label',
      EET_ENDS_DAYS_VALUE: 'ends_days_value',
      EET_ENDS_DAYS_LABEL: 'ends_days_label',
      EET_ENDS_HOURS_VALUE: 'ends_hours_value',
      EET_ENDS_HOURS_LABEL: 'ends_hours_label',
      EET_ENDS_MINUTES_VALUE: 'ends_minutes_value',
      EET_ENDS_MINUTES_LABEL: 'ends_minutes_label',
      EET_STARTS_LABEL: 'starts_label',
      EET_STARTS_DAYS_VALUE: 'starts_days_value',
      EET_STARTS_DAYS_LABEL: 'starts_days_label',
      EET_STARTS_HOURS_VALUE: 'starts_hours_value',
      EET_STARTS_HOURS_LABEL: 'starts_hours_label',
      EET_STARTS_MINUTES_VALUE: 'starts_minutes_value',
      EET_STARTS_MINUTES_LABEL: 'starts_minutes_label',
      EET_ENDED_LABEL: 'ended_label',
      EET_ENDS_BLOCK: 'ends_block',
      EET_ENDED_BLOCK: 'ended_block',
      EET_STARTS_BLOCK: 'starts_block',
      EET_TITLE: 'title',
      EET_DATES: 'dates',
    };
    this._localizations = {
      [this._eElementsTypes.EET_ENDED_LABEL]: `${OP_WRAPPER_LOCALIZATION_PREFIX}.tournaments.common.label_end`,
      [this._eElementsTypes.EET_ENDS_LABEL]: `${OP_WRAPPER_LOCALIZATION_PREFIX}.tournaments.details.ends_label`,
      [this._eElementsTypes.EET_ENDS_DAYS_LABEL]: `${OP_WRAPPER_LOCALIZATION_PREFIX}.tournaments.details.days_label_short`,
      [this._eElementsTypes.EET_ENDS_HOURS_LABEL]: `${OP_WRAPPER_LOCALIZATION_PREFIX}.tournaments.details.hours_label_short`,
      [this._eElementsTypes.EET_ENDS_MINUTES_LABEL]: `${OP_WRAPPER_LOCALIZATION_PREFIX}.tournaments.details.minutes_label_short`,
      [this._eElementsTypes.EET_STARTS_LABEL]: `${OP_WRAPPER_LOCALIZATION_PREFIX}.tournaments.details.starts_label`,
      [this._eElementsTypes.EET_STARTS_DAYS_LABEL]: `${OP_WRAPPER_LOCALIZATION_PREFIX}.tournaments.details.days_label_short`,
      [this._eElementsTypes.EET_STARTS_HOURS_LABEL]: `${OP_WRAPPER_LOCALIZATION_PREFIX}.tournaments.details.hours_label_short`,
      [this._eElementsTypes.EET_STARTS_MINUTES_LABEL]: `${OP_WRAPPER_LOCALIZATION_PREFIX}.tournaments.details.minutes_label_short`,
    };

    this._tick = this._tick.bind(this);
    this._currentOrientation = 'V';

    this.init(container);
  }

  onShow() {
    super.onShow();
    this._visible = true;
    this._update();
  }

  _onDataUpdated() {
    super._onDataUpdated();
    this.set(this._eElementsTypes.EET_DATES, this._formatDates(this._data.details.startAt, this._data.details.endAt));
    this.set(this._eElementsTypes.EET_TITLE, this._data.details.title);

    this._update();
    this._setupBanner();
  }

  _setupBanner() {
    if (!this._data) return;
    const orientationKey = this._currentOrientation === 'V' ? 'Port' : 'Land';
    const key = 'img'.concat(orientationKey);
    if (this.interactiveElements[this._eElementsTypes.EET_BANNER].src === this._data.details[key]) return;

    this.interactiveElements[this._eElementsTypes.EET_BANNER].src = this._data.details[key];
  }

  _update() {
    if (!this._data) return;
    this._startTimestamp = null;
    this._endTimestamp = null;

    switch (this._data.status) {
      case eTournamentStatus.ETS_ACTIVE: {
        this.interactiveElements[this._eElementsTypes.EET_BANNER_PANEL].classList.remove('hidden');
        this.interactiveElements[this._eElementsTypes.EET_ENDED_LABEL].classList.add('hidden');
        this.interactiveElements[this._eElementsTypes.EET_ENDED_BLOCK].classList.add('hidden');
        this.interactiveElements[this._eElementsTypes.EET_STARTS_BLOCK].classList.add('hidden');
        this.interactiveElements[this._eElementsTypes.EET_ENDS_BLOCK].classList.remove('hidden');

        this._endTimestamp = new Date(this._data.details.endAt).getTime();
        this._updateTimer();
        this._startTimer();
      }
        break;
      case eTournamentStatus.ETS_ARCHIVED: {
        this.interactiveElements[this._eElementsTypes.EET_BANNER_PANEL].classList.remove('hidden');
        this.interactiveElements[this._eElementsTypes.EET_ENDED_LABEL].classList.remove('hidden');
        this.interactiveElements[this._eElementsTypes.EET_ENDED_BLOCK].classList.remove('hidden');
        this.interactiveElements[this._eElementsTypes.EET_ENDS_BLOCK].classList.add('hidden');
        this.interactiveElements[this._eElementsTypes.EET_STARTS_BLOCK].classList.add('hidden');

        this._tickTimerStopped = true;
      }
        break;
      case eTournamentStatus.ETS_FUTURE: {
        this.interactiveElements[this._eElementsTypes.EET_BANNER_PANEL].classList.remove('hidden');
        this.interactiveElements[this._eElementsTypes.EET_ENDED_LABEL].classList.add('hidden');
        this.interactiveElements[this._eElementsTypes.EET_ENDED_BLOCK].classList.add('hidden');
        this.interactiveElements[this._eElementsTypes.EET_ENDS_BLOCK].classList.add('hidden');
        this.interactiveElements[this._eElementsTypes.EET_STARTS_BLOCK].classList.remove('hidden');

        this._endTimestamp = new Date(this._data.details.startAt).getTime();
        this._updateTimer();
        this._startTimer();
      }
        break;
    }
  }

  _updateTimer() {
    const { unix, days, hours, minutes, seconds } = getTimeLeftTillTimestamp(this._endTimestamp);
    this._timeTillEnd = unix;

    switch (this._data.status) {
      case eTournamentStatus.ETS_ACTIVE: {
        this.set(this._eElementsTypes.EET_ENDS_DAYS_VALUE, days);
        this.set(this._eElementsTypes.EET_ENDS_HOURS_VALUE, hours);
        this.set(this._eElementsTypes.EET_ENDS_MINUTES_VALUE, minutes);
        break;
      }
      case eTournamentStatus.ETS_FUTURE: {
        this.set(this._eElementsTypes.EET_STARTS_DAYS_VALUE, days);
        this.set(this._eElementsTypes.EET_STARTS_HOURS_VALUE, hours);
        this.set(this._eElementsTypes.EET_STARTS_MINUTES_VALUE, minutes);
        break;
      }
    }
  }

  _startTimer() {
    if (!this._visible) return;
    this._tickTimerStopped = false;
    requestAnimationFrame(this._tick);
  }

  _tick(timestamp) {
    if (this._tickTimerStopped) {
      this._lastTickTimestamp = null;
      return;
    }

    if (this._timeTillEnd <= 0) {
      this._lastTickTimestamp = null;
      this._update();
      return;
    }

    if (!this._lastTickTimestamp) this._lastTickTimestamp = timestamp;
    if (timestamp - this._lastTickTimestamp < 1000) return requestAnimationFrame(this._tick);
    this._lastTickTimestamp = timestamp;

    this._updateTimer();
    requestAnimationFrame(this._tick);
  }

  cleanup() {
    super.cleanup();
    this._tickTimerStopped = true;
    this._visible = false;
  }

  _formatDates(startDate, endDate) {
    const regexp = /\d\d\d\d-\d\d-\d\d/;
    const start = startDate.match(regexp)[0];
    const end = endDate.match(regexp)[0];
    if (!start || !end) return;

    return `${start.split('-').join('/')} -\n ${end.split('-').join('/')}`;
  }

  get dataSelector() {
    return (data) => ({ details: data.details, status: data.status });
  }

  set scaleData({ scaleData }) {
    const needToUpdateBanner = this._currentOrientation !== scaleData.orientation;
    this._currentOrientation = scaleData.orientation;

    if (needToUpdateBanner) {
      this._setupBanner();
    }
  }

  _getMarkup() {
    return `<div class="system-ui-container__tournaments_main__body_description_promo_block__banner_box">
              <img id=${this.interactiveElementsIds[this._eElementsTypes.EET_BANNER]} src="" alt="" />
              <div id=${this.interactiveElementsIds[this._eElementsTypes.EET_ENDED_LABEL]} class="system-ui-container__tournaments_main__body_description_promo_block__banner_box__end_label" id=${this.interactiveElementsIds[this._eElementsTypes.EET_LABEL_END]}>
                  ${this._getLocalization(this._localizations[this._eElementsTypes.EET_ENDED_LABEL])}
                </div>
              <div id=${this.interactiveElementsIds[this._eElementsTypes.EET_BANNER_PANEL]}
                  class="system-ui-container__tournaments_main__body_description_promo_block__banner_box__timer_overlay">
                  
                <div class="system-ui-container__tournaments_main__body_description_promo_block__banner_box__timer_overlay__starts_block" id=${this.interactiveElementsIds[this._eElementsTypes.EET_STARTS_BLOCK]}>
                  <span id=${this.interactiveElementsIds[this._eElementsTypes.EET_STARTS_LABEL]}>${this._getLocalization(this._localizations[this._eElementsTypes.EET_STARTS_LABEL])}</span> 
                  <div>
                    <div>
                      <span id=${this.interactiveElementsIds[this._eElementsTypes.EET_STARTS_DAYS_VALUE]}>1</span>
                      <span id=${this.interactiveElementsIds[this._eElementsTypes.EET_STARTS_DAYS_LABEL]}>${this._getLocalization(this._localizations[this._eElementsTypes.EET_STARTS_DAYS_LABEL])}</span>
                    </div>
                    <div>
                      <span id=${this.interactiveElementsIds[this._eElementsTypes.EET_STARTS_HOURS_VALUE]}>23</span>
                      <span id=${this.interactiveElementsIds[this._eElementsTypes.EET_STARTS_HOURS_LABEL]}>${this._getLocalization(this._localizations[this._eElementsTypes.EET_STARTS_HOURS_LABEL])}</span>
                    </div>
                    <div>
                      <span id=${this.interactiveElementsIds[this._eElementsTypes.EET_STARTS_MINUTES_VALUE]}>15</span>
                      <span id=${this.interactiveElementsIds[this._eElementsTypes.EET_STARTS_MINUTES_LABEL]}>${this._getLocalization(this._localizations[this._eElementsTypes.EET_STARTS_MINUTES_LABEL])}</span>
                    </div>
                  </div>
                </div>
                
                <div class="system-ui-container__tournaments_main__body_description_promo_block__banner_box__timer_overlay__ends_block" id=${this.interactiveElementsIds[this._eElementsTypes.EET_ENDS_BLOCK]}>
                  <span id=${this.interactiveElementsIds[this._eElementsTypes.EET_ENDS_LABEL]}>${this._getLocalization(this._localizations[this._eElementsTypes.EET_ENDS_LABEL])}</span> 
                  <div>
                    <div>
                      <span id=${this.interactiveElementsIds[this._eElementsTypes.EET_ENDS_DAYS_VALUE]}>1</span>
                      <span id=${this.interactiveElementsIds[this._eElementsTypes.EET_ENDS_DAYS_LABEL]}>${this._getLocalization(this._localizations[this._eElementsTypes.EET_ENDS_DAYS_LABEL])}</span>
                    </div>
                    <div>
                      <span id=${this.interactiveElementsIds[this._eElementsTypes.EET_ENDS_HOURS_VALUE]}>23</span>
                      <span id=${this.interactiveElementsIds[this._eElementsTypes.EET_ENDS_HOURS_LABEL]}>${this._getLocalization(this._localizations[this._eElementsTypes.EET_ENDS_HOURS_LABEL])}</span>
                    </div>
                    <div>
                      <span id=${this.interactiveElementsIds[this._eElementsTypes.EET_ENDS_MINUTES_VALUE]}>15</span>
                      <span id=${this.interactiveElementsIds[this._eElementsTypes.EET_ENDS_MINUTES_LABEL]}>${this._getLocalization(this._localizations[this._eElementsTypes.EET_ENDS_MINUTES_LABEL])}</span>
                    </div>
                  </div>
                </div>
                
                <div class="system-ui-container__tournaments_main__body_description_promo_block__banner_box__timer_overlay__ended_block" id=${this.interactiveElementsIds[this._eElementsTypes.EET_ENDED_BLOCK]}>
                  <span id=${this.interactiveElementsIds[this._eElementsTypes.EET_TITLE]}></span>
                  <span id=${this.interactiveElementsIds[this._eElementsTypes.EET_DATES]}></span>
                </div>
              </div>
            </div>`
  }
}
