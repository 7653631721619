export default class LocalStorageManager {
  static setToStorage({ key, value, stringify, onError }) {
    try {
      const data = stringify ? JSON.stringify(value) : value;
      localStorage.setItem(key, data);
    } catch (e) {
      console.error(e);
      onError && onError(e);
    }
  };

  static getFromStorage({ key, parse, onError }) {
    try {
      const value = localStorage.getItem(key);
      return parse ? JSON.parse(value) : value;
    } catch (e) {
      console.error(e);
      onError && onError(e);
    }
  };

  static removeFromStorage({ key, onError }) {
    try {
      return localStorage.removeItem(key);
    } catch (e) {
      console.error(e);
      onError && onError(e);
    }
  };
}
