import UrlParser from './urlParser';
import Branding from './branding';
import MessageWindow from '../messageWindow/messageWindow';
import { errorTypes } from '../utils/errorTypes';
import ControllerStatistic from '../statistic/ControllerStatistic';
import EventManager from '../events/manager';

export default new class Utility {
  constructor() {
    this.urlParser = new UrlParser();
    this.branding = new Branding(this.urlParser.params);
    this.eventManager = EventManager;
    this.ControllerStatistic = new ControllerStatistic();
    this.messageWindow = new MessageWindow();
    this.errorTypes = errorTypes;

    window.OPUtility = this;
  }
}
