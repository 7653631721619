import BasePreloader from 'Preloader/BasePreloader';
import gameLogoSrc from './logo.webp';
import 'Preloader/index.css';
import './gamePreloader.css';

export default new class gamePreloader extends BasePreloader {
  constructor() {
    super();
  }
  createMarkup() {
    super.createMarkup();
    const gameLogo = document.createElement('img');
    gameLogo.src = gameLogoSrc;
    gameLogo.classList.add('game_logo');
    this.preloaderElement.querySelector('.preloaderContainer').prepend(gameLogo);
  }
}
