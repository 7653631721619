import BaseManagerWS from './base/baseManagerWS';
import eTournamentsWS from './eTournamentsWS';
import { getUrlParam } from '../utils/url';

export default class ManagerTournamentsWS extends BaseManagerWS {
  constructor({ onMessage, onClose, authorizationToken}) {
    const authorizationData = { id: 'auth', key: authorizationToken };
    const api = getUrlParam('api');
    const mode = /\.dev\./.test(api) ? 'dev.' : /\.stage\./.test(api) ? 'stage.' : '';
    super({ url: `wss://tournaments.${mode}onlyplay.net/info/websocket`, onMessage, authorizationData, autoReconnect: false });

    this.externalOnClose = onClose;
  }

  async requestTournamentsInfo() {
    return await this.ws.send({
      id: eTournamentsWS.ETWS_INFO,
    });
  }

  async requestLeaderboard(currentCount, tournamentId) {
    return await this.ws.send({
      id: eTournamentsWS.ETWS_MORE,
      type: 'leaderboard',
      tournamentId,
      currentCount
    });
  }

  async onClose() {
    super.onClose();
    await this.externalOnClose();
    this.reconnect();
  }

  set authorizationToken(token) {
    this.authorizationData.key = token;
  }
}
