export default new class SpinTimeManager {
    constructor() {
        this._startTime = 0;
        this._minimalTime = 0;
    }

    start() {
        this._startTime = Date.now();
    }

    stop() {
        this._startTime = 0;
    }

    get minimalTime() {
        return this._minimalTime;
    }

    set minimalTime(value) {
        this._minimalTime = value;
    }

    get startTime() {
        return this._startTime;
    }

    get currentTime() {
        return Date.now() - this.startTime;
    }

    get timeLeft() {
        let value = this.minimalTime - this.currentTime
        return value < 0 ? 0 : value;
    }

    getTimeLeft(targetDelay) {
        return targetDelay - this.currentTime;
    }
}
