import BaseUi from '../baseUi';
import SystemUIInterface from './systemUIInterface';
import eControllerTypes from '../../controllers/system/eControllerTypes';
import {
  ControllerHonesty,
  ControllerTournaments,
  ControllerNotifications,
  ControllerTournamentsStartScreen,
  ControllerModalTournamentEnd,
  ControllerSidebar,
  ControllerRoundsHistory,
  ControllerTournamentsUpcomingScreen,
} from '../../controllers';

export default class BaseSystemUi extends BaseUi {
  constructor(props) {
    super(props);

    this._config = {
      sidebar: {},
      colors: {},
      ...this._config,
    };

    this._eControllersClasses = {
      [this.controllerTypes.ECT_HONESTY]: ControllerHonesty,
      [this.controllerTypes.ECT_TOURNAMENTS]: ControllerTournaments,
      [this.controllerTypes.ECT_TOURNAMENTS_START_SCREEN]: ControllerTournamentsStartScreen,
      [this.controllerTypes.ECT_MODAL_TOURNAMENT_END]: ControllerModalTournamentEnd,
      [this.controllerTypes.ECT_NOTIFICATIONS]: ControllerNotifications,
      [this.controllerTypes.ECT_SIDEBAR]: ControllerSidebar,
      [this.controllerTypes.ECT_ROUNDS_HISTORY]: ControllerRoundsHistory,
      [this.controllerTypes.ECT_TOURNAMENTS_UPCOMING_SCREEN]: ControllerTournamentsUpcomingScreen,
    };
  }

  hide() {
    throw new Error('System ui cannot be hidden');
  }

  _checkConfig() {
  }

  _initInterface() {
    return new SystemUIInterface(this);
  }

  _setupRoot() {
    this.root.setAttribute('id', 'root-system-ui');
    this.root.classList.add('system-ui-container');
  }

  _createInterfaceContainer() {
    const interfaceContainer = document.createElement('div');
    interfaceContainer.setAttribute('id', 'system-interface-container');
    this.root.append(interfaceContainer);
    return interfaceContainer;
  }

  _afterControllersInit() {
    const controllerSidebar = this.controllers[this.controllerTypes.ECT_SIDEBAR];
    controllerSidebar.config = { buttons: this._config.sidebar.buttons || [] };

    this._addExtraControllersListeners();
  }

  _addExtraControllersListeners() {
  }

  get controllersIdPrefix() {
    return 'system_ui';
  }

  get controllerTypes() {
    return eControllerTypes;
  }

  set partnerConfig(config) {
    this._partnerConfig = config;
    this._checkConfig();
  }

  set scaleData(data) {
    this._scaleData = data;
    for (const key in this.controllers) {
      const controller = this.controllers[key];
      controller.scaleData = { scaleData: this._scaleData, rootScale: 1 };
    }
  }
}
