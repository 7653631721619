import FlashLib from 'flashlib_onlyplay';
import MoneyFormat from 'Engine/base/moneyFormat/MoneyFormat';

export default class FreeSpinsTotalWinContainer extends FlashLib.MovieClip {
    constructor(data, displayItemData) {
        super(data, displayItemData);
    }

    setValue(value) {
        this.valueText = this.getChildByName('valueText');
        this.valueText.text = value ? MoneyFormat.format(value, true) : '';
    }
}
