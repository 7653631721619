import FlashLib from 'flashlib_onlyplay';
import EntryPoint from "Engine/EntryPoint";

export default class BaseSlotSymbol extends FlashLib.MovieClip {
    constructor(data, displayItemData) {
        super(data, displayItemData);

        this.id = 1;
        this.config = EntryPoint.configData.symbols;
        this.reel = 0;
        this.incativeTintColor = 0x444444;
        this.iterateInterval = null;

        this.init();
        this.changeSymbol(this.id);
    }

    init() {

    }

    onShowCompete() {
        if (!this.iterateSymbols.length) return;
    }

    changeSymbol(id, reel) {
        this.id = id;
        this.reel = reel;
        this.goToFrame(id);
        this.image = this.getChildAt(0);
    }

    iterateOverSymbols(symbols) {
        this.iterateSymbols = symbols;
        this.alpha = 0;

        this.animateSymbolChange(this.iterateSymbols.pop());

        this.iterateInterval = setInterval(() => {
            this.animateSymbolChange(this.iterateSymbols.pop());

            if (!this.iterateSymbols.length) {
                this.clearInterval();
            }
        }, 200)
    }

    animateSymbolChange(symbol) {
        this.changeSymbol(symbol);
    }

    startAnimation() {
    }

    stopAnimation() {

    }

    setBlur(value) {
        this.goToFrame(value ? this.id + this.config.count : this.id);
    }

    setActive(value) {
        let image = this.getChildAt(0);
        if(!image.tint) {
            image = image.getChildAt(0);
        }
        image.tint = value ? 16777215 : this.incativeTintColor;
    }

    clearInterval() {
        if (this.iterateInterval) {
            clearInterval(this.iterateInterval);
            this.iterateInterval = null;
        }
    }
}
