import GlobalDispatcher from '../../events/GlobalDispatcher';

export default class BaseGameModel {
    constructor() {

    }

    init() {
        GlobalDispatcher.dispatch('model:init');
        this.addListeners();
    }

    addListeners() {

    }
}