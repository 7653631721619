import winController from './winController';
import  gsap  from 'gsap';

export default class MegaWinController extends winController {
  constructor(...arg) {
    super(...arg);
    this._moveCounterDuration = 2;
    this._moveCounterValue = 10;
  }

  onStart() {
    this.coinParticles2.visible = true;
    this.coinParticles2.start();
    // gsap.to(this.winAmountContainer, { y: `+=${this._moveCounterValue}`, duration: this._moveCounterDuration, ease: 'power4.out' });
    this._dirty = true;
  }

  onSkip() {
    this.onStart();
  }

  cleanup() {
    super.cleanup();
    if (!this._dirty) return;
    if (this.coinParticles2) {
      this.coinParticles2.stop();
      this.coinParticles2.visible = false;
    }

    gsap.killTweensOf(this.winAmountContainer)
    this._dirty = false;
  }
}
