import * as PIXI from 'pixi.js';
import GlobalDispatcher from '../../events/GlobalDispatcher';
//import PixiSoundSpriteLoader from "./PixiSoundSpriteLoader";
import EntryPoint from '../../EntryPoint';
import FontsLoader from 'Engine/base/preloader/fontsLoader';
import baseFontsConfig from './baseFontsPreloaderConfig';

export default class BasePreloaderController extends PIXI.Container {

  constructor(config) {
    super();

    //new PixiSoundSpriteLoader();

    this._config = config;
    this._brandingConfig = this._parseBrandingConfig(this._config);
    this._compiled = EntryPoint.compiled;
    this._mobile = EntryPoint.mobile;
    this._hasServerData = false;
    this._hasResources = false;
    this._canStart = true;

    this.addListeners();
    this.loadFonts();
  }

  addListeners() {
    GlobalDispatcher.addOnce('model:getServerData', this.onGetServerData, this);
  }

  onGetServerData() {
    this.hasServerData = true;
  }

  loadFonts() {
    const fonts = baseFontsConfig;
    if (this._config.fonts) fonts.push(...this._config.fonts);
    if (this._brandingConfig.fonts) fonts.push(...this._brandingConfig.fonts);

    if (fonts.length) {
      FontsLoader.load(fonts)
        .then(() => {
          this.loadGame();
        })
        .catch(this.onLoadingError.bind(this));
    } else {
      this.loadGame();
    }
  }

  loadGame() {
    PIXI.Assets.reset();
    // PIXI.Assets.baseUrl = './assets';

    this.bundleAssets = {};
    this.addSounds();
    this.addAssets();
    PIXI.Assets.addBundle('assets', this.bundleAssets);
    PIXI.Assets.loadBundle('assets', this.onLoadingUpdate.bind(this)).then(this.onLoadingComplete.bind(this)).catch(this.onLoadingError.bind(this));
  }

  addAssets() {
    const assets = [...this._config.assets];
    if (this._brandingConfig.assets) assets.push(...this._brandingConfig.assets);

    assets.forEach(asset => {
      this._adjustAssetPath(asset);
      this.bundleAssets[asset.name] = { src: asset.path, data: asset.options };
    });
  }

  addSounds() {
    const sounds = [...Object.values(this._config.sounds)];
    if (this._brandingConfig.sounds) sounds.push(...Object.values(this._brandingConfig.sounds));

    sounds.forEach(sound => {
      this.bundleAssets[sound] = `assets/sounds/${sound}.mp3`
    });
  }

  onLoadingUpdate(data) {
    GlobalDispatcher.dispatch('preloaderController:progress', data * 100);
  }

  onLoadingComplete(event) {
    this.startLoadingDelayedAssets();
    this.emit('allResourcesLoaded');
    GlobalDispatcher.dispatch('preloaderController:allResourcesLoaded');
    this.onLoadingUpdate(1);
    this.hasResources = true;
  }

  startLoadingDelayedAssets() {
    const delayedAssets = [];
    if (this._config.delayedAssets) delayedAssets.push(...this._config.delayedAssets);
    if (this._brandingConfig.delayedAssets) delayedAssets.push(...this._brandingConfig.delayedAssets);

    delayedAssets.forEach(asset => {
      this._adjustAssetPath(asset);
      PIXI.Assets.add(asset.name, asset.path, asset.options);
      PIXI.Assets.backgroundLoad(asset.name);
    });
  }

  onLoadingError(event) {
    console.error(event);
    window.OPWrapperService.showError(window.OPWrapperService.errors.ASSETS_ERROR.CODE);
    PIXI.Assets.reset();
  }

  allComplete() {
    if (this.hasResources && this.hasServerData && this.canStart) {
      this.startGame();
    }
  }

  startGame() {
    if (this.hasResources && this.hasServerData && this.canStart) {
      this.emit('gameLoadingComplete');
    }
  }

  _adjustAssetPath(asset) {
    if (!(/https?/.test(asset.path)) && !(/base64/.test(asset.path))) asset.path = 'assets/' + asset.path;
  }

  _parseBrandingConfig(config) {
    let brandingConfig = {};

    if (config.brandingAssets) {
      brandingConfig = OPUtility.branding.getConfigValue(config.brandingAssets);
      if (!brandingConfig) window.OPWrapperService.showError(window.OPWrapperService.errors.BRANDED_ASSETS_NOT_FOUND.CODE);
    }

    return brandingConfig;
  }

  get hasServerData() {
    return this._hasServerData;
  }

  set hasServerData(value) {
    this._hasServerData = value;
    this.allComplete();
  }

  get hasResources() {
    return this._hasResources;
  }

  set hasResources(value) {
    this._hasResources = value;
    this.allComplete()
  }

  get canStart() {
    return this._canStart;
  }

  set canStart(value) {
    this._canStart = value;
    this.allComplete()
  }
}
