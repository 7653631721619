import EventEmitter from '../eventEmitter';

export default class BaseUi extends EventEmitter {
  constructor({ config, partnerConfig }) {
    super();

    this._partnerConfig = partnerConfig;
    this._config = config;
    this._eControllersClasses = {};

    this._eControllersContainersIds = Object.values(this.controllerTypes).reduce((accum, type) => {
      if (accum.hasOwnProperty(type)) {
        throw new Error(`Controller type '${type}' should be uniq`);
      }
      accum[type] = this._createControllerId(type);
      return accum;
    }, {});

    this._overlayHandlers = {
      show: this.showOverlay.bind(this),
      hide: this.hideOverlay.bind(this)
    }

    this._excludedControllerTypes = new Set();
    this._supportedEventsList = new Set();
    this._overlayTogglers = new Set();
    this._controllers = null;
  }

  init(container) {
    this._checkConfig();
    this._setupTheme();

    this._createRoot(container);
    this.interfaceContainer = this._createInterfaceContainer();
    this.interfaceContainer.innerHTML = this._getMarkup();

    this._initControllers();
    this._afterControllersInit();

    this._interface = this._initInterface();
    if (!this._interface) throw new Error(`'_initInterface' method must return an instance of Interface`);
  }

  on(event, callback) {
    if (!this._supportedEventsList.has(event)) return;
    super.on(event, callback)
  }

  // //system api
  // showHonestyControl(data) {
  //   const honestyController = this.controllers[this.controllerTypes.ECT_HONESTY];
  //   if (honestyController) {
  //     //set data to controller
  //     //honestyController.setData(data)
  //     honestyController.show();
  //   }
  // }

  show() {
    this.root.classList.remove('hidden');
  }

  hide() {
    this.root.classList.add('hidden');
  }

  setOpacity(value) {
    this.root.style.opacity = value;
  }

  showOverlay(id) {
    this._overlayTogglers.add(id);
    this.root.classList.add('overlay-visible');

    return () => this.hideOverlay(id);
  }

  hideOverlay(id) {
    this._overlayTogglers.delete(id);
    if (!this._overlayTogglers.size) this.root.classList.remove('overlay-visible');
  }

  remove() {
    this.removeAllListeners();
    for (let controller of Object.values(this.controllers)) {
      controller.removeAllListeners();
      controller.beforeRemove();
    }
    this.root.remove();
  }

  _createRoot(container = document.body) {
    this.root = document.createElement('div');
    this._setupRoot();
    container.prepend(this.root);
  }

  _initControllers() {
    this._controllers = {};
    for (let key in this._eControllersContainersIds) {
      this._initController(key);
    }
  }

  _initController(key) {
    if (this._excludedControllerTypes.has(key)) return;

    const id = this._eControllersContainersIds[key];
    const container = document.getElementById(id);
    if (!container) throw new Error(`Can't find container by given id: '${id}'`);

    const controller = this._eControllersClasses[key];
    this._controllers[key] = new controller({ container, type: key, overlayHandlers: this._overlayHandlers });

    this._addControllerListeners(key);
  }

  _addControllerListeners(type) {
    if (!this._controllers[type].events) return;

    for (let event of Object.values(this._controllers[type].events[type])) {
      const events = this._getEventsRecursively(event);

      events.forEach(e => {
        if (this._supportedEventsList.has(e)) throw new Error(`Event type '${e}' already exist`);

        this._supportedEventsList.add(e);
        this._controllers[type].on(e, (params) => {
          console.log(this.constructor.name, ': ', e);
          this.emit(e, params)
        });
      });
    }
  }

  _getEventsRecursively(event, events = []) {
    if (typeof event === 'object') {
      const keys = Object.keys(event);
      for (let key of keys) {
        this._getEventsRecursively(event[key], events);
      }
    } else if (typeof event === 'string') {
      events.push(event);
    }

    return events;
  }

  _setupTheme() {
    if (this._config && this._config.colors) {
      for (let key in this._config.colors) {
        for (let state in this._config.colors[key]) {
          document.documentElement.style.setProperty(`--color-${key}--${state}`, this._config.colors[key][state]);
        }
      }
    }
  }

  _setupRoot() {}

  _checkConfig() {}

  _afterControllersInit() {}

  _createControllerId(type) {
    return `${this.controllersIdPrefix}__${type}`;
  }

  _initInterface() {
    throw new Error(`You must override '_initInterface' method inside derived class`);
  }

  _createInterfaceContainer() {
    throw new Error(`You must override '_createInterfaceContainer' method inside derived class`);
  }

  _getMarkup() {
    throw new Error(`You must override '_getMarkup' method inside derived class`);
  }

  set scaleData(data) {
    throw new Error(`You must override 'set scaleData' method inside derived class`);
  }

  get controllerTypes() {
    throw new Error(`You must override 'get controllerTypes' method inside derived class`);
  }

  get controllersIdPrefix() {
    throw new Error(`You must override 'get controllersIdPrefix' method inside derived class`);
  }

  get events() {
    const events = {};

    if (this._controllers) {
      for (let controller of Object.values(this._controllers)) {
        Object.assign(events, controller.events);
      }
    }

    return events;
  }

  get controllers() {
    return this._controllers;
  }

  get controllersContainersIds() {
    return this._eControllersContainersIds;
  }

  get supportedEventsList() {
    return this._supportedEventsList;
  }

  get Interface() {
    return this._interface;
  }
}
