export class SocketController {
  constructor({ url, onMessage, onOpen, onClose, authorizationData }) {
    this.requestId = 0;
    this.responseCallbacks = {};
    this.callStack = [];
    this.externalOnMessage = onMessage;
    this.externalOnOpen = onOpen;
    this.externalOnClose = onClose;
    this.url = url;
    this.authorizationData = authorizationData;

    this.connect = this.connect.bind(this);
    this.onMessage = this.onMessage.bind(this);
    this.onOpen = this.onOpen.bind(this);
    this.onError = this.onError.bind(this);
    this.onClose = this.onClose.bind(this);
    this.reconnect = this.reconnect.bind(this);
    this.connect(url);
  }

  connect(url) {
    this.ws = new WebSocket(url);
    this.ws.addEventListener('message', this.onMessage);
    this.ws.addEventListener('open', this.onOpen);
    this.ws.addEventListener('error', this.onError);
    this.ws.addEventListener('close', this.onClose);
  }

  onOpen() {
    if (this.ws.readyState > 1) return;
    if (this.ws.readyState === 0) return setTimeout(() => this.onOpen(), 100);
    console.log('%cws is open', 'background-color: green');
    this.externalOnOpen();
    this.tryAuth();

    while (this.callStack.length > 0) {
      const item = this.callStack.shift();
      this._sendWithPromise(item.message, item.resolve, item.reject);
    }
  }

  tryAuth() {
    if (this.authorizationData) this.send(this.authorizationData);
  }

  onError(event) {
    console.error(event.message);
  }

  reconnect(authorizationData) {
    this.close();
    console.log('reconnecting to ws...');
    this.authorizationData = authorizationData;
    this.connect(this.url)
  }

  async onMessage(event) {
    let data = '';
    data = JSON.parse(event.data);
    this.externalOnMessage(data);

    if (data.code) {
      console.error(data.message);
      if (data.hasOwnProperty('requestId')) {
        this.responseCallbacks[data.requestId].reject(data);
        delete this.responseCallbacks[data.requestId];
      }
    }
    if (data.hasOwnProperty('requestId')) {
      if (data.requestId === -1) return;
      this.responseCallbacks[data.requestId].resolve(data);
      delete this.responseCallbacks[data.requestId];
    }
  }

  send(message) {
    if (!navigator.onLine) {
      throw new Error('No Internet connection.')
    }
    return new Promise((resolve, reject) => {
      try {
        this._sendWithPromise(message, resolve, reject);
      } catch (e) {
        if (message && message.id && message.id === 'ping') return;
        console.error(e.message);
        this.callStack.push({ message, resolve, reject });
      }
    })
  }

  close() {
    this.ws.removeEventListener('message', this.externalOnMessage);
    this.ws.removeEventListener('message', this.onMessage);
    this.ws.removeEventListener('open', this.onOpen);
    this.ws.removeEventListener('error', this.onError);
    this.ws.removeEventListener('close', this.onClose);
    if (this.ws.readyState !== 3) this.ws.close();
    this.ws = null;
    console.log('%cws is closed', 'background-color: crimson');
  }

  onClose() {
    console.log('%cws connection lost', 'background-color: crimson');
    this.externalOnClose();
  }

  _sendWithPromise(message, resolve, reject) {
    if (this.ws.readyState !== 1) throw new Error('Can\'t send message trough ws, request will be added to call stack');
    this.ws.send(JSON.stringify({ ...message, requestId: this.requestId }));
    this.responseCallbacks[this.requestId] = { resolve, reject };
    this.requestId++;
  }
}
