import FlashLib from 'flashlib_onlyplay';
import {gsap, TweenMax} from 'gsap';


export default class BaseOverlayContainer extends FlashLib.MovieClip {
    constructor(data, displayData) {
        super(data, displayData);

        this._alphaValue = 0.8;
        this.interactive = true;

        this.init();
        this.addListeners();
    }

    init() {
        this.getChildAt(0).alpha = this.alphaValue;
    }

    addListeners() {

    }

    get alphaValue() {
        return this._alphaValue;
    }

    set alphaValue(value) {
        this._alphaValue = value;
        this.getChildAt(0).alpha = this.alphaValue;
    }

    show(duration = 0.6) {
        this.visible = true;
        const alpha = 1;
        if (gsap) {
            gsap.killTweensOf(this);
            gsap.to(this, { alpha, duration });
        } else {
            TweenMax.killTweensOf(this);
            TweenMax.to(this, duration, { alpha });
        }
    }

    hide(duration = 0.6) {
        const alpha = 0;
        if (gsap) {
            gsap.killTweensOf(this);
            gsap.to(this, { alpha, duration, onComplete: () => this.visible = false });
        } else {
            TweenMax.killTweensOf(this);
            TweenMax.to(this, duration, { alpha, onComplete: () => this.visible = false });
        }
    }

    changeColor(color=0x0) {
        const graphic = this.getChildAt(0)
        graphic.clear();
        graphic.beginFill(color, 1)
        graphic.drawRect(-this.displayData.width / 2, -this.displayData.height / 2, this.displayData.width, this.displayData.height)
        graphic.endFill();
        graphic.alpha = this.alphaValue;
    }
}
