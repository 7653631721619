export default class EventDataChange {
  constructor(initialData) {
    this._listeners = [];
    this._processingIndex = null;

    this._data = initialData;
  }

  add(listener, selector) {
    if (!listener) {
      console.error('listener is undefined');
      console.trace();
      return;
    }

    const item = { listener };
    if (selector) {
      item.selector = selector;
      item.observeTarget = selector(this._data);
    }
    this._listeners.push(item);
    return () => this.remove(listener);
  }

  remove(listener) {
    const listenerIndex = this._listeners.findIndex(item => item.listener === listener);
    if (listenerIndex === -1) return;

    if (this._processingIndex !== null && listenerIndex <= this._processingIndex) {
      this._processingIndex--;
    }

    this._listeners.splice(listenerIndex, 1);
  }

  call(data) {
    this._data = data;
    for (this._processingIndex = 0; this._processingIndex < this._listeners.length; this._processingIndex++) {
      const { listener, observeTarget, selector } = this._listeners[this._processingIndex];
      let nextData = this._data;

      if (selector) {
        nextData = selector(this._data);
        if (observeTarget === nextData) continue;
        this._listeners[this._processingIndex].observeTarget = nextData;
      }

      listener(nextData);
    }

    this._processingIndex = null;
  }
}
