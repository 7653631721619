import BaseInfoItem from './BaseInfoItem';
import LocalizationManager from '../../i18n/LocalizationManager';
import Stopwatch from '../../utils/Stopwatch';

export default class StopwatchInfoItem extends BaseInfoItem {
  constructor(container) {
    super(container);
    this.view.classList.add('opPlayTime');
    Stopwatch.onUpdateTime.add(this._updateTime.bind(this));
  }

  _updateTime({timeDifferenceText}) {
    this.view.innerText = `${LocalizationManager.getLocalizedText(this.baseLocalizePath + '.timePlayedTitle')} ${timeDifferenceText}`
  }
}
