import BaseSystemController from '../baseSystemController';
import { OP_WRAPPER_LOCALIZATION_PREFIX } from '../../../../utils/constants';

export default class ControllerRoundsList extends BaseSystemController {
  constructor({ container, ...props }) {
    super({ ...props });
    this._eEventTypes = {
      SHOW_RESULT_CLICK: this.getEventName('show_result'),
      LOAD_MORE: this.getEventName('load_more'),
    };
    this._eElementsTypes = {
      EET_TABLE_HEAD_1: 'table_head_1',
      EET_TABLE_HEAD_2: 'table_head_2',
      EET_TABLE_HEAD_3: 'table_head_3',
      EET_TABLE_HEAD_4: 'table_head_4',
      EET_TABLE_BODY: 'table_body',
    };
    this._localizations = {
      [this._eElementsTypes.EET_TABLE_HEAD_1]: `${OP_WRAPPER_LOCALIZATION_PREFIX}.roundsHistory.rounds_table.head_1`,
      [this._eElementsTypes.EET_TABLE_HEAD_2]: `${OP_WRAPPER_LOCALIZATION_PREFIX}.roundsHistory.rounds_table.head_2`,
      [this._eElementsTypes.EET_TABLE_HEAD_3]: `${OP_WRAPPER_LOCALIZATION_PREFIX}.roundsHistory.rounds_table.head_3`,
      [this._eElementsTypes.EET_TABLE_HEAD_4]: `${OP_WRAPPER_LOCALIZATION_PREFIX}.roundsHistory.rounds_table.head_4`,
    };

    this._currentPage = 1;
    this._intersectionObserver = new IntersectionObserver((entry, observer) => {
      if (entry[0].isIntersecting) {
        this.emit(this._eEventTypes.LOAD_MORE, ++this._currentPage);
        observer.disconnect();
      }
    }, {
      root: this._container,
      rootMargin: '0px',
      threshold: 0,
    });

    this.init(container);
  }

  cleanup() {
    super.cleanup();
    this._intersectionObserver.disconnect();
    this._currentPage = 1;
  }

  _onDataUpdated() {
    super._onDataUpdated();
    this._updateTable(this._data);
  }

  _updateTable({ list, hasMore }) {
    const tableBody = this.interactiveElements[this._eElementsTypes.EET_TABLE_BODY];
    const listMap = Object.fromEntries(list.map(item => [item.id, item]));
    const rows = this._checkAndRemoveNotExistRows(Array.from(tableBody.children), listMap);
    const rowsMap = Object.fromEntries(rows.map(row => [row.dataset.id, row]));

    for (let item of list) {
      let row = rowsMap[item.id];

      if (!row) {
        row = this._createRow(item);
      }

      tableBody.appendChild(row);
    }

    const lastRow = tableBody.children[tableBody.children.length - 1];

    if (hasMore) {
      this._intersectionObserver.observe(lastRow);
    }
  }

  _createRow(data) {
    const { id, end_date, bet_amount, profit, currency } = data;
    const currencyUpper = currency.toUpperCase();
    const isoDate = new Date(end_date).toISOString();
    const [date, time] = isoDate.split('T');

    const timeString = `${date} ${time.slice(0, time.indexOf('.'))}`;
    const betString = `${bet_amount} ${currencyUpper}`;
    const profitString = `${profit} ${currencyUpper}`;

    const button = document.createElement('button');
    button.classList.add('system-ui-container__modal__rounds_history__body__rounds_table__row');

    button.onclick = () => this.emit(this._eEventTypes.SHOW_RESULT_CLICK, data);
    button.setAttribute('data-id', id);
    button.innerHTML = `<div class="system-ui-container__modal__rounds_history__body__rounds_table__row__cell">
                      <span title=${timeString}>${timeString}</span>
                    </div>
                    <div class="system-ui-container__modal__rounds_history__body__rounds_table__row__cell">
                      <span title=${id}>${id}</span>
                    </div>
                    <div class="system-ui-container__modal__rounds_history__body__rounds_table__row__cell">
                      <span title=${betString}>${betString}</span>
                    </div>
                    <div class="system-ui-container__modal__rounds_history__body__rounds_table__row__cell">
                      <span title=${profitString}>${profitString}</span>
                    </div>
                    <div class="system-ui-container__modal__rounds_history__body__rounds_table__row__cell">
                      <span>
                        <svg width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M2.06 1.76333L10.2733 10L2.06 18.2367" stroke="white" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                      </span>
                    </div>`;

    return button;
  }

  _checkAndRemoveNotExistRows(currentRows, data) {
    return currentRows.filter(row => {
      if (data[row.dataset.id]) {
        return true;
      }

      row.remove();
      return false;
    });
  }

  get dataSelector() {
    return (data) => data.rounds;
  }

  _getMarkup() {
    return `<div class="system-ui-container__modal__rounds_history__body__rounds_table">
              <div class="system-ui-container__modal__rounds_history__body__rounds_table__head">
                <div class="system-ui-container__modal__rounds_history__body__rounds_table__row">
                  <div class="system-ui-container__modal__rounds_history__body__rounds_table__row__cell">
                    <span id=${this.interactiveElementsIds[this._eElementsTypes.EET_TABLE_HEAD_1]}>${this._getLocalization(this._localizations[this._eElementsTypes.EET_TABLE_HEAD_1])}</span>
                  </div>
                  <div class="system-ui-container__modal__rounds_history__body__rounds_table__row__cell">
                    <span id=${this.interactiveElementsIds[this._eElementsTypes.EET_TABLE_HEAD_2]}>${this._getLocalization(this._localizations[this._eElementsTypes.EET_TABLE_HEAD_2])}</span>
                  </div>
                  <div class="system-ui-container__modal__rounds_history__body__rounds_table__row__cell">
                    <span id=${this.interactiveElementsIds[this._eElementsTypes.EET_TABLE_HEAD_3]}>${this._getLocalization(this._localizations[this._eElementsTypes.EET_TABLE_HEAD_3])}</span>
                  </div>
                  <div class="system-ui-container__modal__rounds_history__body__rounds_table__row__cell">
                    <span id=${this.interactiveElementsIds[this._eElementsTypes.EET_TABLE_HEAD_4]}>${this._getLocalization(this._localizations[this._eElementsTypes.EET_TABLE_HEAD_4])}</span>
                  </div>
                </div>
              </div>
              <div id=${this.interactiveElementsIds[this._eElementsTypes.EET_TABLE_BODY]} class="system-ui-container__modal__rounds_history__body__rounds_table__body">
              </div>
            </div>`
  }
}
