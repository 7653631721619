import FlashLib from 'flashlib_onlyplay';
import GlobalDispatcher from 'Engine/events/GlobalDispatcher';
import GameModel from './model/GameModel';
import SoundManager from 'Engine/soundManager/SoundManager';
import NearWinCalculator from './NearWinCalculator';
import EntryPoint from 'Engine/EntryPoint';
import { eSounds } from './enums/eSounds';
import './style.css';
import BaseSlotWinningsController from 'Engine/base/winings/BaseSlotWinningsController';

export default class Main extends FlashLib.MovieClip {
  constructor(data, displayItemData) {
    super(data, displayItemData);

    this.reelsContainer = null;
    this.linesContainer = null;
    this.uiContainer = null;
    this.winningContainer = null;

    this.init();
    this.addListeners();
  }

  onGameResized(data) {
    if (EntryPoint.mobile && data.orientation === 'V') {
      this.slot_container.pivot.x = 0;
      this.slot_container.x = this.slot_container.displayData.x;
      this.slot_container.y = this.slot_container.displayData.y;
      this.slot_container.scale.set(this.slot_container.displayData.scaleX);
      this.logoContainer.y = this.logoContainer.displayData.y - 10;
      this.logoContainer.x = this.logoContainer.displayData.x - 40;
      this.logoContainer.scale.set(0.8);
    } else {
      this.slot_container.pivot.x = this.slot_container.displayData.width / 2;
      this.slot_container.x = this.slot_container.displayData.x + this.slot_container.displayData.width / 2 + 30;
      this.slot_container.y = this.slot_container.displayData.y - 157;
      this.slot_container.scale.set(1.4);
      this.logoContainer.y = this.logoContainer.displayData.y + 55;
      this.logoContainer.x = this.logoContainer.displayData.x;
      this.logoContainer.scale.set(0.65);
    }
  }

  init() {
    this.playBGSound();

    this.winningsController = new BaseSlotWinningsController();

    this.slot_container = this.getChildByName('slot_container');
    this.logoContainer = this.slot_container.getChildByName('LogoContainer');
    this.respinButtonsContainer = this.slot_container.getChildByName('RespinButtonsContainer');
    this.respinInReelInfoContainer = this.slot_container.getChildByName('RespinInReelInfoContainer');
    this.reelsContainer = this.slot_container.getChildByName('ReelsContainer');
    this.symbolsAnimationsContainer = this.reelsContainer.wrapper.getChildByName('SymbolsAnimationsContainer');
    this.linesContainer = this.slot_container.getChildByName('LinesContainer');
    this.lineWinningContainer = this.slot_container.getChildByName('LineWinningContainer');
    this.backgroundContainer = this.getChildByName('BackgroundContainer');
    this.freeSpinsWindowContainer = this.getChildByName('FreeSpinsWindowContainer');
    this.purchaseContainer = this.getChildByName('PurchaseContainer');
    if (EntryPoint.mobile) {
      this.sakuraMobileContainer = this.slot_container.getChildByName('SakuraMobileContainer');
    } else {
      this.sakuraContainer = this.getChildByName('SakuraContainer');
    }
  }

  playBGSound() {
    SoundManager.play(eSounds.BACKGROUND.name, eSounds.BACKGROUND.volume, true, 'music');
  }

  addListeners() {
    GlobalDispatcher.add('model:linesChanged', this.onLinesChanged, this);
    GlobalDispatcher.add('stateManager.stateChanged', this.onStateChanged, this);
    GlobalDispatcher.add('stateEnd:initState', this.onInitStateEnd, this);
    window.OPWrapperService.eventManager.add(
      window.OPWrapperService.eventManager.types.EET_SCALE_MANAGER_RESIZE,
      this.onGameResized,
      this
    );
    // GlobalDispatcher.add('model:getServerData', this.checkForFreeSpins, this);
  }

  onLinesChanged(data) {
    //this.linesContainer.showLines(data.params);
  }

  onInitStateEnd() {
    if (!EntryPoint.GameModel.isFreeSpinsMode) return;
    this.logoContainer.changeState(2);
    this.backgroundContainer.showFreeSpinsBack();
    if (EntryPoint.mobile) {
      this.sakuraMobileContainer.show();
    } else {
      this.sakuraContainer.show();
    }
    SoundManager.pause(eSounds.BACKGROUND.name);
    SoundManager.play(eSounds.FREE_SPINS_BACK.name, eSounds.FREE_SPINS_BACK.volume, true, 'music');
  }

  onStateChanged(data) {
    switch (data.params) {
      case 'initState':

        break;
      case 'waitUserInteractionState':
        if (this.freeSpinsWindowContainer.isVisible) {
          this.freeSpinsWindowContainer.hide();
        }
        this.respinButtonsContainer.setActive(false);
        this.respinInReelInfoContainer.setActive(false);
        break;
      case 'idleState':
        /*this.reelsContainer.hideWinningSymbols();
        this.linesContainer.hideLines();
        this.linesContainer.reset();*/
        if (this.freeSpinsWindowContainer.isVisible) {
          this.freeSpinsWindowContainer.hide();
        }
        if (!EntryPoint.GameModel.isFreeSpinsMode) {
          if (EntryPoint.mobile) {
            this.sakuraMobileContainer.hide();
          } else {
            this.sakuraContainer.hide();
          }
          this.respinButtonsContainer.setActive(!Object.keys(EntryPoint.GameModel.freeSpins).length && EntryPoint.GameModel.respinEnabled && !EntryPoint.AutoPlaySettings.active);
          this.respinInReelInfoContainer.setActive(!Object.keys(EntryPoint.GameModel.freeSpins).length && EntryPoint.GameModel.respinEnabled && !EntryPoint.AutoPlaySettings.active);
        }
        this.respinButtonsContainer.setData(EntryPoint.GameModel.respinCost);
        this.respinInReelInfoContainer.setData(EntryPoint.GameModel.respinCost);
        break;
      case 'requestRespinRollingState':
        this.reelsContainer.hideWinningSymbols();
        this.reelsContainer.startRespin();
        this.winningsController.reset();
        this.linesContainer.hideLines();
        this.linesContainer.reset();
        this.symbolsAnimationsContainer.removeAnimations();
        this.reelsContainer.setActive(true);
        this.lineWinningContainer.hide();
        this.respinButtonsContainer.setActive(false);
        this.respinInReelInfoContainer.setActive(false);

        NearWinCalculator.calculateRespin(GameModel.respinReelIndex);
        break;
      case 'stopRespinRollingState':
        this.reelsContainer.stopRespin(GameModel.reels);
        break;
      case 'requestRollingState':
        this.reelsContainer.hideWinningSymbols();
        this.reelsContainer.start();
        this.winningsController.reset();
        this.linesContainer.hideLines();
        this.linesContainer.reset();
        this.symbolsAnimationsContainer.removeAnimations();
        this.reelsContainer.setActive(true);
        this.lineWinningContainer.hide();
        this.respinButtonsContainer.setActive(false);
        this.respinInReelInfoContainer.setActive(false);
        break;
      case 'stopRollingState':
        NearWinCalculator.calculate();
        this.reelsContainer.stop(GameModel.reels);
        break;
      case 'showBigWinState':
        this.lineWinningContainer.show(2);
        break;
      case 'showLinesWinState':
        this.lineWinningContainer.show(1);
        break;
      case 'showWinningsState':
        SoundManager.play(eSounds.SMALL_WIN.name);
        this.winningsController.start();
        this.reelsContainer.setActive(false);
        break;
      case 'expandSymbolsState':
        this.reelsContainer.hideWinningSymbols();
        this.winningsController.reset();
        this.symbolsAnimationsContainer.removeAnimations();
        this.lineWinningContainer.hide();
        this.reelsContainer.setActive(false);
        this.linesContainer.hideLines();
        this.linesContainer.reset();
        break;
      case 'showExpandedWinState':
        SoundManager.play(eSounds.SMALL_WIN.name, eSounds.SMALL_WIN.volume, false, 'winning');
        break;
      case 'freeSpinsAddState':
        SoundManager.pause(eSounds.BACKGROUND.name);
        const freeSpinsSound = SoundManager.getSound(eSounds.FREE_SPINS_BACK.name);
        if (!freeSpinsSound.isPlaying) {
          SoundManager.play(eSounds.FREE_SPINS_BACK.name, eSounds.FREE_SPINS_BACK.volume, true, 'music');
        }
        this.freeSpinsWindowContainer.show(1);
        this.reelsContainer.hideWinningSymbols();
        this.winningsController.reset();
        this.symbolsAnimationsContainer.removeAnimations();
        this.lineWinningContainer.hide();
        this.reelsContainer.setActive(true);
        this.linesContainer.hideLines();
        this.linesContainer.reset();
        this.logoContainer.changeState(2);
        this.backgroundContainer.showFreeSpinsBack();
        if (EntryPoint.mobile) {
          this.sakuraMobileContainer.show();
        } else {
          this.sakuraContainer.show();
        }
        GlobalDispatcher.dispatch('ui:changeAutoPlay', true);
        break;
      case 'freeSpinsOutState':
        SoundManager.resume(eSounds.BACKGROUND.name);
        SoundManager.stop(eSounds.FREE_SPINS_BACK.name);
        this.freeSpinsWindowContainer.show(2);
        this.reelsContainer.hideWinningSymbols();
        this.winningsController.reset();
        this.symbolsAnimationsContainer.removeAnimations();
        this.lineWinningContainer.hide();
        this.reelsContainer.setActive(true);
        this.linesContainer.hideLines();
        this.linesContainer.reset();
        this.logoContainer.changeState(1);
        this.backgroundContainer.hideFreeSpinsBack();
        if (EntryPoint.mobile) {
          this.sakuraMobileContainer.hide();
        } else {
          this.sakuraContainer.hide();
        }
        GlobalDispatcher.dispatch('ui:changeAutoPlay', false);
        break;
    }
  }
}
