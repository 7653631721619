import { EMPostMessageTypes, EMReceiveMessageTypes } from './constants';
import test from './test';
import EventManager from '../../events/manager';
import EMErrorCodes from './EMErrorCodes';

export default class EveryMatrixMessageManager {
  constructor() {
    window.addEventListener('message', this._onMessage);

    EventManager.add(EMPostMessageTypes.PMT_LOAD_PROGRESS, (data) => this._onLoadProgress(data));
    EventManager.add(EMPostMessageTypes.PMT_LOAD_COMPLETED, () => this._onLoadCompleted());
    EventManager.add(EMPostMessageTypes.PMT_AUDIO_TOGGLE, (data) => this._onAudioToggle(data));
    EventManager.add(EMPostMessageTypes.PMT_ROUND_START, () => this._onRoundStart());
    EventManager.add(EMPostMessageTypes.PMT_ROUND_END, () => this._onRoundEnd());
    EventManager.add(EMPostMessageTypes.PMT_STAKE_UPDATE, (data) => this._onStakeUpdate(data));
    EventManager.add(EMPostMessageTypes.PMT_CLOSED, () => this._onClosed());
    EventManager.add(EMPostMessageTypes.PMT_ERROR_MESSAGE, (data) => this._onErrorMessage(data));
  }

  _onMessage(event) {
    const message = event.data;
    const receiveTypes = Object.values(EMReceiveMessageTypes);
    // console.log(message);
    if (receiveTypes.includes(message.name) && message.sender === 'emwindow') {
      EventManager.dispatch(message.name, message.data);
    }
  }

  _send(type, data) {
    const message = { name: type, sender: 'game' };
    if (data != null) message.data = data;
    // console.log(message);
    window.top.postMessage(message, '*');
  }

  _onLoadProgress(data) {
    this._send(EMPostMessageTypes.PMT_LOAD_PROGRESS, Math.floor(data.progress));
  }

  _onLoadCompleted() {
    this._send(EMPostMessageTypes.PMT_LOAD_COMPLETED);
  }

  _onAudioToggle(data) {
    this._send(EMPostMessageTypes.PMT_AUDIO_TOGGLE, data.audioState);
  }

  _onRoundStart() {
    this._send(EMPostMessageTypes.PMT_ROUND_START);
  }

  _onRoundEnd() {
    this._send(EMPostMessageTypes.PMT_ROUND_END);
  }

  _onStakeUpdate(data) {
    this._send(EMPostMessageTypes.PMT_STAKE_UPDATE, data.betAmount);
  }

  _onClosed() {
    this._send(EMPostMessageTypes.PMT_CLOSED);
  }

  _onErrorMessage(data) {
    const code = EMErrorCodes[data.code]
    if (!code) return;
    this._send(EMPostMessageTypes.PMT_ERROR_MESSAGE, {
      errorCode: code,
      errorMessage: data.message
    });
  }
}
