import FlashLib from 'flashlib_onlyplay';
import animationCreator from 'Engine/animations/animationCreator';

export default class SakuraContainer extends FlashLib.MovieClip {
  constructor(data, displayData) {
    super(data, displayData);

    this.startListener = { complete: this._setIdleAnimation.bind(this) };
    this.finishListener = { complete: this._hide.bind(this) };

    this.track = null;
    this.isHiding = false;
    this.visible = false;
  }

  initAnimation() {
    this.animation = animationCreator.createAnimation('freeSpinsSakura');
    this.addChild(this.animation);
    this.animation.visible = false;
  }

  playAnimation() {
    this.animation.state.addListener(this.startListener);
    this.animation.visible = true;

    this.track = this.animation.state.setAnimation(0, 'start', false);
    this.track.reverse = false;
  }

  _setIdleAnimation() {
    this.animation.state.removeListener(this.startListener);

    this.animation.state.setAnimation(0, 'idle', true);
  }

  hide() {
    if (!this.animation || this.isHiding) return;
    this.isHiding = true;
    this.animation.state.addListener(this.finishListener);

    this.track = this.animation.state.setAnimation(0, 'start', false);
    this.track.reverse = true;
  }

  _hide() {
    this.visible = false;
    this.animation.state.removeListener(this.finishListener);

    this.animation.visible = false;
    this.isHiding = false;
    this.track = null;
    this.animation = null;
  }

  show() {
    if (this.animation) return;
    this.visible = true;
    this.initAnimation();
    this.playAnimation();
  }
}
