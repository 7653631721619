import { postMessageTypes, receiveMessageTypes } from './constants';
import EventManager from '../events/manager';
import test from './test';

export default class MessageManager {
  constructor() {
    window.addEventListener('message', this._onMessage);

    for (const type in postMessageTypes) {
      EventManager.add(postMessageTypes[type], (data) => this._send(postMessageTypes[type], data));
    }
  }

  _onMessage(event) {
    const message = event.data;
    const receiveTypes = Object.values(receiveMessageTypes);
    // console.log(message);
    if (receiveTypes.includes(message.name) && message.sender === 'provider') {
      EventManager.dispatch(message.name, message.data);
    }
  }

  _send(type, data) {
    const message = { name: type, sender: 'game' };
    const configParams = window.OPWrapperService.config.messageEvents[type];
    if (!configParams || (!Array.isArray(configParams) && configParams !== true)) return;

    // ADD PARAMS
    if (data !== null && data !== undefined && Array.isArray(configParams) && configParams.length) {
      configParams.forEach((paramName) => {
        if (data[paramName] !== null && data[paramName] !== undefined) {
          if (!message.data) message.data = {};
          message.data[paramName] = data[paramName];
        }
      });
    }

    // SEND MESSAGE
    window.top.postMessage(message, '*');
  }
}
