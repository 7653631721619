// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("/assets/images/preloader_background.webp", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "#preloader {\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") center/cover no-repeat;\n}\n\n.game_logo {\n  width: 100%;\n  margin-bottom: 0.5vw;\n}\n\n.preloaderLogo {\n  order: 1;\n  margin-bottom: 0;\n  margin-top: 2.4vw;\n}", "",{"version":3,"sources":["webpack://./src/gamePreloader/gamePreloader.css"],"names":[],"mappings":"AAAA;EACE,0EAAA;AACF;;AAEA;EACE,WAAA;EACA,oBAAA;AACF;;AAEA;EACE,QAAA;EACA,gBAAA;EACA,iBAAA;AACF","sourcesContent":["#preloader {\n  background: url(\"~/assets/images/preloader_background.webp\") center / cover no-repeat;\n}\n\n.game_logo {\n  width: 100%;\n  margin-bottom: 0.5vw;\n}\n\n.preloaderLogo {\n  order: 1;\n  margin-bottom: 0;\n  margin-top: 2.4vw;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
