import BaseState from 'Engine/base/states/BaseState';
import EntryPoint from 'Engine/EntryPoint';
import SpinTimeManager from 'Engine/base/spinTime/SpinTimeManager';

export default class MinimalRollingState extends BaseState {
  constructor(stateData) {
    super(stateData);
  }

  start() {
    super.start();
    //let delay = EntryPoint.GameSettings.quickSpin ? 500 : 2000;
    let remainingTime = SpinTimeManager.currentTime;
    let delay = EntryPoint.GameSettings.quickSpin ? 500 : 1000;
    delay -= remainingTime;
    console.log('remainingTime', remainingTime, 'delay', delay);
    this.timeout = setTimeout(() => {
      if (this.active) {
        this.complete()
      }
    }, delay);
  }

  onEnd() {
    super.onEnd();
    if (this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = null;
    }
  }

  stop() {
    super.stop();
    EntryPoint.GameSettings.quickStop = true;
  }
}
