import BaseState from "Engine/base/states/BaseState";
import EntryPoint from 'Engine/EntryPoint';

export default class WaitUserInteractionState extends BaseState {

    constructor(stateData) {
        super(stateData);
    }

    start() {
        OPWrapperService.infoPanel.setGameState(OPWrapperService.infoPanel.gameStateTypes.EGST_FREESPINS_GAME);
        EntryPoint.GameModel.isFreeSpinsInActiveState = true;
        super.start();
    }

    stop() {
        this.complete();
    }

    onEnd() {
        super.onEnd();
    }
}
