import FlashLib from 'flashlib_onlyplay';
import { cloneArray, shuffle } from 'Engine/utils/array';
import { isObject } from 'Engine/utils/object';

export default class ControllerReels extends FlashLib.MovieClip {
  constructor(data, displayItemData) {
    super(data, displayItemData);
    this._stateIndicatorReel = null;
    this._stopCount = 0;
    this._targetDistancesForStop = [];
    this._rollSpeeds = [];
    this._stopBezierSpeedPointsReal = [];
    this._bounceSpeeds = [];
    this.symbolsHeights = [];
    this.eStates = {
      ES_START: 'start',
      ES_ROLL: 'roll',
      ES_STOP: 'stop',
      ES_STOP_BOUNCE: 'stop_bounce',
      ES_WAIT: 'wait',
      ES_EXCLUDED: 'excluded',
    };
    this._state = this.eStates.ES_WAIT;


    this.onStart = () => {
    };
    this.onRoll = () => {
    };
    this.onStop = () => {
    };
    this.onStopReel = (reelId) => {
    }
    this.onHittingBar = (reelId) => {
    };
    this.onReelStopped = (reelId) => {
    };
    this.onAllReelsStopped = () => {
    };
    this.onCycleAvailableSymbol = (symbol, reelId) => {
    }
    this.onCycleDataSymbol = (symbol, reelId) => {
    }
    this.timeFunctionStart = this._timeFunctionStart;

    this._update = this._update.bind(this);
  }

  init(availableSymbols) {
    this.availableSymbols = []
    this.reels = [...this.children];
    this.reels.forEach((reel, index) => {
      reel.id = index;
      reel.distanceTraveledFromStart = 0;
      reel.distanceTraveledForStop = 0;
      reel.distanceTraveledForBounce = 0;
      reel._state = this.eStates.ES_WAIT;
      reel.symbols = [...reel.children];
      this.availableSymbols[reel.id] = shuffle(cloneArray(availableSymbols[reel.id]));
      reel.symbols.forEach(symbol => {
        symbol.changeSymbol(this.availableSymbols[reel.id][0]);
        this.availableSymbols[reel.id].unshift(this.availableSymbols[reel.id].pop());
        this.onCycleAvailableSymbol(symbol, reel.id)
      });
      this._tryHideBottomSymbol(reel);
    });
    this.calculateSymbolsHeights();


    //toDo: configurable parameters
    this.topSimbolsCount = 1;
    this.bottomSimbolsCount = 1;
    this.startSpeeds = 2.5 || [];
    this.symbolsCountForStart = 3 || [];
    this.symbolsCountForStop = 4 || [];
    this.startDelays = 0; // || [];
    this.stopDelays = 0; // || [];
    this.progressiveDelays = false;
    this.isBounce = true;
    this.hideBottomSymbols = this.hideBottomSymbols === undefined ? false : this.hideBottomSymbols;
    this.bounceRelativeHeight = 0.5;
    this.impactCoefficient = 0.3;
    this.stopBezierSpeedPoints = [1, 1, 1, 1, 0.005];
    this.excludedReels = [];
  }

  start() {
    if (this._state === this.eStates.ES_WAIT) {
      this._needStop = false;
      this._stateIndicatorReel = null;
      this._state = this.eStates.ES_START;

      this._rollSpeeds = [];
      this.reels.forEach((reel) => {
        this._rollSpeeds.push(0);
        if (this.excludedReels.includes(reel.id)) {
          reel._state = this.eStates.ES_EXCLUDED;
        } else {
          reel._state = this.eStates.ES_START;
          this._showBottomSymbol(reel);
          this._stateIndicatorReel = this._stateIndicatorReel || reel;
        }
      });

      this._startMove();
    } else {
      console.warn('ControllerReels: Calling "start" before the reels have stopped')
    }
  }

  stop(data) {
    this.data = data.map(reelData => reelData.concat());
    this._remainingSymbolsCount = [];
    this._mainTimestampStop = Date.now();
    this._needStop = true;
  }

  setAvailableSymbols(availableSymbols) {
    this.availableSymbols = availableSymbols.map((arr) => {
      return shuffle(cloneArray(arr));
    });
  }

  calculateSymbolsHeights() {
    this.symbolsHeights = this.reels.map(reel => parseFloat((reel.symbols[1].y - reel.symbols[0].y).toFixed(1)));
  }

  getConfigValue(data, reelIndex, progressive) {
    try {
      if (Array.isArray(data)) {
        if (data.length <= reelIndex) throw new Error();
        return data[reelIndex];

      } else if (isObject(data)) {
        if (!data.hasOwnProperty(reelIndex)) throw new Error();
        return data[reelIndex];

      } else return progressive ? data * reelIndex : data;
    } catch (e) {
      console.trace(`Can\'t find config data for nth(${reelIndex}) reel`);
    }
  }

  set stopBezierSpeedPoints(value) {
    this._stopBezierSpeedPoints = Array.isArray(value) ? value : [value, value, value, value, 0.005];
  }

  get stopBezierSpeedPoints() {
    return this._stopBezierSpeedPoints;
  }

  set bounceRelativeHeight(value) {
    this._bounceHeights = this.symbolsHeights.map(height => height * value);
  }

  get bounceRelativeHeight() {
    return this._bounceHeights[0] / this.symbolsHeights[0];
  }

  _startMove() {
    const now = Date.now();
    this._timestampsStart = this.reels.map((reel, index) => now + this.getConfigValue(this.startDelays, index, this.progressiveDelays));
    this._timestampsStop = [];
    this._mainTimestampStart = now
    this._prevUpdateTime = now;
    this._targetDistancesForStart = this.reels.map((reel, index) => this.symbolsHeights[index] * this.getConfigValue(this.symbolsCountForStart, index));
    this._targetDurationsForStart = this._targetDistancesForStart.map((distance, index) => distance / this.getConfigValue(this.startSpeeds, index));
    this.onStart();
    this._update();
  }

  _update() {
    if (this._state === this.eStates.ES_WAIT) return
    const now = Date.now()
    this.delta = now - this._prevUpdateTime;
    if (this.delta > 150) {
      this._timestampsStart = this._timestampsStart.map((timestamp) => timestamp + this.delta)
      this._timestampsStop = this._timestampsStop.map((timestamp) => timestamp + this.delta)
      this._mainTimestampStop += this.delta;
      this.delta = 0
    }
    this._prevUpdateTime = now;

    this.reels.forEach((reel) => {
      switch (reel._state) {
        case this.eStates.ES_START:
          this._updateStart(reel);
          break;
        case this.eStates.ES_ROLL:
          this._updateRoll(reel, now);
          break;
        case  this.eStates.ES_STOP:
          this._updateStop(reel);
          break;
        case  this.eStates.ES_STOP_BOUNCE:
          this._updateStopBounce(reel);
          break;
        case this.eStates.ES_WAIT:
        case this.eStates.ES_EXCLUDED:
          break;
      }
      this._tryCycleSymbol(reel);
    });

    if (this._stateIndicatorReel && this._stateIndicatorReel._state !== this._state) {
      switch (this.reels[0]._state) {
        case this.eStates.ES_ROLL:
          this.onRoll();
          this._state = this.eStates.ES_ROLL;
          break;
        case this.eStates.ES_STOP:
          this._state = this.eStates.ES_STOP;
          this.onStop();
          break;
      }
    }
    requestAnimationFrame(this._update);
  }

  _updateStart(reel) {
    const linerProgress = (this._prevUpdateTime - this._timestampsStart[reel.id]) / this._targetDurationsForStart[reel.id];
    let progress = this.timeFunctionStart(linerProgress);
    progress = linerProgress >= 0 ? progress : 1;
    const currentDistance = progress * this._targetDistancesForStart[reel.id];
    const differenceDistance = currentDistance - reel.distanceTraveledFromStart;
    reel.symbols.forEach(symbol => {
      symbol.y += differenceDistance
    })
    reel.distanceTraveledFromStart = currentDistance;
    const speed = differenceDistance / this.delta;
    if (speed > this._rollSpeeds[reel.id]) {
      this._rollSpeeds[reel.id] = speed
    }

    if (linerProgress >= 1) reel._state = this.eStates.ES_ROLL;
  }

  _timeFunctionStart(progress) {
    return progress ** 2;
  }

  _updateRoll(reel, nowTime) {
    const distanceDelta = this.delta * this.getConfigValue(this._rollSpeeds, reel.id);
    reel.symbols.forEach(symbol => {
      symbol.y += distanceDelta;
    })
    reel.distanceTraveledFromStart += distanceDelta;
    if (this._needStop && this._prevUpdateTime >= (this._mainTimestampStop + this.getConfigValue(this.stopDelays, reel.id, this.progressiveDelays))) {
      this._tryCycleSymbol(reel);
      reel._state = this.eStates.ES_STOP;
      this._timestampsStop[reel.id] = nowTime;
      this._targetDistancesForStop[reel.id] = this.symbolsHeights[reel.id] * this.getConfigValue(this.symbolsCountForStop, reel.id) + (this.symbolsHeights[reel.id] - reel.distanceTraveledFromStart % this.symbolsHeights[reel.id]);
      this._remainingSymbolsCount[reel.id] = Math.ceil(Math.round(this._targetDistancesForStop[reel.id]) / this.symbolsHeights[reel.id]);
      if (this._remainingSymbolsCount[reel.id] < reel.symbols.length - this.bottomSimbolsCount) {
        const diffSymbolCountToMin = ((reel.symbols.length - this.bottomSimbolsCount) - this._remainingSymbolsCount[reel.id]);
        this._remainingSymbolsCount[reel.id] += diffSymbolCountToMin;
        this._targetDistancesForStop[reel.id] += this.symbolsHeights[reel.id] * diffSymbolCountToMin;
      }
      if (this.isBounce) this._targetDistancesForStop[reel.id] += this._bounceHeights[reel.id];
      this._stopBezierSpeedPointsReal[reel.id] = this._stopBezierSpeedPoints.map(value => value * this._rollSpeeds[reel.id])
      this.onStopReel(reel.id);
    }
  }

  _updateStop(reel) {
    const progress = reel.distanceTraveledForStop / this._targetDistancesForStop[reel.id]
    this._rollSpeeds[reel.id] = this._stopBezier(progress, this._rollSpeeds[reel.id], ...this._stopBezierSpeedPointsReal[reel.id]);
    const distance = this._rollSpeeds[reel.id] * this.delta;

    if ((reel.distanceTraveledForStop + distance) >= this._targetDistancesForStop[reel.id]) {
      reel.symbols.forEach(symbol => {
        symbol.y += this._targetDistancesForStop[reel.id] - reel.distanceTraveledForStop;
      });
      reel.distanceTraveledForStop = this._targetDistancesForStop[reel.id];
      this._tryCycleSymbol(reel);
      this._tryHideBottomSymbol(reel);
      this.onHittingBar(reel.id);
      if (this.isBounce) {
        reel._state = this.eStates.ES_STOP_BOUNCE;
        this._bounceSpeeds[reel.id] = this._stopBezierSpeedPointsReal[reel.id][4] * this.impactCoefficient;
      } else {
        this._onReelStopped(reel);
      }
    } else {
      reel.symbols.forEach(symbol => {
        symbol.y += distance;
      });
      reel.distanceTraveledForStop += distance;
    }
  }

  _stopBezier(progress, startSpeed, mediumSpeed1, mediumSpeed2, mediumSpeed3, mediumSpeed4, endSpeed) {
    return (1 - progress) ** 5 * startSpeed + 5 * progress * (1 - progress) ** 4 * mediumSpeed1 + 10 * progress ** 2 * (1 - progress) ** 3 * mediumSpeed2 + 10 * progress ** 3 * (1 - progress) ** 2 * mediumSpeed3 + 5 * progress ** 4 * (1 - progress) * mediumSpeed4 + progress ** 5 * endSpeed
  }

  _updateStopBounce(reel) {
    const progress = reel.distanceTraveledForBounce / this._bounceHeights[reel.id];
    let speed = -this._bounceBezier(progress, this._bounceSpeeds[reel.id], this._bounceSpeeds[reel.id], 0.005);
    const distance = speed * this.delta;

    reel.symbols.forEach(symbol => {
      symbol.y += distance;
    })
    reel.distanceTraveledForBounce += -distance;

    if (progress >= 1) {
      reel.symbols.forEach(symbol => {
        symbol.y -= this._bounceHeights[reel.id] - reel.distanceTraveledForBounce;
      })
      this._onReelStopped(reel);
    }
  }

  _bounceBezier(progress, startSpeed, mediumSpeed, endSpeed = 0) {
    return (1 - progress) * (1 - progress) * startSpeed + 2 * (1 - progress) * progress * mediumSpeed + progress * progress * endSpeed;
  }

  _tryCycleSymbol(reel) {
    if (reel._state === this.eStates.ES_STOP_BOUNCE || reel._state === this.eStates.ES_EXCLUDED || reel._state === this.eStates.ES_WAIT) return;
    if (Math.round(reel.symbols[reel.symbols.length - 1].y) >= this.symbolsHeights[reel.id] * (reel.symbols.length)) {
      const symbol = reel.symbols.pop();
      symbol.y = reel.symbols[0].y - this.symbolsHeights[reel.id];
      reel.symbols.unshift(symbol);
      reel.setChildIndex(symbol, 0);
      if (reel._state === this.eStates.ES_STOP && this.data[reel.id].length && this._remainingSymbolsCount[reel.id] - this.topSimbolsCount <= this.data[reel.id].length) {
        reel.symbols[0].changeSymbol(this.data[reel.id].pop());
        this.onCycleDataSymbol(reel.symbols[0], reel.id)
      } else {
        reel.symbols[0].changeSymbol(this.availableSymbols[reel.id][0]);
        this.availableSymbols[reel.id].unshift(this.availableSymbols[reel.id].pop());
        this.onCycleAvailableSymbol(reel.symbols[0], reel.id)
      }
      if (reel._state === this.eStates.ES_STOP) this._remainingSymbolsCount[reel.id]--;
      this._tryCycleSymbol(reel);
    }
  }

  _tryHideBottomSymbol(reel) {
    if (this.hideBottomSymbols) reel.symbols[reel.symbols.length - 1].visible = false;
  }

  _showBottomSymbol(reel) {
    reel.symbols[reel.symbols.length - 1].visible = true;
  }

  _onReelStopped(reel) {
    reel._state = this.eStates.ES_WAIT;
    reel.distanceTraveledFromStart = 0;
    reel.distanceTraveledForStop = 0;
    reel.distanceTraveledForBounce = 0;
    this._stopCount++;
    this._rollSpeeds[reel.id] = 0;
    this.onReelStopped(reel.id);
    if (this._stopCount === this.reels.length - this.excludedReels.length) {
      this._stopCount = 0;
      this._onStop()
    }
  }

  _onStop() {
    this._state = this.eStates.ES_WAIT;
    this.onAllReelsStopped();
  }
}
