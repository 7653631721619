import BaseNotification from '../baseNotification';
import eNotificationTypes from '../eNotificationTypes';
import { OP_WRAPPER_LOCALIZATION_PREFIX } from '../../../../utils/constants';

export default class TournamentNotification extends BaseNotification {
  constructor(data) {
    super({ type: eNotificationTypes.ENT_TOURNAMENT_STATUS_CHANGED, _id: data._id });
    this._data = {
      title: '',
      userId: '',
      score: '',
      place: '',
      prize: '',
      ...data
    };
    this._lifeTime = 5000;

    this._eButtonsTypes = {
      EBT_ROOT: `container_root_${this._id}`,
    };
    this._eEventTypes = {
      ...this._eEventTypes,
      NOTIFICATION_CLICK: this.getEventName(this._eButtonsTypes.EBT_ROOT),
    };
    this._eButtonsActions = {
      [this._eButtonsTypes.EBT_ROOT]: () => {
        if (window.OPWrapperService.ControllerPlayerUi.hud.tournamentLabelEnabled)
        window.OPWrapperService.SystemUI.toggleControllerVisibility(window.OPWrapperService.SystemUI.controllerTypes.ECT_TOURNAMENTS, true)
      },
    };
    this._eElementsTypes = {
      EET_CONTAINER_ROOT: this._eButtonsTypes.EBT_ROOT,
      EET_LABEL_1: `label_1_${this._id}`,
      EET_LABEL_2: `label_2_${this._id}`,
      EET_LABEL_3: `label_3_${this._id}`,
      EET_LABEL_4: `label_4_${this._id}`,
      EET_LABEL_5: `label_5_${this._id}`,
    };

    this._localizations = {
      [this._eElementsTypes.EET_LABEL_1]: `${OP_WRAPPER_LOCALIZATION_PREFIX}.tournaments.notifications.position_change_label_1`,
      [this._eElementsTypes.EET_LABEL_2]: `${OP_WRAPPER_LOCALIZATION_PREFIX}.tournaments.notifications.position_change_label_2`,
      [this._eElementsTypes.EET_LABEL_3]: `${OP_WRAPPER_LOCALIZATION_PREFIX}.tournaments.notifications.position_change_label_3`,
      [this._eElementsTypes.EET_LABEL_4]: `${OP_WRAPPER_LOCALIZATION_PREFIX}.tournaments.notifications.position_change_label_4`,
      [this._eElementsTypes.EET_LABEL_5]: `${OP_WRAPPER_LOCALIZATION_PREFIX}.tournaments.notifications.position_change_label_5`,
    }

    this._onAnimationEnd = this._onAnimationEnd.bind(this);
  }

  init(...props) {
    super.init(...props);
    this.root = this.interactiveElements[this._eElementsTypes.EET_CONTAINER_ROOT];
    this.root.onanimationend = this._onAnimationEnd;
  }

  _onAnimationEnd() {
    if (this._complete) {
      super._onLifeTimeComplete();
    } else {
      this._trackLifeTime();
    }
  }

  _onLifeTimeComplete() {
    this.root.classList.add('notification_hide');
  }

  _getMarkup() {
    return `
          <div id=${this.interactiveElementsIds[this._eElementsTypes.EET_CONTAINER_ROOT]} class="OPWrapper_service__notifications__wrapper__container">
            <div class="OPWrapper_service__notifications__wrapper__notification system-ui-container__notifications__tournament_notification">
              <div class="system-ui-container__notifications__tournament_notification__title">
                <h1>${this._data.title}</h1>
              </div>
              <div class="system-ui-container__notifications__tournament_notification__content">
                <div>
                  <div><span id=${this.interactiveElementsIds[this._eElementsTypes.EET_LABEL_1]}>${this._getLocalization(this._localizations[this._eElementsTypes.EET_LABEL_1])}</span></div>
                  <div><span id=${this.interactiveElementsIds[this._eElementsTypes.EET_LABEL_2]}>${this._getLocalization(this._localizations[this._eElementsTypes.EET_LABEL_2])}</span></div>
                  <div><span id=${this.interactiveElementsIds[this._eElementsTypes.EET_LABEL_3]}>${this._getLocalization(this._localizations[this._eElementsTypes.EET_LABEL_3])}</span></div>
                  <div><span id=${this.interactiveElementsIds[this._eElementsTypes.EET_LABEL_4]}>${this._getLocalization(this._localizations[this._eElementsTypes.EET_LABEL_4])}</span></div>
                  <div><span id=${this.interactiveElementsIds[this._eElementsTypes.EET_LABEL_5]}>${this._getLocalization(this._localizations[this._eElementsTypes.EET_LABEL_5])}</span></div>
                </div>
                <div>
                  <div>
                    <svg width="80" height="40" viewBox="0 0 80 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M50.1171 15.95L40.0005 5.83334L29.8838 15.95" stroke="white" stroke-width="2.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M40 34.1667V6.11667" stroke="white" stroke-width="2.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                  </div>
                  <div><span>${this._data.userId}</span></div>
                  <div><span>${this._data.score}</span></div>
                  <div><span>${this._data.place}</span></div>
                  <div><span>${this._data.prize}</span></div>
                </div>
              </div>
            </div>
          </div>`
  }
}
