import isMobile from 'ismobilejs';

export default new class UserAgentDetector {
  constructor() {
    this.isMobile = isMobile(window.navigator);
    this.checkBrowser()
  }

  checkBrowser() {
    this.isOpera = /Opera Mini\/|OPM\/|OPR\//i.test(window.navigator.userAgent);
  }
}
