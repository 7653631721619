export const formatCurrency = (value, currency = '') => {
  const splitValue = value.toString().split('.');
  const mainValue = _format(splitValue[0]);
  const fraction = splitValue[1] ? `.${splitValue[1]}` : '';

  return `${mainValue}${fraction} ${currency.toUpperCase()}`;
};

export const format = (value, decimals, hideFractionIfPossible = true) => {
  const splitValue = value.toFixed(decimals).split('.');
  let fraction = '';
  if (decimals === 2) {
    if (!hideFractionIfPossible || parseFloat(splitValue[1]) !== 0) {
      fraction = '.' + splitValue[1];
    }
  } else if (decimals > 0 && parseFloat(splitValue[1]) !== 0) {
    fraction = '.' + splitValue[1].replace(/0*$/, '');
  }
  const mainValue = _format(splitValue[0]);

  return `${mainValue}${fraction}`;
};

function _format(value) {
  let result = [];
  for (let i = value.length - 1; i >= 0; i--) {
    if (i !== value.length - 1 && (value.length - 1 - i) % 3 === 0) result.unshift(' ');
    result.unshift(value[i]);
  }
  return result.join('');
}
