import BaseController from './basePlayerController';
import UserAgentDetector from '../../../utils/UserAgentDetector';
import { isIframe } from '../../../utils/isIframe';

export default class ControllerFullScreen extends BaseController {
  constructor({ container, ...props }) {
    super({ ...props });
    this._eButtonsTypes = {
      EBT_FULL_SCREEN: 'full_screen_button',
      EBT_EXIT_FULL_SCREEN: 'exit_full_screen_button',
    };
    this._eEventTypes =  {
      FULL_SCREEN_CLICK: this.getEventName(this._eButtonsTypes.EBT_FULL_SCREEN),
      EXIT_FULL_SCREEN_CLICK: this.getEventName(this._eButtonsTypes.EBT_EXIT_FULL_SCREEN),
    };
    this._eButtonsActions = {
      [this._eButtonsTypes.EBT_FULL_SCREEN]: this.openFullscreen.bind(this),
      [this._eButtonsTypes.EBT_EXIT_FULL_SCREEN]: this.exitFullscreen.bind(this),
    };
    this._eElementsTypes = {
      EET_FULL_SCREEN_BUTTON: this._eButtonsTypes.EBT_FULL_SCREEN,
      EET_EXIT_FULL_SCREEN_BUTTON: this._eButtonsTypes.EBT_EXIT_FULL_SCREEN,
    };

    this.init(container);

    this._updateButton = this._updateButton.bind(this);

    if (!UserAgentDetector.isMobile.android.device || !document.fullscreenEnabled) {
      if (UserAgentDetector.isMobile.android.device) this.checkFullscreenAllows();
      this.hide();
    } else {
      this._updateButton();
      window.addEventListener('fullscreenchange', this._updateButton);
    }
  }

  beforeRemove() {
    super.beforeRemove();
    window.removeEventListener('fullscreenchange', this._updateButton);
  }

  get isFullscreen() {
    return Boolean(document.fullscreenElement
      || document.webkitFullscreenElement
      || document.mozFullScreenElement
      || document.msFullscreenElement);
  }

  checkFullscreenAllows() {
      if (isIframe() && document.featurePolicy && !document.featurePolicy.allowsFeature("fullscreen")) {
        console.warn("Game can't use fullscreen, please add allow=\"fullscreen\" attribute to iframe element");
      }
  }

  openFullscreen() {
    let element = document.documentElement;
    const requestFullscreen = element.requestFullscreen
      || element.mozRequestFullScreen
      || element.webkitRequestFullscreen
      || element.msRequestFullscreen;

    const callFullscreen = () => {
      requestFullscreen && document.fullscreenEnabled && requestFullscreen.call(element, { navigationUI: 'hide' })
        .catch(e => console.error(e));
    }

    clearTimeout(this._fullscreenTimeoutForFirstCall);
    this._fullscreenTimeoutForFirstCall = setTimeout(callFullscreen, 100); // hack: fullscreen can be called only after user action
  }

  exitFullscreen() {
    const exitFullscreen = document.exitFullscreen
      || document.mozCancelFullScreen
      || document.webkitExitFullscreen
      || document.msExitFullscreen;

    exitFullscreen && exitFullscreen.call(document)
      .catch(e => console.error(e));
  }

  _updateButton() {
    this.isFullscreen ? this._showExitButton() : this._showOpenButton();
  }

  _showOpenButton() {
    this.interactiveElements[this._eElementsTypes.EET_FULL_SCREEN_BUTTON].classList.remove('hidden');
    this.interactiveElements[this._eElementsTypes.EET_EXIT_FULL_SCREEN_BUTTON].classList.add('hidden');
  }

  _showExitButton() {
    this.interactiveElements[this._eElementsTypes.EET_FULL_SCREEN_BUTTON].classList.add('hidden');
    this.interactiveElements[this._eElementsTypes.EET_EXIT_FULL_SCREEN_BUTTON].classList.remove('hidden');
  }

  _getMarkup() {
    return `
          <button id=${this.interactiveElementsIds[this._eElementsTypes.EET_FULL_SCREEN_BUTTON]}>
          <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M4.66797 23.2867V21.0001C4.66797 9.33342 9.33464 4.66675 21.0013 4.66675H35.0013C46.668 4.66675 51.3346 9.33342 51.3346 21.0001V35.0001C51.3346 46.6668 46.668 51.3334 35.0013 51.3334H32.668"
                stroke="white" stroke-width="3.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M30.332 25.6667L42.022 13.9534H32.6654" stroke="white" stroke-width="3.5" stroke-linecap="round"
                  stroke-linejoin="round" />
            <path d="M42.0215 13.9534V23.31" stroke="white" stroke-width="3.5" stroke-linecap="round"
                  stroke-linejoin="round" />
            <path
                d="M25.668 37.6833V43.9833C25.668 49.2333 23.568 51.3333 18.318 51.3333H12.018C6.76797 51.3333 4.66797 49.2333 4.66797 43.9833V37.6833C4.66797 32.4333 6.76797 30.3333 12.018 30.3333H18.318C23.568 30.3333 25.668 32.4333 25.668 37.6833Z"
                stroke="white" stroke-width="3.5" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
        </button>
         <button id=${this.interactiveElementsIds[this._eElementsTypes.EET_EXIT_FULL_SCREEN_BUTTON]}>
          <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.66797 23.2867V21.0001C4.66797 9.33342 9.33464 4.66675 21.0013 4.66675H35.0013C46.668 4.66675 51.3346 9.33342 51.3346 21.0001V35.0001C51.3346 46.6668 46.668 51.3334 35.0013 51.3334H32.668" stroke="white" stroke-width="3.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M42.0225 13.9534L30.3325 25.6667H39.6891" stroke="white" stroke-width="3.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M30.332 25.6667V16.31" stroke="white" stroke-width="3.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M25.668 37.6833V43.9833C25.668 49.2333 23.568 51.3333 18.318 51.3333H12.018C6.76797 51.3333 4.66797 49.2333 4.66797 43.9833V37.6833C4.66797 32.4333 6.76797 30.3333 12.018 30.3333H18.318C23.568 30.3333 25.668 32.4333 25.668 37.6833Z" stroke="white" stroke-width="3.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </button>`
  }
}
