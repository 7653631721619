export default {
  SYMBOLS: 'symbols',
  BIG_WIN: 'bigWin_spine',
  ANTISIPATOR: 'neat_win',
  PURCHASE_BUTTON: 'purchase_button',
  PURCHASE: 'purchase_animation',
  COIN: 'coins',
  FREE_SPINS_SAKURA: 'freeSpinsSakura',
  FREE_SPINS_WINDOW_SPINE: 'freeSpinsWindow_spine',
  LINES_SPINE: 'lines_spine',
  PURCHASE_ANIMATION: 'purchase_animation',
}

// TODO add EAT_
