import GlobalDispatcher from "Engine/events/GlobalDispatcher";
import EntryPoint from "Engine/EntryPoint";

export default class BaseSlotWinningsController {
    constructor(config = {nextWinTimeout: 2000, nextScatterWinTimeout: 2000}) {
        this.config = config;
        this.winningData = null;
        this.currentWinId = 0;

        this.init();
        this.addListeners();
    }

    init() {

    }

    addListeners() {
        GlobalDispatcher.add('winningsController:slowWinComplete', this.onSlowWinComplete, this);
    }

    showNextWin() {
        if (this.currentWinId === this.winningData.length) {
            GlobalDispatcher.dispatch('winningsController:allWinningsComplete');
        }
        let index = this.currentWinId % this.winningData.length;
        let currentWinData = this.winningData[index];
        if (currentWinData) {
            GlobalDispatcher.dispatch('winningsController:slowWin', currentWinData);
            this.currentWinId++;
            this.timeout = setTimeout(() => {
                this.showNextWin();
            },currentWinData.isScatter ? this.config.nextScatterWinTimeout : this.config.nextWinTimeout);
        }
    }

    onSlowWinComplete() {
        clearTimeout(this.timeout);
        this.showNextWin();
    }

    start() {
        this.currentWinId = 0;
        this.winningData = [];
        if (EntryPoint.GameModel.winnings.lines) {
            this.winningData = this.winningData.concat(EntryPoint.GameModel.winnings.lines);
        }
        if (EntryPoint.GameModel.winnings.scatters) {
            this.winningData = this.winningData.concat(EntryPoint.GameModel.winnings.scatters);
        }
        GlobalDispatcher.dispatch('winningsController:startShowWinnings', this.winningData);
        this.showNextWin();
    }

    stop() {
        clearTimeout(this.timeout);
    }

    reset() {
        this.currentWinId = 0;
        this.winningData = [];
        clearTimeout(this.timeout);
    }
}
