import LocalizationManager from '../i18n/LocalizationManager';
import RealityCheckDropdown from './components/realityCheckDropdown';
import { OP_WRAPPER_LOCALIZATION_PREFIX } from '../utils/constants';

export default class StartRealityCheckPopup {
  constructor(container, timeouts,  onActiveTimeout, onClickContinue, ) {
    this.container = container;
    this.timeouts = timeouts;
    this.onActiveTimeout = onActiveTimeout;
    this.onClickContinue = onClickContinue
    this.init()
  }

  init() {
    this.dropdown = new RealityCheckDropdown(this.timeouts, this.onActiveTimeout);
    this._createMarkup()
    this.view = this.container.querySelector('#startRealityCheckPopup');
    this.dropdown.init(this.view)
    this.title = this.view.querySelector('#realityCheckTitle');
    this.continueButton = this.view.querySelector('.realityCheck__button_continue');
    this.continueButton.addEventListener('click', this.onClickContinue);
  }

  setActiveDropdownOption(timeout) {
    this.dropdown.setActiveDropdownOption(timeout)
  }

  show() {
    this.view.style.setProperty('visibility', 'visible');
  }

  hide() {
    this.view.style.setProperty('visibility', 'hidden');
  }

  _createMarkup() {
    const markup = `
      <div id="startRealityCheckPopup">
        <div id="realityCheckContainer">
          <div id="realityCheckContent">
          <div class="realityCheck__title">${LocalizationManager.getLocalizedText(`${OP_WRAPPER_LOCALIZATION_PREFIX}.realityCheck.title`)}</div>
           <div class="realityCheck__desc">
            <span>${LocalizationManager.getLocalizedText(`${OP_WRAPPER_LOCALIZATION_PREFIX}.realityCheck.startDesc`)}</span>
          </div>
          ${this.dropdown.createMarkup()}
          <div class="realityCheckButtonsBlock">
            <button class="realityCheck__button realityCheck__button_continue">${LocalizationManager.getLocalizedText(`${OP_WRAPPER_LOCALIZATION_PREFIX}.realityCheck.ok`)}</button>
          </div>
          <div class="realityCheck__bottomDesc">${LocalizationManager.getLocalizedText(`${OP_WRAPPER_LOCALIZATION_PREFIX}.realityCheck.bottomDesc`)}</div>
          </div>
        </div>
      </div>`;
    this.container.insertAdjacentHTML('beforeend', markup );
  }
}
