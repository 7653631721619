
window.checkFPS = () => {
  window._needStopCheckFps = false;
  const container = document.createElement('div');
  container.id = 'fps_monitor'
  const fps = document.createElement('span');
  const aFps = document.createElement('span');
  const minFps = document.createElement('span');
  const maxFps = document.createElement('span');
  let minFpsAmount;
  let maxFpsAmount;

  container.append(fps, aFps, minFps, maxFps);
  [...container.children].forEach(el => {
    el.style.padding = 'max(0.8vw, 0.8vh)';
    el.style.textTransform = 'none';
  })
  container.style.color = '#ffffff'
  container.style.fontSize= 'max(1.6vw, 1.6vh)';
  container.style.position = 'fixed';
  container.style.zIndex = '1000';
  container.style.background = '#01ade0'
  fps.textContent = '0 FPS'
  aFps.textContent = '-- aFPS'
  minFps.textContent = '-- minFPS'
  maxFps.textContent = '--   maxFPS'
  document.body.append(container);
  let time = Date.now();
  let timeForAFps = time;
  let countForAFps = 0;
  const maxCount = 10;
  let count = maxCount;
  const updateFPS = () => {
    count--;
    const nowTime = Date.now();

    if (count === 0 ) {
      count = maxCount;
      const fpsAmount = Math.round(1000/ ((nowTime - time) / count));
      fps.textContent = `${fpsAmount} FPS`;

      if (fpsAmount < minFpsAmount || minFpsAmount === undefined) {
        minFpsAmount = fpsAmount;
      }

      if (fpsAmount > maxFpsAmount || maxFpsAmount === undefined) {
        maxFpsAmount = fpsAmount;
      }

      time = nowTime;
    }

    const diffForAFps = nowTime - timeForAFps
    countForAFps++
    if (diffForAFps >= 10000) {
      aFps.textContent = `${(1000/ ((nowTime - timeForAFps) / countForAFps)).toFixed(0)} aFPS`;
      minFps.textContent = `${minFpsAmount} minFPS`;
      maxFps.textContent = `${maxFpsAmount} maxFPS`;
      minFpsAmount = undefined;
      maxFpsAmount = undefined;
      countForAFps = 0;
      timeForAFps = nowTime;
    }

    if (!window._needStopCheckFps) requestAnimationFrame(updateFPS);
  }
  try {
    localStorage.setItem('checkFPSEnabled', 'true');
  } catch (e) {
  }
  requestAnimationFrame(updateFPS);
}

window.uncheckFPS = () => {
  try {
    localStorage.setItem('checkFPSEnabled', 'false');
  } catch (e) {
  }
  window._needStopCheckFps = true;
  const container = document.getElementById('fps_monitor')
  if (container) container.remove();
}

const createFPSMonitor = () => {
  if (window._FPSMonitor) return;
  const stats = window._FPSMonitor = new window.Stats();
  document.body.appendChild(stats.dom);
  window._needStopFPSMonitor = false;
  try {
    localStorage.setItem('FPSMonitorEnabled', 'true');
  } catch (e) {
  }
  requestAnimationFrame(function loop() {
    if (window._needStopFPSMonitor) return;
    stats.update();
    requestAnimationFrame(loop)
  });
}

window.FPSMonitor = () => {
  if (!window._FPSMonitorScript) {
    window._FPSMonitorScript = document.createElement('script');
    window._FPSMonitorScript.onload = createFPSMonitor;
    window._FPSMonitorScript.src = 'https://mrdoob.github.io/stats.js/build/stats.min.js';
    document.head.appendChild(window._FPSMonitorScript);
  } else {
    createFPSMonitor();
  }
}

window.removeFPSMonitor = () => {
  if (window._FPSMonitor) {
    try {
      localStorage.setItem('FPSMonitorEnabled', 'false');
    } catch (e) {
    }
    window._FPSMonitor.dom.remove();
    delete window._FPSMonitor;
    window._needStopFPSMonitor = true;
  }
}

try {
  const FPSMonitorEnabled = JSON.parse(localStorage.getItem('FPSMonitorEnabled'));
  if (FPSMonitorEnabled) window.FPSMonitor();
  const checkFPSEnabled = JSON.parse(localStorage.getItem('checkFPSEnabled'));
  if (checkFPSEnabled) window.checkFPS();
} catch (e) {
}
