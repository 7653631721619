import FlashLib from 'flashlib_onlyplay';
import GlobalDispatcher from 'Engine/events/GlobalDispatcher';
import SoundManager from 'Engine/soundManager/SoundManager';
import EntryPoint from 'Engine/EntryPoint';
import { makeHitArea } from 'Engine/utils/makeHitArea';
import { eSounds } from '../enums/eSounds';

export default class RespinButtonsContainer extends FlashLib.MovieClip {
  constructor(data, displayData) {
    super(data, displayData);

    this.buttons = [];

    this.init();
    this.addActions();
    this.addListeners();
  }

  init() {
    for (let i = 1; i <= 5; i++) {
      let button = this.getChildByName(`respinButton_${i}`);
      makeHitArea(button);
      button.enabled = false;
      button.value = 0;
      this.buttons.push(button);
    }
  }

  addActions() {
    this.buttons.forEach((button) => {
      button.actions = {
        click: {
          callback: this.onButtonClick, context: this, params: button.id
        },
        touchend: {
          callback: this.onButtonClick, context: this, params: button.id
        }
      };
    });
  }

  addListeners() {
    GlobalDispatcher.add('model:respinEnabled', this.onRespinEnabledChanged, this);
  }

  onRespinEnabledChanged(event) {
    this.setActive(event.params);
  }

  onButtonClick(data) {
    SoundManager.play(eSounds.EST_BUTTON_CLICK.name);

    if (EntryPoint.GameModel.respinCost[data - 1] > EntryPoint.GameModel.balance) {
      return GlobalDispatcher.dispatch('infoPopupManager:showError', {
        message: 'info_message1_text',
        title: 'info_title1_text',
        type: 1
      });
    }

    GlobalDispatcher.dispatch('startRespin', data - 1);
  }

  setData(data) {
    this.buttons.forEach((button, index) => {
      button.value = data[index];
    });
  }

  setActive(value) {
    this.buttons.forEach((button) => {
      button.enabled = value;
      if (!value) button.value = '';
    });
  }


}
