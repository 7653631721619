import ManagerBackgroundRequests from './managerBackgroundRequests';
import ManagerTournamentWS from './managerTournamentsWS';
import eTournamentsWS, { eNotificationTypes } from './eTournamentsWS';
import Utility from '../utility';
import { sleep } from '../utils/sleep';
import ManagerHttp from './managerHttp';

export default class ManagerServerCommunication {
  constructor(wrapperModel) {
    this._urlParams = Utility.urlParser.params;

    this._roundsHistoryApi = 'https://rounds-history.dev.ig-onlyplay.net/api/';
    // this._roundsHistoryApi = 'http://0.0.0.0:8083/api/';

    this._wrapperModel = wrapperModel;
    this._managerBackgroundRequests = new ManagerBackgroundRequests({
      api: this._urlParams.api,
      sid: this._urlParams.sid,
      gid: this._urlParams.gid
    });
    this._managerHttp = new ManagerHttp({
      api: this._urlParams.api,
      sid: this._urlParams.sid,
      gid: this._urlParams.gid
    });

    this.handleModelChange = this.handleModelChange.bind(this);
    this._wrapperModel.onChange.add(this.handleModelChange);
  }

  requestRoundsHistory(page) {
    const abortController = new AbortController();
    const signal = abortController.signal;
    const promise = this._managerHttp.postRequest(this._roundsHistoryApi.concat('rounds/get_history'), {
      sid: this._urlParams.sid,
      gid: this._urlParams.gid,
      page,
    }, { signal }).then(res => {
      if (res) this._wrapperModel.update(prev => ({
        ...prev,
        rounds_history: {
          ...prev.rounds_history,
          rounds: {
            ...prev.rounds_history.rounds,
            list: [...prev.rounds_history.rounds.list, ...res.data.list],
            hasMore: res.data.hasMore,
          }
        }
      }));
    });

    return { abortRoundsRequest: () => abortController.abort(), promise };
  }

  requestRoundResult(round) {
    const abortController = new AbortController();
    const signal = abortController.signal;
    const promise = this._managerHttp.postRequest(this._roundsHistoryApi.concat('rounds/get_results'), {
      sid: this._urlParams.sid,
      gid: this._urlParams.gid,
      roundsParams: [round],
    }, { signal }).then(res => {
      if (res && res.data.length) this._wrapperModel.update(prev => ({
        ...prev,
        rounds_history: {
          ...prev.rounds_history,
          results: {
            ...prev.results,
            [round.region]: { ...prev.rounds_history.results[round.region], [round.id]: res.data[0] }
          }
        }
      }));
    });

    return { abortResultRequest: () => abortController.abort(), promise };
  }

  async requestTournamentsInfo(...props) {
    const { data } = await this._managerTournamentWS.requestTournamentsInfo(...props);
    this._wrapperModel.update(prev => ({ ...prev, tournamentsInfo: data }));
  }

  async requestTournamentsLeaderboard(...props) {
    const { data } = await this._managerTournamentWS.requestLeaderboard(...props)

    const { type, tournamentId, ...tournamentData } = data;
    if (type === 'leaderboard') this._wrapperModel.update(prev => ({
      ...prev,
      tournamentsInfo: {
        ...prev.tournamentsInfo,
        data: {
          ...prev.tournamentsInfo.data,
          [tournamentId]: { ...prev.tournamentsInfo.data[tournamentId], ...tournamentData }
        }
      }
    }));
  }

  handleModelChange({ tournamentsInfo, partnerConfig, gameInited }) {
    if (!gameInited) return;

    if (tournamentsInfo) this._initTournamentWS(tournamentsInfo.ws.key);

    this._managerBackgroundRequests.statusCheckConfig = {
      checkStatus: partnerConfig.session.checkStatus,
      checkStatusInterval: partnerConfig.session.checkStatusInterval,
    };

    this._wrapperModel.onChange.remove(this.handleModelChange);
  }

  _initTournamentWS(authorizationToken) {
    this._managerTournamentWS = new ManagerTournamentWS({
      onMessage: this._onTournamentWSMessage.bind(this),
      onClose: this._requestTournamentsAuthorizationToken.bind(this),
      authorizationToken
    });
  }

  async _requestTournamentsAuthorizationToken() {
    await sleep(30000);

    const response = await this._managerHttp.getTournamentsAuthorizationToken();
    let key;

    if (response) {
      key = response.ws.key;
      this._managerTournamentWS.authorizationToken = key;
    }

    this._wrapperModel.update(prev => ({
      ...prev,
      tournamentsInfo: { ...prev.tournamentsInfo, ws: { ...prev.tournamentsInfo.ws, key } }
    }))
  }

  //todo: move to separate controller or tournamentWSManager
  _onTournamentWSMessage({ id, type, data }) {
    switch (id) {
      case eTournamentsWS.ETWS_NOTIFICATION: {
        this._handleTournamentNotification(type, data);
        break;
      }
    }
  }

  _handleTournamentNotification(type, data) {
    //todo: add start/end tournament handle (also sidebar init button)
    switch (type) {
      case eNotificationTypes.BONUSES: {
        const { tournamentId, ...info } = data;

        this._wrapperModel.update(prev => ({
          ...prev,
          tournamentsInfo: {
            ...prev.tournamentsInfo,
            data: {
              ...prev.tournamentsInfo.data,
              [tournamentId]: { ...prev.tournamentsInfo.data[tournamentId], bonuses: info.bonuses }
            }
          }
        }));
        break;
      }
      case eNotificationTypes.TOURNAMENT_START: {
        const { tournaments, tournamentId } = data;
        this._wrapperModel.update(prev => ({
          ...prev,
          tournamentsInfo: { ...prev.tournamentsInfo, ...tournaments },
        }));
        window.OPWrapperService.SystemUI.handleTournamentStart(tournamentId);
        break;
      }
      case eNotificationTypes.TOURNAMENT_ANNOUNCEMENT: {
        const { tournaments, tournamentId } = data;
        this._wrapperModel.update(prev => ({
          ...prev,
          tournamentsInfo: { ...prev.tournamentsInfo, ...tournaments },
        }));
        window.OPWrapperService.SystemUI.handleTournamentAnnouncement(tournamentId);
        break;
      }
      case eNotificationTypes.TOURNAMENT_END: {
        const { tournaments, tournamentId } = data;
        this._wrapperModel.update(prev => ({
          ...prev,
          tournamentsInfo: { ...prev.tournamentsInfo, ...tournaments },
        }));

        window.OPWrapperService.SystemUI.handleTournamentEnd(tournamentId);
        break;
      }
      default: {
        window.OPWrapperService.SystemUI.showNotification(type, data);
      }
    }
  }
}
