import BaseState from "Engine/base/states/BaseState";
import GlobalDispatcher from "Engine/events/GlobalDispatcher";
import EntryPoint from "Engine/EntryPoint";
import SpinTimeManager from 'Engine/base/spinTime/SpinTimeManager';

export default class InitState extends BaseState {

    constructor(stateData) {
        super(stateData);
    }

    addListeners() {
        super.addListeners();
        GlobalDispatcher.add('autoPlaySettings:activeChanged', this.onAutoPlayChanged, this);
        GlobalDispatcher.add('autoPlaySettings:pausedChanged', this.onAutoPlayChanged, this);
    }

    onAutoPlayChanged(event) {
        if (this.active && EntryPoint.AutoPlaySettings.active && !EntryPoint.AutoPlaySettings.paused) {
            this.stop();
        }
    }

    start() {
        super.start();
        window.OPWrapperService.freeBetsController.updateTotalWin();
        if (!EntryPoint.GameModel.isFreeSpinsMode) {
            window.OPWrapperService.freeBetsController.show();
            OPWrapperService.realityCheck.blockedRealityCheck = false;
        }
        if (
          window.OPWrapperService.freeBetsController.isFirstFreeBet
          || window.OPWrapperService.freeBetsController.isLastFreeBet
          || window.OPWrapperService.realityCheck.blockedGame
        ) EntryPoint.AutoPlaySettings.active = false;
        EntryPoint.GameSettings.quickStop = false;
        EntryPoint.GameModel.respinReelIndex = null;
        if (
          EntryPoint.AutoPlaySettings.active
          && EntryPoint.GameModel.bet > EntryPoint.GameModel.balance
          && EntryPoint.GameModel.isSpinWithoutBalanceCheck
        ) {
            EntryPoint.AutoPlaySettings.active = false;
            return GlobalDispatcher.dispatch('infoPopupManager:showError', {
                message: 'info_message1_text',
                title: 'info_title1_text',
                type: 1
            });
        } else if ((EntryPoint.AutoPlaySettings.active && !EntryPoint.AutoPlaySettings.paused) || EntryPoint.GameModel.isFreeSpinsMode) {
            setTimeout(()=>{
                this.stop();
            }, SpinTimeManager.timeLeft);
        }
    }

    /*stop() {
        if(GameModel.checkStartSpin()) {
            super.stop();
        }
    }*/
}
