import { OP_WRAPPER_LOCALIZATION_PREFIX } from '../../utils/constants';

export default class BaseFreeBetsItem {
  constructor(container) {
    this.defaultLocalizationPath = `${OP_WRAPPER_LOCALIZATION_PREFIX}.freeBets`;

    const template = document.createElement('div');
    template.innerHTML = this.createMarkup().trim();
    this.view = template.firstChild;
    container.append(this.view);
  }
  createMarkup() {
    return '';
  }

  show() {
    this.view.style.display = 'block';
  }

  hideElement(element = this.view) {
    const duration = 300;
    if(!element.timeStartHideElement) {
      element.timeStartHideElement = Date.now();
    }
    const now = Date.now()
    if (now - element.timeStartHideElement < duration) {
      element.style.opacity = 1 - (now - element.timeStartHideElement) / duration;
      requestAnimationFrame(() => this.hideElement(element));
    } else {
      element.style.display = 'none';
      element.style.opacity = 1;
      element.timeStartHideElement = null;
    }
  }
}
