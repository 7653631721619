import BaseFreeBetsItem from './baseFreeBetsItem';

export default class BaseFreeBetsPopup extends BaseFreeBetsItem{
  constructor(container) {
    super(container);
    this.addListeners();
    this.onClose = () => {}
  }

  show() {
    super.show();
    document.addEventListener('keydown', this.stopListenKeyDown, true)
  }

  addListeners() {
    this.view.querySelector('.freeBets__button')
      .addEventListener('click', () => this.onCloseClick());
  }
  onCloseClick() {
    window.OPWrapperService.initConfig.onButtonClick();
    this.onClose();
    this.hideElement();
    document.removeEventListener('keydown', this.stopListenKeyDown, true)
  }

  stopListenKeyDown(e) {
    e.preventDefault();
    e.stopPropagation();
  }
}
