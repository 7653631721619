import FlashLib from 'flashlib_onlyplay';
import { gsap } from 'gsap';
import animationCreator from 'Engine/animations/animationCreator';
import animationTypes from '../enums/animationTypes';

export default class StartScreenContent extends FlashLib.MovieClip {
  constructor(data, displayData) {
    super(data, displayData);

    this.init();
    this.initBookAnimation();
  }

  init() {
    this.content = this.getChildAt(1);
    this.content.alpha = 0;
    this.content.visible = false;
    this.animationContainer = this.getChildAt(0);
  }

  initBookAnimation() {
    this.animation = animationCreator.createAnimation(animationTypes.FREE_SPINS_WINDOW_SPINE);
    this.animationContainer.addChildAt(this.animation, 0);
    this.animation.visible = false;
  }

  startAnimation() {
    this.animation.visible = true;

    this.animation.state.addListener({
      complete: this.showContent.bind(this)
    });

    this.animation.state.setAnimation(0, 'book_open', false);
  }

  showContent() {
    this.content.visible = true;
    gsap.to(this.content, {alpha:1, duration: 0.3});
  }

  hide() {
    this.animation.destroy({children: true});
    this.visible = false;
  }
}
