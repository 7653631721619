import WinAmountAnimated from './winAmountAnimated';
import { eWinAmountSizes } from '../enums/eWinAmountSizes';
import EntryPoint from 'Engine/EntryPoint';

export default class SelfResizedWinAmountAnimated extends WinAmountAnimated {
  constructor(...args) {
    super(...args);
    this._addResizeListener();
  }

  _addResizeListener() {
    window.OPWrapperService.eventManager.add(
      window.OPWrapperService.eventManager.types.EET_SCALE_MANAGER_RESIZE,
      this._updateFontSize,
      this
    );
  }

  _updateFontSize(data) {
    const fontSizes = eWinAmountSizes[data.orientation];
    const decimals = EntryPoint.GameModel.currencyInfo.decimals;
    this.fontSize = decimals > 2 ? fontSizes.SMALL : fontSizes.REGULAR;
  }
}
