import * as PIXI from 'pixi.js';
import GlobalDispatcher from 'Engine/events/GlobalDispatcher';
import animationCreator from 'Engine/animations/animationCreator';
import animationTypes from '../enums/animationTypes';

export default class SymbolAnimation extends PIXI.Container {
  constructor(id, position, type = '') {
    super();

    this.id = id;
    this.symbolPosition = position;

    this.animation = null;
    this.init();
  }

  init() {
    this.animation = animationCreator.createAnimation(animationTypes.SYMBOLS);
    this.animation.state.addListener({
      complete: () => {
        setTimeout(() => {
          this._complete();
        }, 0);
      }
    });
    this.animation.visible = false;
    this.addChild(this.animation);
  }

  start(animation, loop) {
    this.animation.state.setAnimation(0, animation, loop);
    this.animation.visible = true;

    GlobalDispatcher.dispatch('symbolAnimation:start', { name: animation, position: this.symbolPosition });
  }

  _complete() {
    this.emit('animationComplete', this);
  }

  stop() {
    GlobalDispatcher.dispatch('symbolAnimation:end', this.symbolPosition);
    this.animation.visible = false;
    this.animation.state.clearTracks();
    this.animation.skeleton.setToSetupPose();
  }
}
