import FlashLib from 'flashlib_onlyplay';

export default class BaseSlotLine extends FlashLib.MovieClip {
    constructor(data, displayItemData) {
        super(data, displayItemData);

        this._id = null;
        this.init();
    }

    init() {

    }

    hide() {
        this.visible = false;
    }

    show(winning = false) {
        this.visible = true;
        this.alpha = winning ? 0.5 : 1;
    }

    get id() {
        return this._id;
    }

    set id(value) {
        this._id = value;
    }
}
//FlashLib.registerClass('BaseSlotLine', BaseSlotLine);
