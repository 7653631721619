const orientationsAdapter = {
  H: 'landscape',
  V: 'portrait',
};

const formatOrientation = (orientation) => {
  return orientationsAdapter[orientation];
}

export {
  formatOrientation,
}
