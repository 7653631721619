import BaseController from './basePlayerController';
import { OP_WRAPPER_LOCALIZATION_PREFIX } from '../../../utils/constants';

export default class ControllerMaxBet extends BaseController {
  constructor({ container, ...props }) {
    super({ ...props });
    this._eButtonsTypes = {
      EBT_MAX_BET: 'max_bet_button',
    };
    this._eEventTypes = {
      MAX_BET_CLICK: this.getEventName(this._eButtonsTypes.EBT_MAX_BET),
    };
    this._eElementsTypes = {
      EET_MAX_BET_BUTTON: this._eButtonsTypes.EBT_MAX_BET,
      EET_LABEL_1: 'label_1',
      EET_LABEL_2: 'label_2',
    };
    this._localizations = {
      [this._eElementsTypes.EET_LABEL_1]: `${OP_WRAPPER_LOCALIZATION_PREFIX}.userInterface.max_bet_label_1`,
      [this._eElementsTypes.EET_LABEL_2]: `${OP_WRAPPER_LOCALIZATION_PREFIX}.userInterface.max_bet_label_2`,
    };
    this._adaptiveElements = [this._eElementsTypes.EET_LABEL_1, this._eElementsTypes.EET_LABEL_2];

    this.init(container);
  }

  _getMarkup() {
    return `<button id=${this.interactiveElementsIds[this._eElementsTypes.EET_MAX_BET_BUTTON]} data-role="max-bet">
          <div>
            <span id=${this.interactiveElementsIds[this._eElementsTypes.EET_LABEL_1]}>${this._getLocalization(this._localizations[this._eElementsTypes.EET_LABEL_1])}</span>
            <span id=${this.interactiveElementsIds[this._eElementsTypes.EET_LABEL_2]}>${this._getLocalization(this._localizations[this._eElementsTypes.EET_LABEL_2])}</span>
          </div>
        </button>`
  }
}
