import UserAgentDetector from './UserAgentDetector';

const disableDoubleTapToZoom = (element) => {
  if (!UserAgentDetector.isMobile.any) return;

  const doubleTapDelay = 500;
  let firstPointerTapTimestamp = null;

  const handler = (e) => {
    const now = Date.now();

    if (!firstPointerTapTimestamp || now - firstPointerTapTimestamp > doubleTapDelay) {
      firstPointerTapTimestamp = now;
    } else {
      firstPointerTapTimestamp = null;
      e.preventDefault();
    }
  };

  element.addEventListener('touchstart', handler);
}

export {
  disableDoubleTapToZoom,
}
