import BaseInfoItem from './BaseInfoItem';
import LocalizationManager from '../../i18n/LocalizationManager';
import { format } from '../../utils/formatCurrency';

export default class NetPosition extends BaseInfoItem {
  constructor(container) {
    super(container);
    this._labelLocalisationKey = '.netPosition';

    this._netPosition = 0;
    this._update();
  }

  onStatisticUpdate({ totalWinAmount, totalBetAmount }) {
    this._netPosition = totalWinAmount - totalBetAmount;
    this._update();
  }

  _update() {
    const decimals = window.OPWrapperService.currencyInfo.decimals;
    const currency = window.OPWrapperService.currencyInfo.currency;

    const formattedNetPosition = format(this._netPosition, decimals, false);
    this.view.innerText = `${LocalizationManager.getLocalizedText(this.baseLocalizePath + this._labelLocalisationKey)}: ${formattedNetPosition} ${currency}`
  }
}
