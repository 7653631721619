import LocalizationManager from '../../i18n/LocalizationManager';
import { OP_WRAPPER_LOCALIZATION_PREFIX } from '../../utils/constants';

export default class RealityCheckDropdown {
  constructor(timeouts, onActiveTimeout) {
    this.timeouts = timeouts;
    this.onActiveTimeout = onActiveTimeout;
    this._dropdownClick = this._dropdownClick.bind(this);
    this._dropdownBlur = this._dropdownBlur.bind(this);
  }
  createMarkup() {
    return `<div class="realityCheck__dropdownTitle">${LocalizationManager.getLocalizedText(`${OP_WRAPPER_LOCALIZATION_PREFIX}.realityCheck.next_notification`)}</div>
      <div class="realityCheck__dropdownWrapper">
        <div tabindex="0" id="realityCheck__dropdown">
           ${this.timeouts.reduce((prevValue, timeout) => prevValue + `<div class="realityCheck__dropdownOption" data-value="${timeout}">${timeout} ${LocalizationManager.getLocalizedText(`${OP_WRAPPER_LOCALIZATION_PREFIX}.realityCheck.minutes`)}</div>\n`, '\n')}
        </div>
      </div>`;
  }

  init(container) {
    this._dropdown = container.querySelector('#realityCheck__dropdown');
    this._initDropdown()
  }

  setActiveDropdownOption(timeout) {
    const newActiveOption = this._options.find(option => option.dataset.value === `${timeout}`);
    this._dropdown.innerHTML = '';
    this._dropdown.prepend(...this._options);
    this._dropdown.prepend(newActiveOption);
  }

  _initDropdown() {
    const options = this._dropdown.querySelectorAll('.realityCheck__dropdownOption')
    this._options = [ ...options ]
    this._dropdown.addEventListener('click', this._dropdownClick)
    this._dropdown.addEventListener('blur', this._dropdownBlur)
  }

  _dropdownClick(e) {
    window.OPWrapperService.initConfig.onButtonClick();
    if (!this._dropdown.classList.contains('active')) {
      this._dropdown.classList.add('active')
    } else {
      this._dropdown.classList.remove('active')
      this.onActiveTimeout(e.target.dataset.value);
    }
  }

  _dropdownBlur(e) {
    this._dropdown.classList.remove('active')
  }
}
