import GlobalDispatcher from "../../events/GlobalDispatcher";
import Signal from "../../events/Signal.js";

export default new class StatesManager {
    constructor() {
        this._statesConfig = null;
        this._states = {};
        this._currentState = null;

        this._stateWillStartSignal = new Signal();
    }

    /**
     *
     * @param statesConfig
     */
    setStatesConfig(statesConfig) {
        this._statesConfig = statesConfig;
        this.init();
    }

    /**
     *
     */
    init() {
        let entry = null;
        this._statesConfig.forEach((stateData) => {
            if (!this._states[stateData.name]) {
                let stateInstance = new stateData.class(stateData);
                stateInstance.add(this.onStateCallback, this);
                this._states[stateData.name] = stateInstance;

                if (stateData.entry) {
                    entry = stateInstance;
                }
            }
        });
        entry.start();
    }

    /**
     *
     * @param stateName
     * @returns {*}
     */
    getStateDataByName(stateName) {
        return this._statesConfig.find((data) => {
            return data.name === stateName;
        });
    }

    /**
     *
     * @param data
     */
    onStateCallback(data) {
        switch (data.event) {
            case 'start':
                console.log('state start', data.name);
                this._currentState = this._states[data.name];
                GlobalDispatcher.dispatch('stateManager.stateChanged', data.name);
                GlobalDispatcher.dispatch('stateStart:' + data.name);
                break;
            case 'stop':
            case 'complete':
                //console.log('state complete', data.name);
                GlobalDispatcher.dispatch('stateManager.stateComplete', data.name);
                GlobalDispatcher.dispatch('stateEnd:' + data.name);
                this.onStateFinish(data.name);
                break;
        }
    }

    /**
     *
     * @param stateName
     */
    onStateFinish(stateName) {
        let stateData = this.getStateDataByName(stateName);
        if (stateData) {
            if (this.nextStateName) {
                this.stateWillStartSignal.dispatch(this.nextStateName);
                this._states[this.nextStateName].start();
            } else {
                let transitions = this.getTransition(stateData);
                if (transitions) {
                    this.stateWillStartSignal.dispatch(transitions.to);
                    this._states[transitions.to].start();
                }
            }
        }
    }

    /**
     *
     * @param stateData
     * @returns {*}
     */
    getTransition(stateData) {
        for (let i = 0; i < stateData.transitions.length; i++) {
            let transition = stateData.transitions[i];
            if (transition.condition()) {
                return transition;
            }
        }
    }

    /**
     *
     */
    goToNextState() {
        this.currentState.stop();
    }

    /**
     *
     * @param stateName
     */
    goToState(stateName) {
        this.nextStateName = stateName;
        this.currentState.stop();
        this.nextStateName = null;
    }

    /**
     *
     * @returns {null}
     */
    get currentState() {
        return this._currentState;
    }

    get stateWillStartSignal() {
        return this._stateWillStartSignal;
    }
}
