import Event from '../events/eventDataChange';

export default class WrapperModel {
  constructor({ initialData }) {
    this._data = {
      partnerConfig: null,
      initConfig: null,
      tournamentsInfo: null,
      currencyInfo: null,
      isSpinBlocked: false,
      gameInited: false,
      preloadingComplete: false,
      rounds_history: {
        rounds: {
          list: [],
          hasMore: false,
        },
        results: {},
      },
      ...initialData,
    };

    this._spinBlockers = new Set();
    this.onChange = new Event(this._data);
  }

  update(next) {
    this._data = typeof next === 'function' ? next(this._data) : next;
    this.onChange.call(this._data);
  }

  blockSpin(controllerType) {
    this._spinBlockers.add(controllerType);
    this.update(prev => ({ ...prev, isSpinBlocked: true }));
  }

  unblockSpin(controllerType) {
    this._spinBlockers.delete(controllerType);
    if (!this._spinBlockers.size) this.update(prev => ({ ...prev, isSpinBlocked: false }));
  }

  get data() {
    return this._data;
  }
}
