import { errorTypes } from '../../utils/errorTypes';

export default  {
  [errorTypes.NO_CONNECTION.CODE]: '8501',
  [errorTypes.ASSETS_ERROR.CODE]: '8502',
  [errorTypes.SOCKET_DISCONNECTED.CODE]: '8503',
  [errorTypes.SESSION_EXPIRED.CODE]: '8504',
  [errorTypes.SESSION_BUSY.CODE]: '8505',
  [errorTypes.INSUFFICIENT_BALANCE.CODE]: '8506',
}
