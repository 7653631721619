import FlashLib from 'flashlib_onlyplay';
import GlobalDispatcher from 'Engine/events/GlobalDispatcher';
import SoundManager from 'Engine/soundManager/SoundManager';
import EntryPoint from 'Engine/EntryPoint';
import MoneyFormat from 'Engine/base/moneyFormat/MoneyFormat';
import { makeHitArea } from 'Engine/utils/makeHitArea';
import { eSounds } from '../enums/eSounds';

export default class PurchaseConfirm extends FlashLib.MovieClip {
  constructor(data, displayData) {
    super(data, displayData);

    this.init();
    this.addListeners();
  }

  init() {
    this.title = this.getChildByName('purchase_confirm_title');
    this.priceLabel = this.getChildByName('purchase_confirm_price_label');
    this.priceAmount = this.getChildByName('purchase_confirm_price_amount');
    this.btnCancel = this.getChildByName('btnCancel');
    this.btnContinue = this.getChildByName('btnContinue');
    this.btnCancel.enabled = true;
    this.btnContinue.enabled = true;
    makeHitArea(this.btnCancel);
    makeHitArea(this.btnContinue);
  }

  addListeners() {
    GlobalDispatcher.add('purchaseModal:buy', this.onDataReceived.bind(this));
    this.btnCancel.addListener('pointertap', this.hide.bind(this));
    this.btnContinue.addListener('pointertap', this.continuePurchase.bind(this));
  }

  onDataReceived(data) {
    this.price = data.params.price;
    this.priceAmount.text = MoneyFormat.format(this.price, true);
    this.show();
  }

  show() {
    this.visible = true;
  }

  hide() {
    this.visible = false;
  }

  continuePurchase() {
    SoundManager.play(eSounds.EST_BUTTON_CLICK.name);

    if (this.price > EntryPoint.GameModel.balance) {
      GlobalDispatcher.dispatch('errorManager:showError', {
        message: "info_message3_text",
        title: "info_title1_text",
        type: 1
      });
      return this.hide();
    }

    OPWrapperService.ControllerStatistic.bet = this.price;
    OPWrapperService.realityCheck.blockedRealityCheck = true;
    GlobalDispatcher.dispatch('bonusGame:buy');
  }
}
