export const eSounds = {
  BACKGROUND: {
    name: 'Background1',
    volume: 0.5,
    group: 'music'
  },
  FREE_SPINS_BACK: {
    name: 'BackgroundFreeSpins',
    volume: 0.6,
    group: 'music'
  },
  SCATTER_STOP: {
    name: 'ScatterStop',
    volume: 0.5,
    group: 'default'
  },
  SCATTER_WIN: {
    name: 'ScatterWin',
    volume: 1,
    group: 'default'
  },
  REEL_MOVE: {
    name: 'ReelsMove',
    volume: 0.7,
    group: 'default'
  },
  REEL_STOP: {
    name: 'SymbolsStop',
    volume: 0.5,
    group: 'default'
  },
  ANTISIPATOR: {
    name: 'Anticipation',
    volume: 0.7,
    group: 'default'
  },
  BIG_WIN_BACK: {
    name: 'BigWinBackground',
    volume: 0.7,
    group: 'winning'
  },
  SMALL_WIN: {
    name: 'SmallWin',
    volume: 0.8,
    group: 'winning'
  },
  BIG_WIN: {
    name: 'BigWin',
    volume: 1,
    group: 'winning'
  },
  MEGA_WIN: {
    name: 'MegaWin',
    volume: 1.2,
    group: 'winning'
  },
  SUPER_BIG_WIN: {
    name: 'SuperBigWin',
    volume: 1.2,
    group: 'winning'
  },
  SUPER_MEGA_WIN: {
    name: 'SuperMegaWin',
    volume: 1.2,
    group: 'winning'
  },
  SUPER_MEGA_BIG_WIN: {
    name: 'SuperMegaBigWin',
    volume: 1.2,
    group: 'winning'
  },
  SUPER_WIN: {
    name: 'SuperWin',
    volume: 1.2,
    group: 'winning'
  },
  EST_BUTTON_CLICK: {
    name: 'ButtonClick',
    volume: 1,
    group: 'default'
  },
  EST_WIN_END: {
    name: 'Win',
    volume: 1,
    group: 'default'
  }
};

export const soundsPreloaderConfig = Object.fromEntries(Object.keys(eSounds).map(key => [key, eSounds[key].name]));
