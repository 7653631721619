import FlashLib from "flashlib_onlyplay";
import GlobalDispatcher from "Engine/events/GlobalDispatcher";
import MoneyFormat from 'Engine/base/moneyFormat/MoneyFormat';

export default class RespinInReelInfo extends FlashLib.MovieClip {
    constructor(data, displayData) {
        super(data, displayData);

        this.interactive = true;
        this.cursor = 'pointer';

        this._enabled = false;
        this._value = 0;

        this.init();
        this.addListeners();
    }

    init() {
        this.id = +this.name.replace('inReelInfo_', '');
        this.getChildAt(0).alpha = 0.9;

        this.titleText = this.getChildByName('respin_startOnReel_label');
        this.valueText = this.getChildByName('valueText');
    }

    addListeners() {

    }

    show() {
        this.alpha = 1;
    }

    hide() {
        this.alpha = 0;
    }

    get value() {
        return this._value;
    }

    set value(value) {
        this._value = value;
        this.valueText.text = MoneyFormat.format(this.value, false, false);
    }

    get enabled() {
        return this._enabled;
    }

    set enabled(value) {
        this._enabled = value;
        this.interactive = this.enabled;
    }
}
