import SoundManager from 'Engine/soundManager/SoundManager';
// import EventEmitter from '../../../eventEmitter';
import EntryPoint from 'Engine/EntryPoint';
import EventEmitter from 'WrapperService/ui/eventEmitter';
import { eSounds } from '../../../enums/eSounds';

export default class winController extends EventEmitter {
  constructor({ config, controllerPopup, animation, counter, smallWinCounter, coefCounter, totalWinCounter, winAmountContainer, coinParticles, coinParticles2, coinParticles3 }) {
    super();

    this.ControllerPopup = controllerPopup;
    this.animation = animation;
    this.counter = counter;
    this.smallWinCounter = smallWinCounter;
    this.coefCounter = coefCounter;
    this.totalWinCounter = totalWinCounter;
    this.winAmountContainer = winAmountContainer;
    this.coinParticles = coinParticles;
    this.coinParticles2 = coinParticles2;
    this.coinParticles3 = coinParticles3;
    this._config = config;

    this._startTimeStamp = null;
    this._durationOnNextCall = null;
    this._counterOffsetOnNextCall = null;

    this._active = false;
    this._dirty = false;

    this._startValue = 0;
    this._endValue = 0;
  }

  start() {
    this.onStart();
    this._active = true;
    if (this._config.animationName) {
      this.animation.visible = true;
      if (this._config.animationName.START && this._config.animationName.IDLE) {
        this.animation.state.setAnimation(0, this._config.animationName.START, false);
        this.animation.state.addAnimation(0, this._config.animationName.IDLE, true, 0);
      } else if (this._config.animationName.IDLE) {
        this.animation.state.setAnimation(0, this._config.animationName.IDLE, true);
      }
    } else {
      if (this.animation) this.animation.visible = false;
    }

    if (this._config.sound) {
      this._playSound();
    }
    SoundManager.play(eSounds.BIG_WIN_BACK.name, eSounds.BIG_WIN_BACK.volume, eSounds.BIG_WIN_BACK.group);

    this.counter.animate(this._startValue, this._endValue, (this._durationOnNextCall ?? this._config.duration) - (this._counterOffsetOnNextCall ?? this._config.counterOffset));
    // this.totalWinCounter.animate(this._startValue, this._endValue, (this._durationOnNextCall ?? this._config.duration) - (this._counterOffsetOnNextCall ?? this._config.counterOffset));
    // this.smallWinCounter.animate(this._startValue / EntryPoint.GameModel.extraCoef, this._endValue / EntryPoint.GameModel.extraCoef, (this._durationOnNextCall ?? this._config.duration) - (this._counterOffsetOnNextCall ?? this._config.counterOffset));

    requestAnimationFrame(this._trackDuration);
  }

  setIdle() {
    this.animation.visible = true;
    this.animation.state.setAnimation(0, this._config.animationName.IDLE, true);
  }

  setDurationOnNextCall(value) {
    this._durationOnNextCall = value;
  }

  setCounterOffsetOnNextCall(value) {
    this._counterOffsetOnNextCall = value
  }

  complete() {
    this.stop();
    this.emit('complete');
  }

  stop() {
    this._active = false;
    this._startTimeStamp = null;
    this._durationOnNextCall = null;
    this._counterOffsetOnNextCall = null;
    SoundManager.stop(eSounds.BIG_WIN_BACK.name);
    this.onEnd();
  }

  onStart() {}

  onEnd() {}

  onSkip() {}

  cleanup() {}

  _trackDuration = (timestamp) => {
    if (!this._active) return;

    if (!this._startTimeStamp) this._startTimeStamp = timestamp;
    if (timestamp - this._startTimeStamp >= (this._durationOnNextCall ?? this._config.duration)) {
      this.complete();
    }

    requestAnimationFrame(this._trackDuration);
  };

  _playSound() {
    return SoundManager.play(this._config.sound.name, this._config.sound.volume, this._config.sound.loop, this._config.sound.group);
  }

  get counterOffset() {
    return this._counterOffsetOnNextCall ?? this._config.counterOffset;
  }

  get duration() {
    return this._durationOnNextCall ?? this._config.duration;
  }

  get startValue() {
    return this._startValue;
  }

  set startValue(value) {
    this._startValue = value;
  }

  set endValue(value) {
    this._endValue = value;
  }

  get completeDelay() {
    return this._config.completeDelay;
  }
}
