import CommonUiController from './base';

export default class ControllerSpinner extends CommonUiController {
  constructor({ container, ...props }) {
    super({ ...props });
    this._eElementsTypes = {
      EET_ROOT: 'root',
    };

    this._currentAppliedClassNames = [];
    this.init(container);
    this.hide();
  }

  _afterInit() {
    super._afterInit();
    this.root = this.interactiveElements[this._eElementsTypes.EET_ROOT];
  }

  set className(value) {
    const classNames = value.split(' ');
    this._currentAppliedClassNames.forEach(className => this.root.classList.remove(className));

    classNames.forEach(className => this.root.classList.add(className))
    this._currentAppliedClassNames = classNames;
  }

  _getMarkup() {
    return `
          <div id=${this.interactiveElementsIds[this._eElementsTypes.EET_ROOT]} class="system-ui-container__spinner">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>`
  }
}
