import GameModel from '../model/GameModel';
import InitState from './InitState';
import IdleState from './IdleState';
import RequestRollingState from './RequestRollingState';
import StopRollingState from './StopRollingState';
import ShowWinningsState from './ShowWinningsState';
import ShowBigWinState from './ShowBigWinState';
import FreeSpinsAddState from './FreeSpinsAddState';
import FreeSpinsOutState from './FreeSpinsOutState';
import ShowLinesWinState from './ShowLinesWinState';
import MinimalRollingState from './MinimalRollingState';
import ExpandSymbolsState from './ExpandSymbolsState';
import ShowExpandedWinState from './ShowExpandedWinState';
import RequestRespinRollingState from './RequestRespinRollingState';
import MinimalRespinRollingState from './MinimalRespinRollingState';
import StopRespinRollingState from './StopRespinRollingState';
import RequestBonusState from './RequestBonusState';
import WaitUserInteractionState from './WaitUserInteractionState';
import EntryPoint from '../libs/game-engine/src/EntryPoint';
import ShowStartScreenState from './showStartScreenState';
import StopWinState from './StopWinState';

export const StatesConfig = [
  {
    name: 'initState', class: InitState, transitions: [
      {
        to: 'showStartScreenState', condition: function () {
          return EntryPoint.GameSettings.introScreen;
        }
      },
      {
        to: 'showLinesWinState', condition: function () {
          return GameModel.winnings && ((GameModel.winnings.lines && GameModel.winnings.lines.length > 0) || (GameModel.winnings.scatters && GameModel.winnings.scatters.length > 0));
        }
      },
      { to: 'freeSpinsAddState', condition: function () {
          return GameModel.freeSpins && GameModel.freeSpins.added > 0;
        }
      },
      {
        to: 'waitUserInteractionState', condition: function () {
          return GameModel.isFreeSpins;
        }
      },
      {
        to: 'freeSpinsOutState', condition: function () {
          return Object.keys(GameModel.freeSpins).length && GameModel.freeSpins.total > 0 && GameModel.freeSpins.left === 0;
        }
      },
      {
        to: 'idleState', condition: function () {
          return true;
        }
      }
    ], entry: true
  },
  {
    name: 'idleState', class: IdleState, transitions: [
      {
        to: 'requestRespinRollingState', condition: function () {
          return GameModel.checkStartRespin();
        }
      },
      {
        to: 'requestRollingState', condition: function () {
          return GameModel.checkStartSpin();
        }
      },
    ]
  },
  {
    name: 'requestRespinRollingState', class: RequestRespinRollingState, transitions: [
      {
        to: 'minimalRespinRollingState', condition: function () {
          return true;
        }
      }
    ]
  },
  {
    name: 'minimalRespinRollingState', class: MinimalRespinRollingState, transitions: [
      {
        to: 'stopRespinRollingState', condition: function () {
          return true;
        }
      }
    ]
  },
  {
    name: 'stopRespinRollingState', class: StopRespinRollingState, transitions: [
      {
        to: 'showLinesWinState', condition: function () {
          return GameModel.winnings && ((GameModel.winnings.lines && GameModel.winnings.lines.length > 0) || (GameModel.winnings.scatters && GameModel.winnings.scatters.length > 0));
        }
      },
      {
        to: 'freeSpinsAddState', condition: function () {
          return Object.keys(GameModel.freeSpins).length && GameModel.freeSpins.added > 0;
        }
      },
      {
        to: 'freeSpinsOutState', condition: function () {
          return Object.keys(GameModel.freeSpins).length && GameModel.freeSpins.total > 0 && GameModel.freeSpins.left === 0;
        }
      },
      {
        to: 'idleState', condition: function () {
          return true;
        }
      }
    ]
  },
  {
    name: 'requestRollingState', class: RequestRollingState, transitions: [
      {
        to: 'minimalRollingState', condition: function () {
          return true;
        }
      }
    ]
  },
  {
    name: 'minimalRollingState', class: MinimalRollingState, transitions: [
      {
        to: 'stopRollingState', condition: function () {
          return true;
        }
      }
    ]
  },
  {
    name: 'stopRollingState', class: StopRollingState, transitions: [
      {
        to: 'showLinesWinState', condition: function () {
          return GameModel.winnings && ((GameModel.winnings.lines && GameModel.winnings.lines.length > 0) || (GameModel.winnings.scatters && GameModel.winnings.scatters.length > 0));
        }
      },
      {
        to: 'expandSymbolsState', condition: function () {
          return GameModel.specialData !== null;
        }
      },
      {
        to: 'freeSpinsAddState', condition: function () {
          return Object.keys(GameModel.freeSpins).length && GameModel.freeSpins.added > 0;
        }
      },
      {
        to: 'freeSpinsOutState', condition: function () {
          return Object.keys(GameModel.freeSpins).length && GameModel.freeSpins.total > 0 && GameModel.freeSpins.left === 0;
        }
      },
      {
        to: 'idleState', condition: function () {
          return true;
        }
      }
    ]
  },
  {
    name: 'showLinesWinState', class: ShowLinesWinState, transitions: [
      {
        to: 'showWinningsState', condition: function () {
          return true;
        }
      }
    ]
  },
  {
    name: 'showBigWinState', class: ShowBigWinState, transitions: [
      {
        to: 'stopWinState', condition: function () {
          return true;
        }
      }
    ]
  },
  {
    name: 'stopWinState', class: StopWinState, transitions: [
      {
        to: 'freeSpinsAddState', condition: function () {
          return Object.keys(GameModel.freeSpins).length && GameModel.freeSpins.added > 0;
        }
      },
      {
        to: 'freeSpinsOutState', condition: function () {
          return Object.keys(GameModel.freeSpins).length && GameModel.freeSpins.total > 0 && GameModel.freeSpins.left === 0;
        }
      },
      {
        to: 'idleState', condition: function () {
          return true;
        }
      }
    ]
  },
  {
    name: 'showWinningsState', class: ShowWinningsState, transitions: [
      {
        to: 'expandSymbolsState', condition: function () {
          return GameModel.specialData !== null;
        }
      },
      {
        to: 'showBigWinState', condition: function () {
          return GameModel.isBigWin;
        }
      },
      {
        to: 'freeSpinsAddState', condition: function () {
          return Object.keys(GameModel.freeSpins).length && GameModel.freeSpins.added > 0;
        }
      },
      {
        to: 'freeSpinsOutState', condition: function () {
          return Object.keys(GameModel.freeSpins).length && GameModel.freeSpins.total > 0 && GameModel.freeSpins.left === 0;
        }
      },
      {
        to: 'idleState', condition: function () {
          return true;
        }
      }
    ]
  },
  {
    name: 'expandSymbolsState', class: ExpandSymbolsState, transitions: [
      {
        to: 'showExpandedWinState', condition: function () {
          return true;
        }
      }
    ]
  },
  {
    name: 'showExpandedWinState', class: ShowExpandedWinState, transitions: [
      {
        to: 'showBigWinState', condition: function () {
          return GameModel.isBigWin;
        }
      },
      {
        to: 'freeSpinsAddState', condition: function () {
          return Object.keys(GameModel.freeSpins).length && GameModel.freeSpins.added > 0;
        }
      },
      {
        to: 'freeSpinsOutState', condition: function () {
          return Object.keys(GameModel.freeSpins).length && GameModel.freeSpins.total > 0 && GameModel.freeSpins.left === 0;
        }
      },
      {
        to: 'idleState', condition: function () {
          return true;
        }
      }
    ]
  },
  {
    name: 'freeSpinsAddState', class: FreeSpinsAddState, transitions: [
      {
        to: 'idleState', condition: function () {
          return true;
        }
      }
    ]
  },
  {
    name: 'freeSpinsOutState', class: FreeSpinsOutState, transitions: [
      {
        to: 'idleState', condition: function () {
          return true;
        }
      }
    ]
  },
  {
    name: 'requestBonusState', class: RequestBonusState, transitions: [
      {
        to: 'freeSpinsAddState', condition: function () {
          return Object.keys(GameModel.freeSpins).length && GameModel.freeSpins.added > 0;
        }
      },
      {
        to: 'idleState', condition: function () {
          return true;
        }
      }
    ]
  },
  {
    name: 'waitUserInteractionState', class: WaitUserInteractionState, transitions: [
      {
        to: 'idleState', condition: function () {
          return true;
        }
      }
    ]
  },
  {
    name: 'showStartScreenState', class: ShowStartScreenState, transitions: [
      {
        to: 'freeSpinsAddState', condition: function () {
          return Object.keys(GameModel.freeSpins).length && GameModel.freeSpins.added > 0;
        }
      },
      {
        to: 'waitUserInteractionState', condition: function () {
          return GameModel.isFreeSpins;
        }
      },
      {
        to: 'idleState', condition: function () {
          return true;
        }
      }
    ]
  }
];
