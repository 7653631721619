import Signal from "../../events/Signal";

export default class BaseState extends Signal {
    constructor(stateData) {
        super();

        this.stateData = stateData;
        this.active = false;

        this.init();
        this.addListeners();
    }

    init() {

    }

    /**
     * Добавление слушателей
     */
    addListeners() {

    }

    /**
     * Получение перехода
     * @returns {*}
     */
    /*getTransition() {
        for(let i = 0; i < this.stateData.transitions.length; i++) {
            let transition = this.stateData.transitions[i];
            if(transition.condition()) {
                return transition;
            }
        }
    }*/

    /**
     * Начало работы состояния
     */
    start() {
        if(this.active) {
            console.log("Can't start already started state!", this.stateData.name);
        }

        this.active = true;
        this.dispatch({event: 'start', name: this.stateData.name});
    }

    /**
     * Принудительная остановка работы состояния
     */
    stop() {
        if(!this.active) {
            console.log("Can't stop inactive state!", this.stateData.name);
        }

        /*let transition = this.getTransition();
        if(!transition) {
            return;
        }*/
        this.active = false;
        this.dispatch({event: 'stop', name: this.stateData.name/*, to:transition.to*/});
        this.onEnd();
    }

    /**
     * Завершение работы состояния
     */
    complete() {
        if(!this.active) {
            console.log("Can't complete inactive state!", this.stateData.name);
        }

        /*let transition = this.getTransition();
        if(!transition) {
            return;
        }*/
        this.active = false;
        this.dispatch({event: 'complete', name: this.stateData.name/*, to:transition.to*/});
        this.onEnd();
    }

    /**
     * Калбек когда состояние закончило работу по любомй причине (само или было завершено принудительно)
     */
    onEnd() {

    }
}