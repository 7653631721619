const eWinAmountSizes = {
  H: {
    REGULAR: 200,
    SMALL: 123,
  },
  V: {
    REGULAR: 123,
    SMALL: 100,
  }
};

const eTotalWinAmountSizes = {
  H: {
    REGULAR: 200,
    SMALL: 160,
  },
  V: {
    REGULAR: 160,
    SMALL: 150,
  }
};

export {
  eWinAmountSizes,
  eTotalWinAmountSizes,
}
