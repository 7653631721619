import FlashLib from 'flashlib_onlyplay';
import GlobalDispatcher from "Engine/events/GlobalDispatcher";
import EntryPoint from "Engine/EntryPoint";
import { makeHitArea } from 'Engine/utils/makeHitArea';
import eEventTypes from '../../enums/eBaseEventTypes';

export default class BaseSettingsContainer extends FlashLib.MovieClip {
    constructor(data, displayData) {
        super(data, displayData);

        this.interactive = true;

        this.init();
        this.addListeners();
        this.addActions();

        this.hide();
    }

    init() {
        this.wrapper = this.getChildByName('wrapper');
        this.gameMuteCheckBox = this.wrapper.getChildByName('gameMuteCheckBox');
        this.winningMuteCheckBox = this.wrapper.getChildByName('soundMuteCheckBox');
        this.musicMuteCheckBox = this.wrapper.getChildByName('musicMuteCheckBox');
        this.introScreenCheckBox = this.wrapper.getChildByName('introScreenCheckBox');
        this.quickSpinCheckBox = this.wrapper.getChildByName('quickSpinCheckBox');
        this.closeButton = this.wrapper.getChildByName('closeButton');

        if (this.gameMuteCheckBox) {
            this.gameMuteCheckBox.checked = !EntryPoint.GameSettings.soundMute;
            makeHitArea(this.gameMuteCheckBox);
            this.gameMuteCheckBox.enabled = true;

        }
        if (this.winningMuteCheckBox) {
            this.winningMuteCheckBox.checked = !EntryPoint.GameSettings.winningMute;
            makeHitArea(this.winningMuteCheckBox);
            this.winningMuteCheckBox.enabled = true;

        }
        if (this.musicMuteCheckBox) {
            this.musicMuteCheckBox.checked = !EntryPoint.GameSettings.musicMute;
            makeHitArea(this.musicMuteCheckBox);
            this.musicMuteCheckBox.enabled = true;

        }

        if (this.introScreenCheckBox) {
            this.introScreenCheckBox.checked = EntryPoint.GameSettings.introScreen;
            makeHitArea(this.introScreenCheckBox);
            this.introScreenCheckBox.enabled = true;


        }
        if (this.quickSpinCheckBox) {
            this.quickSpinCheckBox.checked = EntryPoint.GameSettings.quickSpin;
            makeHitArea(this.quickSpinCheckBox);
            this.quickSpinCheckBox.enabled = true;

        }
        if (this.closeButton) {
            makeHitArea(this.closeButton);
            this.closeButton.enabled = true;
        }



    }

    addListeners() {
        GlobalDispatcher.add('ui:settingsClick', this.onSettingsClick, this);
        GlobalDispatcher.add('ui:spinClick', this.hide, this);

        if (this.gameMuteCheckBox) GlobalDispatcher.add('gameSettings:soundMute', this.onGameMuteCheckBoxChanged, this);
        if (this.winningMuteCheckBox) GlobalDispatcher.add('gameSettings:winningMute', this.onWinningMuteCheckBoxChanged, this);
        if (this.musicMuteCheckBox) GlobalDispatcher.add('gameSettings:musicMute', this.onMusicMuteCheckBoxChanged, this);
        if (this.introScreenCheckBox) GlobalDispatcher.add('gameSettings:introScreen', this.onIntroScreenCheckBoxChanged, this);
        if (this.quickSpinCheckBox) GlobalDispatcher.add('gameSettings:quickSpin', this.onQuickSpinCheckBoxChanged, this);
    }

    addActions() {
        if (this.gameMuteCheckBox) this.gameMuteCheckBox.actions = {
            checked: {
                callback: this.onGameMuteCheckBox, context: this, params: false
            },
            unchecked: {
                callback: this.onGameMuteCheckBox, context: this, params: true
            }
        };

        if (this.winningMuteCheckBox) this.winningMuteCheckBox.actions = {
            checked: {
                callback: this.onWinningMuteCheckBox, context: this, params: false
            },
            unchecked: {
                callback: this.onWinningMuteCheckBox, context: this, params: true
            }
        };

        if (this.musicMuteCheckBox) this.musicMuteCheckBox.actions = {
            checked: {
                callback: this.onMusicMuteCheckBox, context: this, params: false
            },
            unchecked: {
                callback: this.onMusicMuteCheckBox, context: this, params: true
            }
        };

        if (this.introScreenCheckBox) this.introScreenCheckBox.actions = {
            checked: {
                callback: this.onIntroScreenCheckBox, context: this, params: true
            },
            unchecked: {
                callback: this.onIntroScreenCheckBox, context: this, params: false
            }
        };

        if (this.quickSpinCheckBox) this.quickSpinCheckBox.actions = {
            checked: {
                callback: this.onQuickSpinCheckBox, context: this, params: true
            },
            unchecked: {
                callback: this.onQuickSpinCheckBox, context: this, params: false
            }
        };

        if (this.closeButton) this.closeButton.on('pointertap', this.onCloseButtonClick, this);
    }

    onGameMuteCheckBox(params) {
        EntryPoint.GameSettings.soundMute = params;
    }

    onWinningMuteCheckBox(params) {
        EntryPoint.GameSettings.winningMute = params;
    }

    onMusicMuteCheckBox(params) {
        EntryPoint.GameSettings.musicMute = params;
    }

    onIntroScreenCheckBox(params) {
        EntryPoint.GameSettings.introScreen = params;
    }

    onQuickSpinCheckBox(params) {
        EntryPoint.GameSettings.quickSpin = params;
    }

    onGameMuteCheckBoxChanged(event) {
        this.gameMuteCheckBox.checked = !event.params;
    }

    onWinningMuteCheckBoxChanged(event) {
        this.winningMuteCheckBox.checked = !event.params;
    }

    onMusicMuteCheckBoxChanged(event) {
        this.musicMuteCheckBox.checked = !event.params;
    }

    onIntroScreenCheckBoxChanged(event) {
        this.introScreenCheckBox.checked = event.params;
    }

    onQuickSpinCheckBoxChanged(event) {
        this.quickSpinCheckBox.checked = event.params;
    }

    onCloseButtonClick() {
        this.hide();
    }

    onSettingsClick() {
        if (this.visible) {
            this.hide();
        } else {
            this.show();
        }
    }

    show() {
        if (this.visible) return;

        GlobalDispatcher.dispatch(eEventTypes.EBET_HIDE_USER_INTERFACE);
        this.visible = true;
    }

    hide() {
        if (!this.visible) return;

        GlobalDispatcher.dispatch(eEventTypes.EBET_SHOW_USER_INTERFACE);
        this.visible = false;
    }
}
