import FlashLib from 'flashlib_onlyplay';
import { Spine } from 'pixi-spine';

export default class Animation extends PIXI.Container {
  constructor(name) {
    super();

    this._type = 'spine';
    this._animation = null;
    this._name = name;
    this._loop = false;

    this.init();
  }

  init() {
    this._makeSpineAnimation();
    this._makeFlashLibAnimation();
  }

  play(loop = false, name = 'animation') {
    this.animationName = name;
    this._loop = loop;
    if (this._type === 'spine') {
      this._animation.state.setAnimation(0, this.animationName, this._loop);
    } else {
      this._animation.goToFrame(1);
      this._animation.play(this._loop);
    }
    this.emit('start');
  }

  _makeSpineAnimation() {
    let spineData = null;
    const resource = PIXI.Assets.get(this._name);
    if (!resource) {
      return;
    }

    this.type = 'spine';
    spineData = resource.spineData;
    this._animation = new Spine(spineData);
    this._animation.state.addListener({
      complete: () => {
        setTimeout(() => {
          this._complete();
        }, 0);
      }
    });
    this.addChild(this._animation);
  }

  _makeFlashLibAnimation(data) {
    if (!FlashLib.getItemDataFromLibrary(this._name, 'GameFlashLib')) {
      return;
    }

    this._type = 'flashLib';
    this._animation = FlashLib.createItemFromLibrary(this._name, 'GameFlashLib');
    this._animation.on('animationComplete', () => {
      this._complete();
    });
    this._animation.play(false, false, 20);
    this.addChild(this._animation);
  }

  _complete() {
    if (this._animation) {
      this.emit('complete', this);
      /*if (this._animation && !this._loop) {
          this._animation.destroy({children: true});
          this._animation = null;
      }*/
    }
  }

  stop() {
    if (this._animation) {
      this._animation.lastTime = null;
      this._animation.state.clearTrack(0);
      this._animation.skeleton.setToSetupPose();
      this.emit('stop', this);
      /*this._animation.destroy({children: true});
      this._animation = null;*/
    }
  }

  changeSkin(skinName) {
    this._animation.skeleton.setSkinByName(skinName);
  }

  destroy() {
    if (this._animation && !this._loop) {
      this._animation.destroy({ children: true });
      this._animation = null;
    }
    super.destroy({ children: true });
  }

  reset() {
    if (this._animation) {
      this._animation.lastTime = null;
      this._animation.state.clearTrack(0);
      this._animation.skeleton.setToSetupPose();
    }
  }

  get animation() {
    return this._animation;
  }
}
