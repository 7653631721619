import * as PIXI from 'pixi.js';
import gsap, { Elastic } from 'gsap';
import ParticleSystem from '../particles/particleSystem';
import GlobalDispatcher from '../libs/game-engine/src/events/GlobalDispatcher';
import FlashLib from 'flashlib_onlyplay';
import GameModel from '../model/GameModel';
import animationCreator from 'Engine/animations/animationCreator';
import animationTypes from '../enums/animationTypes';
import { shadowText, whiteYelowOrange } from '../fontStyles';
import MoneyFormat from 'Engine/base/moneyFormat/MoneyFormat';
import particleConfig from '../particles/particleConfig';
import { getRandom } from 'Engine/utils/getRandom';

export default class ControllerBonusPurchaseCard extends FlashLib.MovieClip {
  constructor(data, displayData) {
    super(data, displayData);

    this.init();
    this.initParticles();
    this.setTextStyle();
    this.addListeners();
    this.onResize();

    this.cardAnimationClickInScale = 0.16;
    this.cardAnimationScaleDuration = 0.4;
    this.cardAnimationTimeStart = null;
  }

  init() {
    this.animationContainer = this.getChildByName('animationContainer');

    this.label = this.getChildByName('purchase_card_label1');
    while (this.label.origWidth > this.label.width) {
      this.label.style.fontSize--;
    }
    this.label.correctPosition('center', null);

    this.label.pivot.set(this.label.width / 2, this.label.height / 2);
    this.label.x += this.label.width / 2;
    this.label.y += this.label.height / 2;

    this.pricePanel = this.getChildByName(`pricePanel`);
    this.pricePanel.pivot.set(this.pricePanel.width / 2, this.pricePanel.height / 2);
    this.pricePanel.x += this.pricePanel.width / 2;
    this.pricePanel.y += this.pricePanel.height / 2;

    this.priceText = this.pricePanel.getChildByName('text');

    this.buyButton = this.getChildByName('btnBuy');
    this.makeHitArea(this.buyButton);

    this.buyButtonText = this.buyButton.getChildByName('purchase_btn_buy_label');
    this.buyButton.pivot.set(this.buyButton.width / 2, this.buyButton.height / 2);
    this.buyButton.x += this.buyButton.width / 2;
    this.buyButton.y += this.buyButton.height / 2;

    this.clicked = false;
    this.interactive = true;
    this.checkButtonEnabled();
  }

  makeHitArea(el) {
    el.hitArea = new PIXI.Rectangle(0, 0, el.width, el.height);
  }

  initButtonAnimation() {
    this.btnAnimation = animationCreator.createAnimation(animationTypes.PURCHASE);
    if (!this.btnAnimation) return;

    this.btnAnimation.state.addListener({
      complete: this.loopButtonAnimation.bind(this)
    });

    this.btnAnimation.state.setAnimation(0, 'buy_button_side', false);
    this.buyButton.animation = this.btnAnimation;
  }

  loopButtonAnimation() {
    const delay = getRandom(5000, 10000);
    this.btnAnimationTimeout = setTimeout(() => {
      this.btnAnimation.state.setAnimation(0, 'buy_button_side', false)
    }, delay)
  }

  initCardAnimation() {
    this.cardAnimation = animationCreator.createAnimation(animationTypes.PURCHASE);
    if (!this.cardAnimation) return;
    this.cardAnimation.cursor = 'pointer';

    this.cardAnimation.state.setAnimation(0, 'idle_panda_card', true);

    this.cardAnimation.interactive = true;
    this.cardAnimation.hitArea = new PIXI.Rectangle(
      -202,
      -310,
      404,
      510
    );
    this.animationContainer.addChild(this.cardAnimation);

    this.cardAnimation.on('mouseover', this.onAnimationOver, this);
    this.cardAnimation.on('mouseout', this.onAnimationOut, this);
    this.cardAnimation.on('pointertap', this.onAnimationClick, this);
    this.scaledItems = [this.cardAnimation.scale, this.pricePanel.scale, this.label.scale, this.buyButton.scale];
  }

  addListeners() {
    window.addEventListener('resize', () => setTimeout(this.onResize.bind(this), 1));

    this.buyButton.addListener('pointertap', this.onAnimationClick.bind(this));
    this.buyButton.addListener('mouseover', this.onAnimationOver.bind(this));
    this.buyButton.addListener('mouseout', this.onAnimationOut.bind(this));
  };

  onResize() {
    const width = Math.min(window.innerWidth, window.outerWidth);
    const height = Math.min(window.innerHeight, window.outerHeight);
    const isHorizontalOrientation = (width - height) > 0;

    if (PIXI.utils.isMobile.any && isHorizontalOrientation) {
      switch (this.index) {
        case 0:
          this.x = this.displayData.x;
          break;
        case 1:
          this.y = this.displayData.y;
          break;
        case 2:
          this.x = this.displayData.x;
          break;
      }
    } else if (PIXI.utils.isMobile.any) {
      switch (this.index) {
        case 0:
          this.x = this.displayData.x + 60;
          break;
        case 1:
          this.y = this.displayData.y + 200;
          break;
        case 2:
          this.x = this.displayData.x - 60;
          break;
      }
    }
  };

  checkButtonEnabled() {
    // this.buyButton.enabled = GameModel.balance >= this._price;
    this.buyButton.enabled = true
  }

  set type(type) {
    this._type = type;
  }

  set price(data) {
    this._price = data;
    this.updatePrice();
  }

  updatePrice() {
    this.priceText.text = MoneyFormat.format(this._price, true);
    this.checkButtonEnabled()
  };

  onAnimationOver() {
    if (this.clicked) return;
    gsap.killTweensOf(this.scaledItems);
    gsap.to(this.scaledItems, {
      x: 1.08,
      y: 1.08,
      duration: this.cardAnimationScaleDuration,
    });
  };

  onAnimationOut() {
    if (this.clicked) return;
    gsap.killTweensOf(this.scaledItems);
    gsap.to(this.scaledItems, { x: 1, y: 1, duration: this.cardAnimationScaleDuration });
  };

  onAnimationClick() {
    if (this.clicked) return;
    this.clicked = true;

    this.cardAnimation.state.setAnimation(0, 'buy_panda_card', false);
    this.cardAnimation.state.addListener({
      complete: () => {
        this.cardAnimation.state.setAnimation(0, 'idle_panda_card', true);
      }
    });

    gsap.killTweensOf(this.scaledItems);

    this._animateClickIn();
  };

  setTextStyle() {
    this.label.style = { ...this.label.style, ...whiteYelowOrange };
    this.buyButtonText.style = { ...this.buyButtonText.style, ...shadowText };
  }

  _animateClickIn() {
    const scale = 0.16;
    this.cardAnimationTimeStart = Date.now();
    gsap.to(this.scaledItems, {
      x: this.cardAnimation.scale.x - scale,
      y: this.cardAnimation.scale.y - scale,
      duration: 0.1,
      onComplete: this._animateClickOut.bind(this),
    });
  };

  _animateClickOut() {
    gsap.to(this.scaledItems, {
      ease: Elastic.easeOut.config(1, 0.3),
      x: this.cardAnimation.scale.x + this.cardAnimationClickInScale / 2,
      y: this.cardAnimation.scale.y + this.cardAnimationClickInScale / 2,
      duration: 1,
      onUpdate: this._onAnimationUpdate.bind(this),
      onComplete: this.onAnimationOut.bind(this),
    });
  };

  _onAnimationUpdate() {
    if (!this.clicked) return;
    if (Date.now() - this.cardAnimationTimeStart >= 0.6 * 1000) {
      this._onClickCallback();
    }
  }

  _onClickCallback() {
    this.clicked = false;
    GlobalDispatcher.dispatch('purchaseModal:buy', { price: this._price })
  }

  initParticles() {
    const texture = PIXI.Texture.from('starsParticle');
    const width = 300;
    const container = new PIXI.Container();
    container.pivot.x = width / 2;
    container.x = this.displayData.width / 2;
    container.y = -280;

    this.particles = new ParticleSystem(container, { texture: texture }, {
      ...particleConfig.defaultConfig,
      x0max: width
    });

    this.particles.visible = false;
    this.addChildAt(container, 0);
  }

  show() {
    this.initCardAnimation();
    this.showParticles();
    this.initButtonAnimation();
  }

  showParticles() {
    this.particles.visible = true;
    this.particles.start();
  };

  beforeUnload() {
    if (this.btnAnimationTimeout) {
      clearTimeout(this.btnAnimationTimeout);
    }
    if (this.cardAnimation) this.cardAnimation.destroy();
    if (this.btnAnimation) this.btnAnimation.destroy();
    this.stopParticles();
  }

  stopParticles() {
    this.particles.stop();
  }
}
