import BaseTournamentController from './baseTournamentController';
import eTabViewsTypes from './tabsViews/eTabViewsTypes';
import { OP_WRAPPER_LOCALIZATION_PREFIX } from '../../../../utils/constants';
import ControllerLeaderboardView from './tabsViews/ControllerLeaderboardView';
import ControllerPrizesView from './tabsViews/ControllerPrizesView';
import ControllerRulesView from './tabsViews/ControllerRulesView';
import ControllerNominationsView from './tabsViews/ControllerNominationsView';
import eTournamentStatus from './eTournamentStatus';

export default class ControllerTabs extends BaseTournamentController {
  constructor({ container, ...props }) {
    super({ ...props });
    this._viewsMap = {
      [eTabViewsTypes.ETWT_PRIZES]: new ControllerPrizesView({ type: eTabViewsTypes.ETWT_PRIZES }),
      [eTabViewsTypes.ETWT_NOMINATIONS]: new ControllerNominationsView({ type: eTabViewsTypes.ETWT_NOMINATIONS }),
      [eTabViewsTypes.ETWT_LEADERBOARD]: new ControllerLeaderboardView({ type: eTabViewsTypes.ETWT_LEADERBOARD }),
      [eTabViewsTypes.ETWT_RULES]: new ControllerRulesView({ type: eTabViewsTypes.ETWT_RULES }),
    };
    this._eButtonsTypes = {
      EBT_PRIZES: 'prizes_tab',
      EBT_NOMINATIONS: 'nominations_tab',
      EBT_LEADERBOARD: 'leaderboard_tab',
      EBT_RULES: 'rules_tab',
    };
    this._eEventTypes = {
      PRIZES_CLICK: this.getEventName(this._eButtonsTypes.EBT_PRIZES),
      NOMINATIONS_CLICK: this.getEventName(this._eButtonsTypes.EBT_NOMINATIONS),
      LEADERBOARD_CLICK: this.getEventName(this._eButtonsTypes.EBT_LEADERBOARD),
      RULES_CLICK: this.getEventName(this._eButtonsTypes.EBT_RULES),
    };

    for (let view of Object.values(this._viewsMap)) {
      if (!view.events) continue;
      const viewEvents = view.events[view.controllerType];
      Object.values(viewEvents).forEach(event => view.on(event, (params) => this.emit(event, params)))
      this._eEventTypes = { ...viewEvents, ...this._eEventTypes };
    }

    this._onTabClick = this._onTabClick.bind(this);
    this._eButtonsActions = {
      [this._eButtonsTypes.EBT_PRIZES]: this._onTabClick,
      [this._eButtonsTypes.EBT_NOMINATIONS]: this._onTabClick,
      [this._eButtonsTypes.EBT_LEADERBOARD]: this._onTabClick,
      [this._eButtonsTypes.EBT_RULES]: this._onTabClick,
    }
    this._eElementsTypes = {
      EET_VIEW_CONTAINER: 'view_container',
      EET_PRIZES_TAB: this._eButtonsTypes.EBT_PRIZES,
      EET_PRIZES_TAB_LABEL: `${this._eButtonsTypes.EBT_PRIZES}_label`,
      EET_NOMINATIONS_TAB: this._eButtonsTypes.EBT_NOMINATIONS,
      EET_NOMINATIONS_TAB_LABEL: `${this._eButtonsTypes.EBT_NOMINATIONS}_label`,
      EET_LEADERBOARD_TAB: this._eButtonsTypes.EBT_LEADERBOARD,
      EET_LEADERBOARD_TAB_LABEL: `${this._eButtonsTypes.EBT_LEADERBOARD}_label`,
      EET_RULES_TAB: this._eButtonsTypes.EBT_RULES,
      EET_RULES_TAB_LABEL: `${this._eButtonsTypes.EBT_RULES}_label`,
      EET_PLAYER_STATS_CONTAINER: 'player_stats_container',
      EET_PLAYER_SCORE_LABEL: 'player_score_label',
      EET_PLAYER_SCORE: 'player_score',
      EET_PLAYER_RANK_LABEL: 'player_rank_label',
      EET_PLAYER_RANK: 'player_rank',
    };
    this._localizations = {
      [this._eElementsTypes.EET_PRIZES_TAB_LABEL]: `${OP_WRAPPER_LOCALIZATION_PREFIX}.tournaments.tabs.prizes`,
      [this._eElementsTypes.EET_NOMINATIONS_TAB_LABEL]: `${OP_WRAPPER_LOCALIZATION_PREFIX}.tournaments.tabs.nominations`,
      [this._eElementsTypes.EET_LEADERBOARD_TAB_LABEL]: `${OP_WRAPPER_LOCALIZATION_PREFIX}.tournaments.tabs.leaderboard`,
      [this._eElementsTypes.EET_RULES_TAB_LABEL]: `${OP_WRAPPER_LOCALIZATION_PREFIX}.tournaments.tabs.rules`,
      [this._eElementsTypes.EET_PLAYER_SCORE_LABEL]: `${OP_WRAPPER_LOCALIZATION_PREFIX}.tournaments.playerStats.player_score`,
      [this._eElementsTypes.EET_PLAYER_RANK_LABEL]: `${OP_WRAPPER_LOCALIZATION_PREFIX}.tournaments.playerStats.player_rank`,
    };
    this._tabsMap = {
      [eTabViewsTypes.ETWT_RULES]: this._eElementsTypes.EET_RULES_TAB,
      [eTabViewsTypes.ETWT_NOMINATIONS]: this._eElementsTypes.EET_NOMINATIONS_TAB,
      [eTabViewsTypes.ETWT_LEADERBOARD]: this._eElementsTypes.EET_LEADERBOARD_TAB,
      [eTabViewsTypes.ETWT_PRIZES]: this._eElementsTypes.EET_PRIZES_TAB,
    };

    this.init(container);
    this.currentView = eTabViewsTypes.ETWT_LEADERBOARD;
  }

  cleanup() {
    super.cleanup();
    this.currentView = eTabViewsTypes.ETWT_LEADERBOARD;
  }

  _onDataUpdated() {
    super._onDataUpdated();
    this._updatePlayerStats();
    this._updateView();
  }

  _onTabClick(e) {
    const tab = e.currentTarget;
    this.currentView = tab.dataset.viewKey;
  }

  _setTabActive(activeTabType) {
    for (let type of Object.keys(this._tabsMap)) {
      const tab = this.interactiveElements[this._tabsMap[type]];
      if (type === activeTabType) {
        tab.classList.add('active');
      } else {
        tab.classList.remove('active');
      }
    }
  }

  _updateView() {
    if (this._data.status === eTournamentStatus.ETS_ACTIVE) {
      this.interactiveElements[this._eElementsTypes.EET_VIEW_CONTAINER].classList.add('active');
      this.interactiveElements[this._eElementsTypes.EET_PLAYER_STATS_CONTAINER].classList.add('active');
    } else {
      this.interactiveElements[this._eElementsTypes.EET_VIEW_CONTAINER].classList.remove('active')
      this.interactiveElements[this._eElementsTypes.EET_PLAYER_STATS_CONTAINER].classList.remove('active')
    }

    if (!this._data || !this._data.data || !this._data.data.nominations.some(nominationData => nominationData.reward)) {
      this.interactiveElements[this._eElementsTypes.EET_NOMINATIONS_TAB].classList.add('hidden');
    }

    this._currentView.updateData(this._data);
  }

  _updatePlayerStats() {
    if (!this._data || !this._data.data) return;

    this.set(this._eElementsTypes.EET_PLAYER_SCORE, this._data.data.playerStats.score);
    this.set(this._eElementsTypes.EET_PLAYER_RANK, this._data.data.playerStats.place);
  }

  get dataSelector() {
    return (data) => ({ data: data.data, userId: data.userId, tournamentId: data.tournamentId, localizationTournamentId: data.localizationTournamentId, status: data.status, details: data.details });
  }

  set currentView(type) {
    const controllerView = this._viewsMap[type];
    if (this._currentView === controllerView) return;

    if (this._currentView) this._currentView.beforeRemove();
    const viewContainer = this.interactiveElements[this._eElementsTypes.EET_VIEW_CONTAINER];
    viewContainer.innerHTML = '';

    this._setTabActive(type);
    this._currentView = controllerView;
    this._currentView.init(viewContainer);
    if (this._data) this._currentView.updateData(this._data);
  }

  _getMarkup() {
    return `<div class="system-ui-container__tournaments_main__body_content__stat">
              <div class="system-ui-container__tournaments_main__body_content__stat_tabs">
                <button id=${this.interactiveElementsIds[this._eElementsTypes.EET_PRIZES_TAB]} class="primary" data-view-key=${eTabViewsTypes.ETWT_PRIZES}>
                  <span id=${this.interactiveElementsIds[this._eElementsTypes.EET_PRIZES_TAB_LABEL]}>
                    ${this._getLocalization(this._localizations[this._eElementsTypes.EET_PRIZES_TAB_LABEL])}
                  </span>
                </button>
                <button id=${this.interactiveElementsIds[this._eElementsTypes.EET_NOMINATIONS_TAB]} class="primary" data-view-key=${eTabViewsTypes.ETWT_NOMINATIONS}>
                  <span id=${this.interactiveElementsIds[this._eElementsTypes.EET_NOMINATIONS_TAB_LABEL]}>
                    ${this._getLocalization(this._localizations[this._eElementsTypes.EET_NOMINATIONS_TAB_LABEL])}
                  </span>
                </button>
                <button id=${this.interactiveElementsIds[this._eElementsTypes.EET_LEADERBOARD_TAB]} class="primary active" data-view-key=${eTabViewsTypes.ETWT_LEADERBOARD}>
                  <span id=${this.interactiveElementsIds[this._eElementsTypes.EET_LEADERBOARD_TAB_LABEL]}>
                    ${this._getLocalization(this._localizations[this._eElementsTypes.EET_LEADERBOARD_TAB_LABEL])}
                  </span>
                </button>
                <button id=${this.interactiveElementsIds[this._eElementsTypes.EET_RULES_TAB]} class="primary" data-view-key=${eTabViewsTypes.ETWT_RULES}>
                  <span id=${this.interactiveElementsIds[this._eElementsTypes.EET_RULES_TAB_LABEL]}>
                    ${this._getLocalization(this._localizations[this._eElementsTypes.EET_RULES_TAB_LABEL])}
                  </span>
                </button>
              </div>

              <div id=${this.interactiveElementsIds[this._eElementsTypes.EET_PLAYER_STATS_CONTAINER]} class="system-ui-container__tournaments_main__body_content__stat_player">
                <div>
                  <span id=${this.interactiveElementsIds[this._eElementsTypes.EET_PLAYER_SCORE_LABEL]}>${this._getLocalization(this._localizations[this._eElementsTypes.EET_PLAYER_SCORE_LABEL])}</span>
                  <span id=${this.interactiveElementsIds[this._eElementsTypes.EET_PLAYER_SCORE]}>456</span>
                </div>
                <div>
                  <span id=${this.interactiveElementsIds[this._eElementsTypes.EET_PLAYER_RANK_LABEL]}>${this._getLocalization(this._localizations[this._eElementsTypes.EET_PLAYER_RANK_LABEL])}</span>
                  <span id=${this.interactiveElementsIds[this._eElementsTypes.EET_PLAYER_RANK]}>12472</span>
                </div>
              </div>

              <div id=${this.interactiveElementsIds[this._eElementsTypes.EET_VIEW_CONTAINER]} class="system-ui-container__tournaments_main__body_content__stat_view">
              </div>
            </div>`
  }
}
