import { Spine } from 'pixi-spine';
import EntryPoint from 'Engine/EntryPoint';

export default new class AnimationCreator {

  getSpineData(type) {
    let spineData = null;
    let resources = PIXI.Assets.get(type);
    if (resources) {
      spineData = resources.spineData;
      spineData.spineAtlas = resources.spineAtlas
    }
    return spineData;
  }

  createAnimation(type) {
    let spineData = this.getSpineData(type);
    return spineData ? new Spine(spineData) : null;
  }

  async createAnimationWithDelayedLoading(type) {
    await PIXI.Assets.load(type)

    return this.createAnimation(type);
  }

  async uploadToGPU(animation) {
    const promises = animation.spineData.spineAtlas.pages.map(page => EntryPoint.Game.app.renderer.prepare.upload(page));
    return Promise.all(promises);
  }
}
