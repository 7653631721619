import GlobalDispatcher from 'Engine/events/GlobalDispatcher';
import StatesManager from 'Engine/base/states/StatesManager';
import InfoPopupManager from 'Engine/base/infoPopup/infoPopupManager';
import eBigWinTypes from '../enums/eBigWinTypes';
import eBigWinDefaultCoefs from '../enums/eBigWinDefaultCoefs';
import BaseGameModel from 'Engine/base/model/BaseGameModel';
import SlotGameModel from 'Engine/slots/model/SlotGameModel';
import ServerManager from '../server/ServerManager';
import EntryPoint from 'Engine/EntryPoint';

export default new class GameModel extends SlotGameModel {
  constructor() {
    super();

    this._bonusCostMultiplier = null;
    this._respinEnabled = false;
    this._betForRespin = -1;
    this._specialData = null;
    this._respinCost = [0, 0, 0, 0, 0];
    this._respinReelIndex = null;
    this._isPurchaseOpen = false;
    this._isFreeSpinsMode = false;

    GlobalDispatcher.addOnce('model:getServerData', () => {
      this._winRankCoef = {
        [eBigWinTypes.EBWT_WIN]: eBigWinDefaultCoefs.EBWC_WIN,
        [eBigWinTypes.EBWT_BIG_WIN]: OPWrapperService.config.minWinRankCoef ? OPWrapperService.config.minWinRankCoef : eBigWinDefaultCoefs.EBWC_BIG_WIN,
        [eBigWinTypes.EBWT_MEGA_WIN]: eBigWinDefaultCoefs.EBWC_MEGA_WIN,
        [eBigWinTypes.EBWT_EPIC_WIN]: eBigWinDefaultCoefs.EBWC_EPIC_WIN,
      };
    }, this);
  }
  init() {
    super.init();
    ServerManager.onInit();
  }

  addListeners() {
    super.addListeners();
    GlobalDispatcher.add('startRespin', this.onStartRespinClick, this);
  }

  onStartRespinClick(event) {
    this._respinReelIndex = event.params;
    StatesManager.goToNextState();
  }

  checkStartRespin() {
    if (this._respinReelIndex === null || this._respinReelIndex === undefined) {
      return false;
    }

    GlobalDispatcher.dispatch('model:startRespin');
    this.balance -= this.respinCost[this.respinReelIndex];
    return true;

    /*if (this.balance >= this.respinCost[this.respinReelIndex]) {
        GlobalDispatcher.dispatch('model:startRespin');
        this.balance -= this.respinCost[this.respinReelIndex];
        return true;
    } else {
        console.log('noMoney');
        ErrorManager.noMoney();
        GlobalDispatcher.dispatch('model:noMoney');
        if (EntryPoint.AutoPlaySettings.active) {
            EntryPoint.AutoPlaySettings.active = false;
        }
        return false;
    }*/
  }

  checkStartSpin() {
    if (this.balance >= this.bet || this.isFreeSpinsMode) {
      GlobalDispatcher.dispatch('model:startSpin');
      // this.balance -= this.totalBet;
      return true;
    } else {
      InfoPopupManager.noMoney();
      if (EntryPoint.AutoPlaySettings.active) {
        EntryPoint.AutoPlaySettings.active = false;
      }
      return false;
    }
  }

  onMaxBetPressed(data) {
    if (this.respinEnabled && this._betForRespin !== -1 && this.calculateMaxBet().bet !== this._betForRespin) {
      InfoPopupManager.cancelRespin({ callback: this.cancelRespinAndDoMaxBet, context: this, params: data.params });
    } else {
      this.setMaxBet(data.params);
    }
  }

  onChangeBetPressed(data) {
    if (this.respinEnabled && this._betForRespin !== -1 &&
      data.params >= 0 && data.params < this._betPossible.length &&
      this._betPossible[data.params] !== this._betForRespin) {
      InfoPopupManager.cancelRespin({
        callback: this.cancelRespinAndDoBet,
        callbackByCancel: this.stopCancelRespin,
        context: this,
        params: data.params
      });
    } else {
      this.changeBet(data.params);
    }
  }

  stopCancelRespin() {
  }

  cancelRespinAndDoMaxBet(data) {
    this._betForRespin = -1;
    this.respinEnabled = false;
    this.setMaxBet(data);
  }

  cancelRespinAndDoBet(data) {
    this._betForRespin = -1;
    this.respinEnabled = false;
    this.changeBet(data);
  }

  changeBet(index) {
    if (index >= this._betPossible.length) index = this._betPossible.length - 1;
    if (index < 0) index = 0;

    this.betIndex = index;
  }

  setNewData(data) {
    this._lastWin = this.totalWin || this._lastWin;
    this._specialData = data.special || null;
    super.setNewData(data);
    this._betForRespin = this.bet;
    this._respinEnabled = !window.OPWrapperService.freeBetsController.isFreeBets && !!data.respinCost;
    this._respinCost = data.respinCost || this.respinCost;
    this._freeSpins = data.freeSpins || {};
    this._realityCheck = data.realityCheck || null;

    this._bonusCostMultiplier = data.bonusCostMultiplier || this._bonusCostMultiplier;
    this.respinReelIndex = data.respinReelIndex;
    this._isFreeSpinsMode = Boolean(Object.keys(this._freeSpins).length);
    this.freeSpinsInActiveState = this.isFreeSpinsMode && this.freeSpins.played > 0;
    console.log('isFreeSpinsMode: ', this.isFreeSpinsMode, '\nfreeSpinsInActiveState: ', this.freeSpinsInActiveState);
    this._paytableInfo = data.paytableInfo || this._paytableInfo;

    this.country = data.country;
    this.lang = data.lang;

    // if(data.country === 'DE') {
    //     SpinTimeManager.minimalTime = 5000;
    //     Constants.MINIMAL_SPIN_TIME = 5000;
    // }
    GlobalDispatcher.dispatch('model:getServerData', data.action);
  }

  get isFreeSpinsMode(){
    return this._isFreeSpinsMode;
  }
  set isFreeSpinsMode(value){
    this._isFreeSpinsMode = value;
  }

  get specialData() {
    return this._specialData;
  }

  get respinCost() {
    return this._respinCost;
  }

  get respinReelIndex() {
    return this._respinReelIndex;
  }

  set respinReelIndex(value) {
    this._respinReelIndex = value;
  }

  get respinEnabled() {
    return this._respinEnabled;
  }

  get paytableInfo() {
    return this._paytableInfo;
  }

  set respinEnabled(value) {
    this._respinEnabled = value;
    GlobalDispatcher.dispatch('model:respinEnabled', this.respinEnabled);
  }

  get isBigWin() {
    return this.totalWin >= this.bet * eBigWinDefaultCoefs.EBWC_BIG_WIN//10;
  }

  get realityCheck() {
    return this._realityCheck;
  }

  get currencyInfo() {
    return this._currencyInfo;
  }

  get bonusCostMultiplier() {
    return this._bonusCostMultiplier;
  }


  get freeSpinsCount() {
    return this.freeSpins.left;
  }

  set isFreeSpinsInActiveState(value) {
    this._isFreeSpinsInActiveState = value;
  }

  get isFreeSpinsInActiveState() {
    return this._isFreeSpinsInActiveState;
  }

  get freespinsEnabled() {
    return this.isFreeSpinsInActiveState;
  }

  get freespins() {
    return this.freeSpinsCount;
  }

  get freespinsTotalWin() {
    return this.freeSpins.totalWin;
  }

  set isPurchaseOpen(value) {
    this._isPurchaseOpen = value;
  }

  get isPurchaseOpen() {
    return this._isPurchaseOpen;
  }

  get lastWin() {
    return this._lastWin;
  }
  get winRankCoef() {
    return this._winRankCoef;
  }
}
