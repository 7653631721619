import BaseController from './basePlayerController';
import { OP_WRAPPER_LOCALIZATION_PREFIX } from '../../../utils/constants';

export default class ControllerBalance extends BaseController {
  constructor({ container, ...props }) {
    super({ ...props });
    this._eElementsTypes = {
      EET_LABEL: 'label',
      EET_VALUE: 'value',
    };
    this._localizations = {
      [this._eElementsTypes.EET_LABEL]: `${OP_WRAPPER_LOCALIZATION_PREFIX}.userInterface.balance_label`,
    };
    this._adaptiveElements = [this._eElementsTypes.EET_LABEL, this._eElementsTypes.EET_VALUE];

    this.init(container);
  }

  setValue(value) {
    const numValue = Number(value);
    this.set(this._eElementsTypes.EET_VALUE, this._moneyFormat(numValue));
  }

  _getMarkup() {
    return `<div class="ui-container__title-desc-col ui-container__balance_container">
          <label id=${this.interactiveElementsIds[this._eElementsTypes.EET_LABEL]}>
            ${this._getLocalization(this._localizations[this._eElementsTypes.EET_LABEL])}
          </label>
          <span id=${this.interactiveElementsIds[this._eElementsTypes.EET_VALUE]}>
            0.12345678
          </span>
        </div>`
  }
}
