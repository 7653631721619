export const EMPostMessageTypes = {
  PMT_LOAD_PROGRESS: 'loadProgress',
  PMT_LOAD_COMPLETED: 'loadCompleted',
  PMT_AUDIO_TOGGLE: 'audioToggle',
  PMT_ROUND_START: 'roundStart',
  PMT_ROUND_END: 'roundEnd',
  PMT_STAKE_UPDATE: 'stakeUpdate',
  PMT_CLOSED: 'closed',
  PMT_ERROR_MESSAGE: 'errorMessage',
};

export const EMReceiveMessageTypes = {
  RMT_UPDATE_BALANCE: 'updateBalance',
  RMT_STOP_AUTO_SPINS: 'stopAutoSpins',
  RMT_SET_AUDIO: 'setAudio',
  RMT_SHOW_HELP: 'showHelp',
  RMT_TOGGLE_PAYTABLE: 'togglePaytable',
};
