import Adapter from './Adapter';

export const postMessageTypes = {
  PMT_LOAD_PROGRESS: 'loadProgress',
  PMT_LOAD_COMPLETED: 'loadCompleted',
  PMT_AUDIO_TOGGLE: 'audioToggle',
  PMT_ROUND_START: 'roundStart',
  PMT_ROUND_END: 'roundEnd',
  PMT_STAKE_UPDATE: 'stakeUpdate',
  PMT_CLOSED: 'closed',
  PMT_ERROR_MESSAGE: 'errorMessage',
};

export const receiveMessageTypes = {
  RMT_UPDATE_BALANCE: 'updateBalance',
  RMT_STOP_AUTO_SPINS: 'stopAutoSpins',
  RMT_SET_AUDIO: 'setAudio',
  RMT_SHOW_HELP: 'showHelp',
  RMT_TOGGLE_PAYTABLE: 'togglePaytable',
};

export default new class Manager {
  constructor() {
    window.addEventListener('message', this._onMessage);
  }

  loadProgress(progress) {
    this._send(postMessageTypes.PMT_LOAD_PROGRESS, progress);
  }

  loadCompleted() {
    this._send(postMessageTypes.PMT_LOAD_COMPLETED);
  }

  audioToggle(data) {
    this._send(postMessageTypes.PMT_AUDIO_TOGGLE, data);
  }

  roundStart() {
    this._send(postMessageTypes.PMT_ROUND_START);
  }

  roundEnd() {
    this._send(postMessageTypes.PMT_ROUND_END);
  }

  stakeUpdate(data) {
    this._send(postMessageTypes.PMT_STAKE_UPDATE, data);
  }

  closed() {
    this._send(postMessageTypes.PMT_CLOSED);
  }

  errorMessage(data) {
    this._send(postMessageTypes.PMT_ERROR_MESSAGE, data);
  }

  _onMessage(event) {
    const message = event.data;
    const receiveTypes = Object.values(receiveMessageTypes);
    // console.log(message);
    if (receiveTypes.includes(message.name) && message.sender === 'emwindow') {
      Adapter[message.name](message.data);
    }
  }

  _send(type, data) {
    const message = { name: type, sender: 'game' };
    if (data != null) message.data = data;
    window.postMessage(message, '*');
  }
}
