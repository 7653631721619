import EntryPoint from "Engine/EntryPoint";
import GlobalDispatcher from "Engine/events/GlobalDispatcher";
import StatesManager from "Engine/base/states/StatesManager";

export default class AutoPlaySettings {
    constructor() {
        this._count = -1;
        this._selectedCount = -1;
        this._possibleCounts = [];
        this._conditions = [];
        this._active = false;
        this._paused = false;
        this._activeConditions = [];

        this.createConditions();
        this.addListeners();
    }

    init() {
        this._possibleCounts = [10, 25, 50];/*EntryPoint.configData.autoPlayConfig.possibleCounts;*/
        this.resetCount();
        EntryPoint.AutoPlaySettings = this;
    }

    addListeners() {
        GlobalDispatcher.add('stateManager.stateChanged', this.onStateChanged, this);
        GlobalDispatcher.add('autoplay:setActive', this.onSetActive, this);
        GlobalDispatcher.add('autoplay:setPaused', this.onSetPaused, this);
        GlobalDispatcher.add('autoplay:setCount', this.onSetCount, this);
        GlobalDispatcher.add('autoplay:setConditions', this.onSetConditions, this);

        GlobalDispatcher.add('overlay:showed', this.onOverlayShowed, this);
        GlobalDispatcher.add('overlay:hided', this.onOverlayHided, this);
    }

    createConditions() {
        let condition = () => {
            return (StatesManager.currentState.stateData.name === 'showWinningsState' && EntryPoint.GameModel.totalWin > 0);
        };
        this._conditions.push({name: 'stopOnWin', condition: condition});
    }

    onSetActive(event) {
        let value = event.params;
        if (value === undefined) {
            value = !this.active;
        }
        this.active = value;
    }

    onSetPaused(event) {
        this.paused = event.params;
    }

    onSetCount(event) {
        this.selectedCount = this.possibleCounts[event.params];
    }

    onSetConditions(event) {
        this.activeConditions = event.params;
    }

    onStateChanged(event) {
        this.checkForCondition();
        if (!this.active || this.paused) {
            return;
        }
        switch (event.params) {
            case 'initState':

                break;
            case 'idleState':

                break;
            // case 'minimalRollingState':
            //     if (this.active && this.count > 0) {
            //         this.count -= 1;
            //         console.log(this.count, 'this.count');
            //     }
            //     break;
            default:

                break;
        }
    }

    checkForCondition() {
        if (!this.active || this.paused) {
            return;
        }
        this.activeConditions.forEach((conditionName)=>{
            let currentCondition = this._conditions.find((conditionData)=>{
                return conditionData.name === conditionName;
            });
            if (currentCondition.condition()) {
                this.active = false;
            }
        });
    }

    resetCount() {
        this.selectedCount = this.possibleCounts[0];
    }

    onOverlayShowed() {
        if (EntryPoint.GameModel.isFreeSpins) {
            return
        }
        this.paused = true;
    }

    onOverlayHided() {
        if (EntryPoint.GameModel.isFreeSpins) {
            return
        }
        this.paused = false;
    }

    get count() {
        return this._count;
    }

    set count(value) {
        this._count = value;
        GlobalDispatcher.dispatch('autoPlaySettings:countChanged', this.count);

        if (this.count === 0) {
            this.active = false;
        }
    }

    get possibleCounts() {
        return this._possibleCounts;
    }

    get active() {
        return this._active;
    }

    set active(value) {
        this._active = value;
        GlobalDispatcher.dispatch('autoPlaySettings:activeChanged', this.active);
    }

    get selectedCount() {
        return this._selectedCount;
    }

    set selectedCount(value) {
        this.count = value;
        this._selectedCount = value;
        GlobalDispatcher.dispatch('autoPlaySettings:selectedCountChanged', this.selectedCount);
    }

    get paused() {
        return this._paused;
    }

    set paused(value) {
        this._paused = value;
        GlobalDispatcher.dispatch('autoPlaySettings:pausedChanged', this.paused);
    }

    get activeConditions() {
        return this._activeConditions;
    }

    set activeConditions(value) {
        this._activeConditions = value;
    }
}
