import FlashLib from "flashlib_onlyplay";
import { Linear, gsap } from 'gsap';
import * as PIXI from 'pixi.js';

export default class BackgroundContainer extends FlashLib.MovieClip {
    constructor(data, displayData) {
        super(data, displayData);
        this.showedFreeSpinsBackground = false;

        this.init();
        this.createTweens();
        this.active = false;
        this.addListeners();
    }

    init() {
        this.freeSpinsBackground = this.getChildByName('freeSpinsBackground');
        this.freeSpinsBackground.visible = this.showedFreeSpinsBackground;
    }

    createTweens() {
        this.showTween = gsap.fromTo(this.freeSpinsBackground, {alpha:0},
          {alpha:1, duration: 1, ease:Linear.easeNone, repeat:0, yoyo:false, paused:true});
        this.hideTween = gsap.fromTo(this.freeSpinsBackground, {alpha:1},
          {alpha:0, duration: 1, ease:Linear.easeNone, repeat:0, yoyo:false, paused:true, onComplete: this._hideFreeSpinsBack.bind(this)});
    }

    showFreeSpinsBack() {
        if (this.active) return;
        this.showedFreeSpinsBackground = true;
        this.freeSpinsBackground.visible = true;
        this.active = true;
        this.showTween.play(0);
    }

    hideFreeSpinsBack() {
        this.hideTween.play(0);
        this.active = false;
        this.showedFreeSpinsBackground = false;
        this.freeSpinsBackground.visible = false;

        if (this.animation) {
            this.animation.destroy({ children: true });
            this.animation = null;
        }
    }

    addListeners() {
        window.OPWrapperService.eventManager.add(
          window.OPWrapperService.eventManager.types.EET_SCALE_MANAGER_RESIZE,
          this.onGameResized,
          this
        );
    }

    onGameResized(data) {
        const isMobile = PIXI.isMobile.any;
        const newFrameIndex = !isMobile || data.isLandscape ? 2 : 1;

        if (newFrameIndex !== this.currentFrameIndex) {
            this._setFrameIndexAndInitGameObjects(newFrameIndex);
        }
    }

    _setFrameIndexAndInitGameObjects(frameIndex) {
        this.goToFrame(frameIndex);
        this.init();
    }

    _hideFreeSpinsBack() {
        this.active = false;
        this.freeSpinsBackground.visible = false;
    }
}
