import GlobalDispatcher from "Engine/events/GlobalDispatcher";

export default new class InfoPopupManager {
  constructor() {

  }

  noMoney(data) {
    let errorData = {
      type: 1,
      title: 'info_title1_text',
      message: 'info_message1_text'
    };
    GlobalDispatcher.dispatch('infoPopupManager:showError', errorData);
  }

  cancelRespin(data) {
    let errorData = {
      type: 2,
      title: 'info_title2_text',
      message: 'info_message2_text',
      button2: {callback: data.callback, context: data.context, params:data.params},
      button1: {callback: data.callbackByCancel, context: data.context, params:data.params}

    };
    GlobalDispatcher.dispatch('infoPopupManager:showError', errorData);
  }

  backendError(data) {
    let errorData = {
      type: 1,
      title: 'info_title3_text',
      message: data.message,
      button1: {
        callback: ()=>{
          window.location.reload();
        }
      }
    };
    GlobalDispatcher.dispatch('infoPopupManager:showError', errorData);
  }
}
