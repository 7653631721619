import FlashLib from "flashlib_onlyplay";
import GlobalDispatcher from "Engine/events/GlobalDispatcher";
import EntryPoint from "Engine/EntryPoint";

export default class FreeSpinsTopContainer extends FlashLib.MovieClip {
    constructor(data, displayData) {
        super(data, displayData);
        this.init();
        this.addListeners();
        this.initFrame(Math.min(window.innerWidth, window.outerWidth), Math.min(window.innerHeight, window.outerHeight));
    }

    init() {
        this.totalWinContainer = this.getChildByName('totalWinContainer');
        this.countContainer = this.getChildByName('countContainer');
        this.symbol = this.getChildByName('symbol').getChildAt(1);
        this.totalWinContainer.setValue(EntryPoint.GameModel.freeSpins.totalWin || 0);
        this.countContainer.setValue((EntryPoint.GameModel.freeSpins.played || 0) + '/' + EntryPoint.GameModel.freeSpins.total);
        this.symbol.changeSymbol(EntryPoint.GameModel.freeSpins.specialSymbol || 1);
    }

    onGameResized(data) {
        this.initFrame(data.canvasWidth, data.canvasHeight);
    }

    initFrame(width, height) {
        if (EntryPoint.mobile) {
            let frameIndex = height > width ? 2 : 1;
            this.goToFrame(frameIndex);
            this.init();
        }
    }

    addListeners() {
        GlobalDispatcher.add('stateStart:requestRollingState', this.onRequestRollingStateStart, this);
        GlobalDispatcher.add('stateStart:showWinningsState', this.onShowWinningsStateStart, this);
        GlobalDispatcher.add('stateStart:expandSymbolsState', this.onShowWinningsStateStart, this);
        window.OPWrapperService.eventManager.add(
          window.OPWrapperService.eventManager.types.EET_SCALE_MANAGER_RESIZE,
          this.onGameResized,
          this
        );
    }

    onRequestRollingStateStart() {
        this.countContainer.setValue((EntryPoint.GameModel.freeSpins.played + 1 || 0) + '/' + EntryPoint.GameModel.freeSpins.total);
    }

    onShowWinningsStateStart() {
        this.totalWinContainer.setValue(EntryPoint.GameModel.freeSpins.totalWin);
    }

    destroy(options) {
        GlobalDispatcher.remove('stateStart:requestRollingState', this.onRequestRollingStateStart, this);
        GlobalDispatcher.remove('stateStart:showWinningsState', this.onShowWinningsStateStart, this);
        GlobalDispatcher.remove('stateStart:expandSymbolsState', this.onShowWinningsStateStart, this);
        window.OPWrapperService.eventManager.remove(
          window.OPWrapperService.eventManager.types.EET_SCALE_MANAGER_RESIZE,
          this.onGameResized,
          this
        );
        super.destroy(options);
    }

    show() {
        this.visible = true;
    }


    hide() {
        this.visible = false;
    }
}
