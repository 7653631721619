import EntryPoint from "Engine/EntryPoint";

export default new class NearWinCalculator {
    constructor() {
        this._calculateReels = [0, 1, 2, 3, 4];
        this._nearReels = [];
        this._searchSymbol = 1;

        this.init();
    }

    init() {
        EntryPoint.NearWinCalculator = this;
    }

    calculate() {
        this._nearReels = [];
        let found = [];
        this._calculateReels.forEach((index) => {
            EntryPoint.GameModel.reels[index].forEach((symbol) => {
                if (symbol === this._searchSymbol) {
                    found.push(index);
                }
            })
        });
        /*switch (found.length) {
            default:
        }*/
        if (found.length >= 2) {
            let value = found[1];
            if (value <= 3) {
                for (let i = value + 1; i < 5; i++) {
                    this._nearReels.push(i);
                }
            }
        }
    }

    calculateRespin(reelIndex) {
        this._nearReels = [];
        let found = [];
        this._calculateReels.forEach((index) => {
            EntryPoint.GameModel.reels[index].forEach((symbol) => {
                if (symbol === this._searchSymbol) {
                    found.push(index);
                }
            })
        });
        if (found.length === 2 && found.indexOf(reelIndex) === -1) {
            this._nearReels.push(reelIndex);
        }
        if (found.length > 2 && found.indexOf(reelIndex) !== -1) {
            this._nearReels.push(reelIndex);
        }
    }

    reset() {
        this._nearReels = [];
    }

    get nearReels() {
        return this._nearReels;
    }
}
