import InterExtraBoldTtf from '../../../assets/fonts/Inter-ExtraBold.ttf';
import InterSemiBoldTtf from '../../../assets/fonts/Inter-SemiBold.ttf';

export default [
  {
    name: 'InterExtraBold',
    path: InterExtraBoldTtf,
  },
  {
    name: 'InterSemiBold',
    path: InterSemiBoldTtf,
  },
]
