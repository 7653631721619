import eSystemControllerTypes from '../eControllerTypes';

export default {
  ETCT_HEADER: `${eSystemControllerTypes.ECT_TOURNAMENTS}__header`,
  ETCT_BANNER: `${eSystemControllerTypes.ECT_TOURNAMENTS}__banner`,
  ETCT_BONUS_DETAILS: `${eSystemControllerTypes.ECT_TOURNAMENTS}__bonus_details`,
  ETCT_MODAL_BONUS_DETAILS: `${eSystemControllerTypes.ECT_TOURNAMENTS}__modal_bonus_details`,
  ETCT_COMPANY_LOGO: `${eSystemControllerTypes.ECT_TOURNAMENTS}__company_logo`,
  ETCT_TABS: `${eSystemControllerTypes.ECT_TOURNAMENTS}__tabs`,
  ETCT_ARCHIVE_LIST: `${eSystemControllerTypes.ECT_TOURNAMENTS}__archive_list`,
  ETCT_SPINNER: `${eSystemControllerTypes.ECT_TOURNAMENTS}__spinner`,
}
