import BaseSystemController from '../baseSystemController';

export default class BaseTournamentController extends BaseSystemController {
  constructor(...props) {
    super(...props);
    this._tryToUpdateLocalizations = this._tryToUpdateLocalizations.bind(this);
    window.OPWrapperService.localizations.onTournamentLocalizationReceived.add(this._tryToUpdateLocalizations);
  }

  beforeRemove() {
    super.beforeRemove();
    window.OPWrapperService.localizations.onTournamentLocalizationReceived.remove(this._tryToUpdateLocalizations);
  }

  _tryToUpdateLocalizations() {}

  get elementsIdPrefix() {
    return `${super.elementsIdPrefix}__tournaments`;
  }
}
