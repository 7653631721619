export default {
  EBST_START_SCREEN: 'showStartScreenState',
  EBST_IDLE: 'idleState',
  EBST_BUY_BONUS: 'buyBonusState',
  EBST_FREE_SPINS_IN: 'FreespinsInState',
  EBST_FREE_SPINS_OUT: 'FreespinsOutState',
  EBST_INIT: 'initState',
  EBST_REQUEST_ROLLING: 'requestRollingState',
  EBST_MINIMAL_ROLLING: 'minimalRollingState',
  EBST_STOP_ROLLING: 'stopRollingState',
  EBST_SHOW_WIN: 'showWinState',
  EBST_STOP_WIN: 'stopWinState',
  EBST_WAIT_USER_INTERACTION: 'waitUserInteractionState',
}
