import BaseState from "Engine/base/states/BaseState";
import GlobalDispatcher from "Engine/events/GlobalDispatcher";
import EntryPoint from 'Engine/EntryPoint';
import ServerManager from '../server/ServerManager';

export default class RequestRespinRollingState extends BaseState {
    constructor(stateData) {
        super(stateData);
    }

    addListeners() {
        super.addListeners();
        // GlobalDispatcher.add('model:getServerData', this.onGetServerData, this);
    }

    onGetServerData(data) {
        if (data.params === 'respin') {
            this.complete();
        }
    }

    async start() {
        super.start();
        this.startTime = Date.now();
        OPWrapperService.ControllerStatistic.bet = EntryPoint.GameModel.respinCost[EntryPoint.GameModel.respinReelIndex];
        OPWrapperService.realityCheck.blockedRealityCheck = true;
        GlobalDispatcher.dispatch('respinRollingState:start');

        await ServerManager.onStartRespin()
        this.complete();
    }
}
