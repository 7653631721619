import BaseState from "Engine/base/states/BaseState";
import GlobalDispatcher from "Engine/events/GlobalDispatcher";
import GameModel from "../model/GameModel";

export default class ShowExpandedWinState extends BaseState {

    constructor(stateData) {
        super(stateData);
    }

    addListeners() {
        super.addListeners();
        GlobalDispatcher.add('allExpandedSymbolLinesShowed', this.onAllExpandedSymbolLinesShowed, this);
    }

    onAllExpandedSymbolLinesShowed() {
        this.complete();
    }
}