import UiAdapter from 'Engine/base/ui/uiAdapter/BaseUiAdapter';
import EntryPoint from 'Engine/EntryPoint';
import eBaseStateTypes from 'Engine/enums/eBaseStateTypes';
import GlobalDispatcher from 'Engine/events/GlobalDispatcher';

export default class UiAdapterExtended extends UiAdapter {
  constructor(...props) {
    super(...props);
  }

  addListeners() {
    super.addListeners();
    GlobalDispatcher.add('stateEnd:freeSpinsAddState', this.showUi, this);
    GlobalDispatcher.add('stateEnd:freeSpinsOutState', this.showUi, this);
  }

  onExpandOrWin = () => {
    this.ControllerUi.showStopButton();
    this.ControllerUi.toggleStopButton(true);

    this.ControllerUi.toggleMenuButton(false);
    this.ControllerUi.toggleMaxBetButton(false);
    this.ControllerUi.toggleBetSelectors(false);
    this.ControllerUi.toggleInfoButton(false);
    this.ControllerUi.toggleAutoPlayButton(false);

    this.ControllerUi.setWin(EntryPoint.GameModel.totalWin);
    if (!EntryPoint.GameModel.freeSpinsInActiveState) {
      this.ControllerUi.setBalance(EntryPoint.GameModel.balance)
    }
  }

  extraStateHandlers = {
    'requestRespinRollingState': () => {
      this.stateHandlers[eBaseStateTypes.EBST_REQUEST_ROLLING]();
      this.ControllerUi.toggleStopButton(false);
      this.ControllerUi.setBalance(EntryPoint.GameModel.balance);
    },
    'minimalRespinRollingState': this.stateHandlers[eBaseStateTypes.EBST_MINIMAL_ROLLING],
    'stopRespinRollingState': this.stateHandlers[eBaseStateTypes.EBST_STOP_ROLLING],
    'expandSymbolsState': this.onExpandOrWin,
    'showWinningsState': this.onExpandOrWin,
    'showExpandedWinState': this.onExpandOrWin,
    'freeSpinsAddState': this.stateHandlers[eBaseStateTypes.EBST_FREE_SPINS_IN],
    'freeSpinsOutState': this.stateHandlers[eBaseStateTypes.EBST_FREE_SPINS_OUT],
    'requestRollingState': () => {
      this.ControllerUi.toggleStopButton(true);
    },
    default: () => {
    },
  };
}
