import FlashLib from "flashlib_onlyplay";
import EntryPoint from "Engine/EntryPoint";

export default class SymbolsFrames extends FlashLib.MovieClip {
    constructor(data, displayData) {
        super(data, displayData);

        this._id = 1;
        this.config = EntryPoint.configData.symbols;
        this.incativeTintColor = 0x444444;
    }

    setBlur(value) {
        // let frame = value ? this.id + this.config.count : this.id;
        // if (EntryPoint.GameModel.isFreeSpinsMode && this.id === EntryPoint.GameModel.freeSpins.specialSymbol) {
        //     frame = value ? 2 : 21;
        // }
        // this.goToFrame(frame);
    }

    setActive(value) {
        if (this.children.length > 0) {
            let image = this.getChildAt(0);
            image.tint = value ? 16777215 : this.incativeTintColor;
        }
    }

    get id() {
        return this._id;
    }

    set id(value) {
        this._id = value;
        let frame = this.id;
        if (EntryPoint.GameModel.isFreeSpinsMode && this.id === EntryPoint.GameModel.freeSpins.specialSymbol) {
            frame = 2;
        }
        this.goToFrame(frame);
    }
}
