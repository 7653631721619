import winController from './winController';
import { gsap } from 'gsap';

export default class EpicWinController extends winController {
  constructor(...arg) {
    super(...arg);
    this._moveCounterDuration = 2;
    this._moveCounterValue = 30;
  }

  onStart() {
    this.coinParticles3.visible = true;
    this.coinParticles3.start();
    // gsap.to(this.winAmountContainer, { y: `+=${this._moveCounterValue}`, duration: this._moveCounterDuration, ease: 'power4.out' });
  }

  onSkip() {
    this.onStart();
    gsap.killTweensOf(this.winAmountContainer);
    this.winAmountContainer.y += this._moveCounterValue;
  }

  cleanup() {
    super.cleanup();
    if (this.coinParticles3) {
      this.coinParticles3.stop();
      this.coinParticles3.visible = false;
    }
  }
}
