export default class BaseInterface {
  constructor(controller) {
    this._Controller = controller;
    //todo: think about proxy private field
    this.handleModelChange = this.handleModelChange.bind(this);
  }

  show() {
    this._Controller.show();
  }

  hide() {
    this._Controller.hide();
  }

  on(event, callback) {
    this._Controller.on(event, callback);
  }

  setOpacity(value) {
    this._Controller.setOpacity(value);
  }

  showOverlay(id) {
    return this._Controller.showOverlay(id);
  }

  hideOverlay(id) {
    this._Controller.hideOverlay(id);
  }

  remove() {
    this._Controller.remove();
  }

  handleModelChange() {}

  get events() {
    return this._Controller.events;
  }

  get controllerTypes() {
    return this._Controller.controllerTypes;
  }

  get controllers() {
    return this._Controller.controllers;
  }
}
