import FlashLib from 'flashlib_onlyplay';
import GlobalDispatcher from "Engine/events/GlobalDispatcher";
import MoneyFormat from "Engine/base/moneyFormat/MoneyFormat";
import SoundManager from "Engine/soundManager/SoundManager";
import { shadowText } from 'Engine/utils/fontStyles';
import { makeHitArea } from 'Engine/utils/makeHitArea';
import EntryPoint from '../../EntryPoint';
import eEventTypes from '../../enums/eBaseEventTypes';

export default class BaseCoinValueContainer extends FlashLib.MovieClip {
    constructor(data, displayItemData) {
        super(data, displayItemData);

        this.incativeTintColor = 0xCCCCCC;

        this.valuesRange = [];
        this.valueText = null;
        this.currentValue = 0;

        this._enabled = false;
        this._showCurrency = false;

        this.init();
        this.addActions();
    }

    get showCurrency() {
        return this._showCurrency;
    }

    set showCurrency(value) {
        this._showCurrency = value;
    }

    setRange(valuesRange) {
        this.valuesRange = valuesRange;
    }

    init() {
        this.decreaseButton = this.getChildByName('decreaseButton');
        this.increaseButton = this.getChildByName('increaseButton');
        makeHitArea(this.decreaseButton);
        makeHitArea(this.increaseButton);

        this.titleText = this.getChildByName('ui_bet_label');
        this.valueText = this.getChildByName('ui_bet_value');

        this.disableButtons();
    }

    addActions() {
        this.decreaseButton.on('pointertap', this.onClickDecreaseButton, this);
        this.increaseButton.on('pointertap', this.onClickIncreaseButton, this);
    }

    onClickDecreaseButton(data) {
        GlobalDispatcher.dispatch(eEventTypes.EBET_UI_CHANGE_BET, EntryPoint.GameModel.betIndex - 1);
    }

    onClickIncreaseButton(data) {
        GlobalDispatcher.dispatch(eEventTypes.EBET_UI_CHANGE_BET, EntryPoint.GameModel.betIndex + 1);
    }


    setValue(value) {
        this.currentValue = value;
        this.valueText.text = MoneyFormat.format(this.currentValue, this.showCurrency, false);
        this.valueText.fitSize();
        this.valueText.correctPosition('center', 'top');
        this.checkButtonsToEnable();
    }

    checkButtonsToEnable() {
        this.decreaseButton.enabled = this.valuesRange.indexOf(this.currentValue) > 0;
        this.increaseButton.enabled = this.valuesRange.indexOf(this.currentValue) < this.valuesRange.length - 1;
    }

    disableButtons() {
        this.decreaseButton.enabled = false;
        this.increaseButton.enabled = false;
    }

    enableButtons() {
        this.checkButtonsToEnable();
    }

    get enabled() {
        return this._enabled;
    }

    set enabled(value) {
        this._enabled = value;
        this.interactive = value;
        /*this.children.forEach((child)=>{
            child.tint = this._enabled ? 16777215 : this.incativeTintColor;
        });*/

        if(!this._enabled) {
            this.disableButtons();
        } else {
            this.enableButtons();
        }
    }
}
