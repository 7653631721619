const defaultConfig = {
  x0min: 0, // px
  x0max: 0, // px
  y0min: 0, // px
  y0max: 380, // px
  scaleStart: 1,
  scaleEnd: 0,
  angle0From: 0,
  angle0To: Math.PI * 2,
  angleVelocityMin: 0.0005,
  angleVelocityMax: 0.0006,
  v0min: 0.05, // px / milliseconds
  v0max: 0.1, // px / milliseconds
  gravityY: 0,
  mass: 0.001, // ~kg
  drag: 1 / 500,
  lifetimeMin: 8000, // milliseconds
  lifetimeMax: 10000, // milliseconds
  count: 36,
  box: {
    x: 0,
    y: 0,
    height: 1000,
  },
};

const smallParticlesConfig = {
  ...defaultConfig,
  scaleStart: 0.4,
  count: 270,
};

const bigParticlesConfig = {
  ...defaultConfig,
  count: 30,
};

const coinParticlesConfig = {
  ...defaultConfig,
  scaleStart: 0.5,
  scaleEnd: 6,
  gravityY: 1,
  mass: 0.05,
  drag: 1 / 500,
  angle0From: -Math.PI / 180 * 120,
  angle0To: -Math.PI / 180 * 60,
  v0min: 2.15,
  v0max: 2.78,
  count: 60,
};

export default {
  defaultConfig,
  smallParticlesConfig,
  bigParticlesConfig,
  coinParticlesConfig,
}
