import BaseState from "Engine/base/states/BaseState";
import EntryPoint from "Engine/EntryPoint";

export default class MinimalRespinRollingState extends BaseState {
    constructor(stateData) {
        super(stateData);
    }

    start() {
        super.start();
        let delay = EntryPoint.GameSettings.quickSpin ? 500 : 2000;
        this.timeout = setTimeout(()=>{
            this.complete();
        }, delay);
    }

    onEnd() {
        super.onEnd();
        clearTimeout(this.timeout);
    }
}
