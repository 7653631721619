import BaseButton from '../libs/game-engine/src/base/ui/BaseButton';
import * as PIXI from "pixi.js";

export default class ButtonAnimated extends BaseButton {
  constructor(data, displayData) {
    super(data, displayData);

    this.init();
  }

  init() {
    this.animationContainer = this.getChildByName('animationContainer');
    let mask = new PIXI.Graphics();
    mask.beginFill(0x0, 0.01);
    mask.drawRect(0, 0, this.width, this.height);
    mask.endFill();
    this.addChild(mask);
    this.mask = mask;
  }

  set animation(animation) {
    this._animation = animation;
    this.animationContainer.addChild(this._animation);
  }
}
