import FlashLib from 'flashlib_onlyplay';
import GlobalDispatcher from 'Engine/events/GlobalDispatcher';
import EntryPoint from 'Engine/EntryPoint';
import { initUi } from '../ui/initUi';

export default class StartScreenContainer extends FlashLib.MovieClip {
  constructor(data, displayData) {
    super(data, displayData);

    this.onKeyDown = this.onKeyDown.bind(this);
    this.hide = this.hide.bind(this);
    this.init();
    this.addListeners();
  }

  addListeners() {
    GlobalDispatcher.addOnce('startScreen:show', this.show.bind(this));
    this.addEventListener('click', this.hide);
    this.addEventListener('touchend', this.hide);
    this.addEventListener('keydown', this.hide);
  }

  init() {
    this.content = this.getChildByName('startScreenContent');
    this.visible = false;
    this.eventMode = 'static';
    this.cursor = 'pointer';
  }

  onKeyDown(event) {
    if (event.keyCode === 32) {
      event.preventDefault();
      event.stopPropagation();
      this.hide();
    }
  }

  show() {
    if (!EntryPoint.GameSettings.introScreen) return;
    this.visible = true;
    this.content.startAnimation();
  }

  hide() {
    this.content.hide();
    this.visible = false;
    document.removeEventListener('keydown', this.onKeyDown);
    window.OPWrapperService.freeBetsController.show();
    GlobalDispatcher.dispatch('startScreen:hide');
    requestAnimationFrame(() => {
      initUi();
    });
  }
}
