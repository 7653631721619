import BaseController from './basePlayerController';

export default class ControllerMenu extends BaseController {
  constructor({ container, ...props }) {
    super({ ...props });
    this._eButtonsTypes = {
      EBT_BURGER: 'menu_button',
    };
    this._eEventTypes =  {
      BURGER_CLICK: this.getEventName(this._eButtonsTypes.EBT_BURGER),
    };
    this._eElementsTypes = {
      EET_BURGER_BUTTON: this._eButtonsTypes.EBT_BURGER,
    };

    this.init(container);
  }

  _getMarkup() {
    return `<button id=${this.interactiveElementsIds[this._eElementsTypes.EET_BURGER_BUTTON]} ${this._container.dataset.orientation === 'landscape' ? 'data-color="transparent"' : ''}>
            <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_3035_11600)">
                <path d="M8 18.6667H56" stroke="white" stroke-width="4.5" stroke-linecap="round" />
                <path d="M8 32H56" stroke="white" stroke-width="4.5" stroke-linecap="round" />
                <path d="M8 45.3333H56" stroke="white" stroke-width="4.5" stroke-linecap="round" />
              </g>
              <defs>
                <clipPath id="clip0_3035_11600">
                  <rect width="64" height="64" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </button>`
  }
}
