import 'pixi-spine';
import * as PIXI from 'pixi.js';
import './platforms/EveryMatrix/Manager';

window.PIXI = PIXI;
require('pixi-spine');

import './utils/spineExtantion';
import 'Engine/utils/flashlibExtantion';
import '../assets/scss/styles.scss'
import FlashLib from 'flashlib_onlyplay';
import './Imports'
import BaseGame from 'Engine/BaseGame';
import StatesManager from 'Engine/base/states/StatesManager';
import GameModel from './model/GameModel';
import { StatesConfig } from './states/StatesConfig'
import PreloaderConfig from './preloader/preloaderConfig';
import GlobalDispatcher from 'Engine/events/GlobalDispatcher';
import EntryPoint from 'Engine/EntryPoint';
import PreloaderController from 'Engine/base/preloader/BasePreloaderController';
import ControllerTextField from './textField/ControllerTextField';
import SoundManager from 'Engine/soundManager/SoundManager';
import OPWrapperService from 'WrapperService/index';
import wrapperConfig from './wrapperConfig';
import './gamePreloader/preloader';
import BaseGameSettings from 'Engine/base/settings/BaseGameSettings';
import AutoPlaySettings from 'Engine/base/settings/AutoPlaySettings';
import UrlSettingsManager from './settings/UrlSettingsManager';

new OPWrapperService(wrapperConfig);

export default new class Game extends BaseGame {

  constructor() {
    EntryPoint.compiled = true;
    super();

    EntryPoint.Game = this;
    //PIXI.settings.GC_MODE = PIXI.GC_MODES.MANUAL;
  }

  addListeners() {
    super.addListeners();
    GlobalDispatcher.add('preloaderController:progress', this.onPreloaderProgress, this);
    GlobalDispatcher.add('showQuitWindow', () => {
      SoundManager.onBlur();
      SoundManager._blurMutedState = {};
      this.main.destroy();
    });
  }

  initStartClasses() {
    UrlSettingsManager.init();
    GameModel.init();
    // GameSettings.init();
    (new BaseGameSettings).init();
    (new AutoPlaySettings).init();
    StatesManager.setStatesConfig(StatesConfig);

    FlashLib.TextField = ControllerTextField;

    GlobalDispatcher.add('ui:spinClick', StatesManager.goToNextState, StatesManager);
    GlobalDispatcher.add('bonusGame:buy', () => StatesManager.goToState('requestBonusState'));
  }

  initStartParams() {
    /*this.gameSize = {
        width: 1920,
        height: 1080
    };
    this.baseSize = {
        width: window.innerWidth,
        height: window.innerHeight,
        offsetY: 0,
        ratio: 1920 / 1080
    };
    this.scaleData = {
        type: 'css',
        app: this.app,
        safeWidth: 1920,
        safeHeight: 1080,
        fullSize: true,
        gameSize: this.gameSize
    };*/
    /*let gWidth = EntryPoint.mobile ? 1080 : 1920;
    let gHeight = EntryPoint.mobile ? 1920 : 1080;
    let grWidth = EntryPoint.mobile ? 1080 : 1920;
    let grHeight = EntryPoint.mobile ? 2338 : 1080;*/

    let gWidth = EntryPoint.mobile ? 1080 : 2340;
    let gHeight = EntryPoint.mobile ? 2340 : 1080;
    let grWidth = EntryPoint.mobile ? 1080 : 2340;
    let grHeight = EntryPoint.mobile ? 2340 : 1080;

    this.gameSize = {
      desktop: {
        width: 2340,
        height: 1080,
        graphicsWidth: 2340,
        graphicsHeight: 1080,
        graphicsRatio: 2340 / 1080
      },
      mobileLandscape: {
        width: 2340,
        height: 1080,
        graphicsWidth: 2340,
        graphicsHeight: 1080,
        graphicsRatio: 2340 / 1080,
        offsetX: 420
      },
      mobilePortrait: {
        width: 1080,
        height: 2340,
        graphicsWidth: 1080,
        graphicsHeight: 2340,
        graphicsRatio: 1080 / 2340
      },

      /*width: gWidth,
      height: gHeight,
      graphicsWidth: grWidth,
      graphicsHeight: grHeight*/
    };
    this.baseSize = {
      width: window.innerWidth,
      height: window.innerHeight,
      offsetY: 0,
    };
    this.scaleData = {
      app: this.app,
      gameSize: this.gameSize
    };
  }

  createPreloader() {
    this.preloaderController = new PreloaderController(PreloaderConfig);
    this.preloaderController.on('gameLoadingComplete', this.onLoadingComplete, this);
  }

  onPreloaderProgress(event) {
    window.OPPreloader.setProgress(event.params);
  }

  hidePreloader() {
    window.OPPreloader.hide({ withFade: true });
  }

  constructGame() {
    // EntryPoint.configData = PIXI.Loader.shared.resources['gameConfig'].data;
    // this.app.stop();
    // createScene();
    // this.hidePreloader();

    EntryPoint.configData = PIXI.Assets.get('gameConfig'); // PIXI.Loader.shared.resources['gameConfig'].data;
    this.app.stop();
    this.main = FlashLib.createItemFromLibrary('Main', 'GameFlashLib');
    this.app.stage.addChildAt(this.main, 0);

    this.app.renderer.prepare.upload(this.main).then(() => {
        this.app.start();
        window.OPWrapperService.ScaleManager.init(this.scaleData)
        GlobalDispatcher.dispatch('game:gameStarted');
        this.hidePreloader();
        this.linkToPixi();
      }
    );
    window.OPWrapperService.eventManager.add(
      window.OPWrapperService.eventManager.types.EET_SCALE_MANAGER_RESIZE,
      this.drawMask,
      this
    );
  }
  drawMask(data) {
    const x = data.isMobile && data.isPortrait ? 630 : 0;
    const y = data.isMobile && data.isPortrait ? -630 : 0;
    if (!this.gameMask) {
      this.gameMask = new PIXI.Graphics();
    } else {
      this.gameMask.clear();
    }
    this.gameMask.beginFill(0xFF3300);
    this.gameMask.drawRect(
      x,
      y,
      data.gameWidth,
      data.gameHeight
    );
    this.gameMask.endFill();
    this.app.stage.addChild(this.gameMask);
    this.main.mask = this.gameMask;
  }
}
