import FlashLib from 'flashlib_onlyplay';
import GlobalDispatcher from "Engine/events/GlobalDispatcher";
import EntryPoint from "Engine/EntryPoint";

export default class BaseSlotLinesContainer extends FlashLib.MovieClip {
    constructor(data, displayItemData) {
        super(data, displayItemData);

        this.isComplete = true;
        this.lines = [];
        this.timeout = null;

        this.init();
        this.addListeners();
        this.hideLines();
    }

    init() {
        let line = null;
        for (let i = 0; i < EntryPoint.configData.lines.count; i++) {
            line = this.getChildByName('Line_' + (i + 1));
            if (line) {
                line.id = i;
                this.lines.push(line);
            }
        }
    }

    addListeners() {
        GlobalDispatcher.add('winningsController:startShowWinnings', this.onStartShowWinnings, this);
        GlobalDispatcher.add('winningsController:allWinningsComplete', this.onAllWinningsComplete, this);
        GlobalDispatcher.add('winningsController:slowWin', this.onShowWin, this);
    }

    onStartShowWinnings() {
        this.isComplete = false;
    }

    onAllWinningsComplete() {
        this.isComplete = true;
    }

    onShowWin(event) {
        this.showWinLine(event.params);
    }

    hideLines() {
        this.lines.forEach((line) => {
            line.hide();
        });
    }

    showLines(count) {
        this.lines.forEach((line, index) => {
            if (index + 1 <= count) {
                line.show();
            } else {
                line.hide();
            }
        });
    }

    showWinLine(data) {
        this.hideLines();
        let winLine = this.lines[data.line - 1];
        if (winLine) {
            winLine.show(true);
        }
        GlobalDispatcher.dispatch('linesContainer:slowWinLine', data);
    }

    reset() {
        this.hideLines();
    }
}
