import { MixBlend, MixDirection, Utils } from 'pixi-spine';
import * as RUNTIME38 from '@pixi-spine/runtime-3.8';
import { AnimationState, AttachmentTimeline, DrawOrderTimeline, RotateTimeline } from '@pixi-spine/runtime-3.8';

RUNTIME38.AnimationState.prototype.applyMixingFrom = function (to, skeleton, blend) {
  let from = to.mixingFrom;
  if (from.mixingFrom != null) this.applyMixingFrom(from, skeleton, blend);

  let mix = 0;
  if (to.mixDuration == 0) { // Single frame mix to undo mixingFrom changes.
    mix = 1;
    if (blend == MixBlend.first) blend = MixBlend.setup;
  } else {
    mix = to.mixTime / to.mixDuration;
    if (mix > 1) mix = 1;
    if (blend != MixBlend.first) blend = from.mixBlend;
  }

  let events = mix < from.eventThreshold ? this.events : null;
  let attachments = mix < from.attachmentThreshold, drawOrder = mix < from.drawOrderThreshold;
  let animationLast = from.animationLast, animationTime = from.getAnimationTime(), applyTime = animationTime;
  let timelineCount = from.animation.timelines.length;
  let timelines = from.animation.timelines;
  let alphaHold = from.alpha * to.interruptAlpha, alphaMix = alphaHold * (1 - mix);

  if (from.reverse) {
    applyTime = from.animation.duration - applyTime;
  } else {
    if (mix < from.eventThreshold) events = this.events;
  }

  if (blend == MixBlend.add) {
    for (let i = 0; i < timelineCount; i++)
      timelines[i].apply(skeleton, animationLast, applyTime, events, alphaMix, blend, MixDirection.mixOut);
  } else {
    let timelineMode = from.timelineMode;
    let timelineHoldMix = from.timelineHoldMix;

    let firstFrame = from.timelinesRotation.length == 0;
    if (firstFrame) Utils.setArraySize(from.timelinesRotation, timelineCount << 1, null);
    let timelinesRotation = from.timelinesRotation;

    from.totalAlpha = 0;
    for (let i = 0; i < timelineCount; i++) {
      let timeline = timelines[i];
      let direction = MixDirection.mixOut;
      let timelineBlend;
      let alpha = 0;
      switch (timelineMode[i] & (AnimationState.NOT_LAST - 1)) {
        case AnimationState.SUBSEQUENT:
          timelineBlend = blend;
          if (!attachments && timeline instanceof AttachmentTimeline) {
            if ((timelineMode[i] & AnimationState.NOT_LAST) == AnimationState.NOT_LAST) continue;
            timelineBlend = MixBlend.setup;
          }
          if (!drawOrder && timeline instanceof DrawOrderTimeline) continue;
          alpha = alphaMix;
          break;
        case AnimationState.FIRST:
          timelineBlend = MixBlend.setup;
          alpha = alphaMix;
          break;
        case AnimationState.HOLD:
          timelineBlend = MixBlend.setup;
          alpha = alphaHold;
          break;
        default: {
          timelineBlend = MixBlend.setup;
          let holdMix = timelineHoldMix[i];
          alpha = alphaHold * Math.max(0, 1 - holdMix.mixTime / holdMix.mixDuration);
          break;
        }
      }
      from.totalAlpha += alpha;
      if (timeline instanceof RotateTimeline)
        this.applyRotateTimeline(timeline, skeleton, applyTime, alpha, timelineBlend, timelinesRotation, i << 1, firstFrame);
      else {
        // This fixes the WebKit 602 specific issue described at http://esotericsoftware.com/forum/iOS-10-disappearing-graphics-10109
        Utils.webkit602BugfixHelper(alpha, blend);
        if (timelineBlend == MixBlend.setup) {
          if (timeline instanceof AttachmentTimeline) {
            if (attachments || (timelineMode[i] & AnimationState.NOT_LAST) == AnimationState.NOT_LAST) direction = MixDirection.mixIn;
          } else if (timeline instanceof DrawOrderTimeline) {
            if (drawOrder) direction = MixDirection.mixIn;
          }
        }
        timeline.apply(skeleton, animationLast, applyTime, events, alpha, timelineBlend, direction);
      }
    }
  }

  if (to.mixDuration > 0) this.queueEvents(from, animationTime);
  this.events.length = 0;
  from.nextAnimationLast = animationTime;
  from.nextTrackLast = from.trackTime;

  return mix;
};

RUNTIME38.AnimationState.prototype.apply = function (skeleton) {
  if (skeleton == null) throw new Error('skeleton cannot be null.');
  if (this.animationsChanged) this._animationsChanged();

  let events = this.events;
  let tracks = this.tracks;
  let applied = false;

  for (let i = 0, n = tracks.length; i < n; i++) {
    let current = tracks[i];
    if (current == null || current.delay > 0) continue;
    applied = true;
    let blend = i == 0 ? MixBlend.first : current.mixBlend;

    // Apply mixing from entries first.
    let mix = current.alpha;
    if (current.mixingFrom != null)
      mix *= this.applyMixingFrom(current, skeleton, blend);
    else if (current.trackTime >= current.trackEnd && current.next == null)
      mix = 0;

    // Apply current entry.
    let animationLast = current.animationLast, animationTime = current.getAnimationTime(), applyTime = animationTime;
    let applyEvents = events;
    if (current.reverse) {
      applyTime = current.animation.duration - applyTime;
      applyEvents = null;
    }
    let timelineCount = current.animation.timelines.length;
    let timelines = current.animation.timelines;
    if ((i == 0 && mix == 1) || blend == MixBlend.add) {
      for (let ii = 0; ii < timelineCount; ii++) {
        // Fixes issue #302 on IOS9 where mix, blend sometimes became undefined and caused assets
        // to sometimes stop rendering when using color correction, as their RGBA values become NaN.
        // (https://github.com/pixijs/pixi-spine/issues/302)
        Utils.webkit602BugfixHelper(mix, blend);
        timelines[ii].apply(skeleton, animationLast, applyTime, applyEvents, mix, blend, MixDirection.mixIn);
      }
    } else {
      let timelineMode = current.timelineMode;

      let firstFrame = current.timelinesRotation.length == 0;
      if (firstFrame) Utils.setArraySize(current.timelinesRotation, timelineCount << 1, null);
      let timelinesRotation = current.timelinesRotation;

      for (let ii = 0; ii < timelineCount; ii++) {
        let timeline = timelines[ii];
        let timelineBlend = (timelineMode[ii] & (AnimationState.NOT_LAST - 1)) == AnimationState.SUBSEQUENT ? blend : MixBlend.setup;
        if (timeline instanceof RotateTimeline) {
          this.applyRotateTimeline(timeline, skeleton, applyTime, mix, timelineBlend, timelinesRotation, ii << 1, firstFrame);
        } else {
          // This fixes the WebKit 602 specific issue described at http://esotericsoftware.com/forum/iOS-10-disappearing-graphics-10109
          Utils.webkit602BugfixHelper(mix, blend);
          timeline.apply(skeleton, animationLast, applyTime, applyEvents, mix, timelineBlend, MixDirection.mixIn);
        }
      }
    }
    this.queueEvents(current, animationTime);
    events.length = 0;
    current.nextAnimationLast = animationTime;
    current.nextTrackLast = current.trackTime;
  }

  this.queue.drain();
  return applied;
};
