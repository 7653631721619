import FlashLib from 'flashlib_onlyplay';
import EntryPoint from '../libs/game-engine/src/EntryPoint';

export default class SakuraMobileContainer extends FlashLib.MovieClip {
  constructor(data, displayData) {
    super(data, displayData);

    this.track = null;
    this.isHiding = false;
    this.visible = false;
    this.addListeners()
  }

  onGameResized(data) {
    if (EntryPoint.mobile && data.orientation === 'V') {
      this.goToFrame(1)
    } else {
      this.goToFrame(2)
    }
  }

  addListeners() {
    window.OPWrapperService.eventManager.add(
      window.OPWrapperService.eventManager.types.EET_SCALE_MANAGER_RESIZE,
      this.onGameResized,
      this
    );
  }

  hide() {
    if (EntryPoint.mobile) {
      this.visible = false;
      return;
    }

    if (this.isHiding) return;

    this.isHiding = true;

    this.track = this.animation.state.setAnimation(0, 'start', false);
    this.track.reverse = true;
  }

  show() {
    this.visible = true;
  }
}
