export const eAnimationNames = {
  EAN_ANTISIPATOR: 'animation',
  EAN_COIN_FLIP: 'coin'
};

export const eBigWinAnimationNames = {
  IDLE: 'big_win_idle',
  START: 'big_win_start'
};

export const eMegaWinAnimationNames = {
  IDLE: 'mega_win_idle',
  START: 'mega_win_start'
};
export const eEpicWinAnimationNames = {
  IDLE: 'epic_win_idle',
  START: 'epic_win_start'
};
