import BaseFreeBetsPopup from './baseFreeBetsPopup';
import LocalizationManager from '../../i18n/LocalizationManager';

export default class EndFreeBetsPopup extends BaseFreeBetsPopup {
  constructor(container) {
    super(container);
    this.totalWinAmountElement = this.view.querySelector('.freeBetsPopup__amount')
  }

  show(data) {
    super.show();
      this.totalWinAmountElement.textContent = data.totalWin + ' ' + data.currency;
  }

  createMarkup() {
    return `
    <div id="endFreeBetPopup" class="blocker">
      <div class="freeBetsContent freeBetsContent_smallPadding">
        <div class="freeBets__title">${LocalizationManager.getLocalizedText(this.defaultLocalizationPath + '.endFreeBetPopupTotalWin')}</div>
        <div class="freeBetsPopup__amount">1200000000000 uah</div>
        <div class="freeBets__title">${LocalizationManager.getLocalizedText(this.defaultLocalizationPath + '.endFreeBetPopupTitle')}</div>
        <button class="freeBets__button">
          <div class="freeBets__buttonText">${LocalizationManager.getLocalizedText(this.defaultLocalizationPath + '.ok')}</div>
        </button>
      </div>
    </div>
    `
  }
}
