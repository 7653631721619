export default class Branding {
  constructor(initParams) {
    this._initParams = initParams;
    this._originalTitle = document.title;
  }

  setBrandedTitle(titlesConfig) {
    let title = this.getConfigValue(titlesConfig);
    title = title ? ` ${title}` : '';
    document.title = this._originalTitle.concat(title);
  }

  getConfigValue(config) {
    const { launchedForPid, launchedForCasinoId } = this._initParams;

    let value;
    try {
      value = config[launchedForPid][launchedForCasinoId] || config[launchedForPid]._default;
      if (value === undefined) throw new Error(`Can't find config value for launchedForPid: ${launchedForPid}, launchedForCasinoId: ${launchedForCasinoId}`);
    } catch (e) {
      console.error(e);
    }

    return value;
  }
}
