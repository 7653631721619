import { receiveMessageTypes } from './constants';

export default new class TestMessageEvents {
  constructor() {
    window.TestMessageEvents = this;

    for (let type in receiveMessageTypes) {
      this[receiveMessageTypes[type]] = (data) => {
        const message = {
          name: receiveMessageTypes[type],
          sender: 'provider',
          data
        }
        window.postMessage(message, '*');
      }
    }
  }

  init() {
    window.addEventListener('message', (event) => console.warn(event.data));
  }
}
