import BaseState from "Engine/base/states/BaseState";
import GlobalDispatcher from "Engine/events/GlobalDispatcher";
import EntryPoint from "Engine/EntryPoint";

export default class ShowWinningsState extends BaseState {
    constructor(stateData) {
        super(stateData);
    }

    addListeners() {
        super.addListeners();
        GlobalDispatcher.add('winningsController:allWinningsComplete', this.onAllWinningsComplete, this);
        // GlobalDispatcher.add('bigWinContainer:stopState', this.tryStop, this);
    }

    start() {
        super.start();
        if ((!EntryPoint.AutoPlaySettings.active && !EntryPoint.AutoPlaySettings.paused) && !EntryPoint.GameModel.isFreeSpinsMode && !EntryPoint.GameModel.isBigWin) {
            this.complete();
        }
    }
    //
    // tryStop() {
    //     if (this.active) {
    //         if (this.canStop) {
    //             this.stop();
    //         } else {
    //             this.canStop = true;
    //         }
    //     }
    // }

    onAllWinningsComplete() {
        if (this.active) {
            this.complete();
        }
    }
}
