import eControllerTypes from './eControllerTypes';
import eTournamentControllerTypes from './tournaments/eTournamentControllerTypes';
import ControllerSpinner from '../common/ControllerSpinner';
import ControllerTournamentsHeader from './tournaments/ControllerHeader';
import ControllerBonusDetails from './tournaments/ControllerBonusDetails';
import ControllerModalBonusDetails from './tournaments/ControllerModalTournamentDetails';
import ControllerBanner from './tournaments/ControllerBanner';
import ControllerCompanyLogo from './tournaments/ControllerCompanyLogo';
import ControllerTournamentsTabs from './tournaments/ControllerTabs';
import BaseTournamentController from './tournaments/baseTournamentController';
import ControllerTournamentsArchiveList from './tournaments/ControllerTournamentsArchiveList';
import { OP_WRAPPER_LOCALIZATION_PREFIX } from '../../../utils/constants';
import { disableDoubleTapToZoom } from '../../../utils/disableDoubleTapToZoom';
import eTournamentStatus from './tournaments/eTournamentStatus';
import { formatOrientation } from '../../../utils/orientation';
import ScaleManager from '../../../scaleManager/ScaleManager';

export default class ControllerTournaments extends BaseTournamentController {
  constructor({ container, ...props }) {
    super({ type: eControllerTypes.ECT_TOURNAMENTS, ...props });
    this._defaultSizes = {
      H: {
        width: 1920,
        height: 1080,
      },
      V: {
        width: 918,
        height: 1920
      }
    };

    this._eElementsTypes = {
      EET_ROOT: 'root',
      EET_LOADING_LABEL: 'loading_label',
      EET_TITLE_1: 'title_1',
      EET_TITLE_2: 'title_2',
      EET_CONTAINER_SPINNER: this.controllerTypes.ETCT_SPINNER,
      EET_CONTAINER_HEADER: this.controllerTypes.ETCT_HEADER,
      EET_CONTAINER_BANNER: this.controllerTypes.ETCT_BANNER,
      EET_CONTAINER_DETAILS: this.controllerTypes.ETCT_BONUS_DETAILS,
      EET_CONTAINER_MODAL_DETAILS: this.controllerTypes.ETCT_MODAL_BONUS_DETAILS,
      EET_CONTAINER_COMPANY_LOGO: this.controllerTypes.ETCT_COMPANY_LOGO,
      EET_CONTAINER_TABS: this.controllerTypes.ETCT_TABS,
      EET_CONTAINER_ARCHIVE: this.controllerTypes.ETCT_ARCHIVE_LIST,
    };
    this._eControllersClasses = {
      [this.controllerTypes.ETCT_SPINNER]: ControllerSpinner,
      [this.controllerTypes.ETCT_HEADER]: ControllerTournamentsHeader,
      [this.controllerTypes.ETCT_BANNER]: ControllerBanner,
      [this.controllerTypes.ETCT_BONUS_DETAILS]: ControllerBonusDetails,
      [this.controllerTypes.ETCT_MODAL_BONUS_DETAILS]: ControllerModalBonusDetails,
      [this.controllerTypes.ETCT_COMPANY_LOGO]: ControllerCompanyLogo,
      [this.controllerTypes.ETCT_TABS]: ControllerTournamentsTabs,
      [this.controllerTypes.ETCT_ARCHIVE_LIST]: ControllerTournamentsArchiveList,
    };
    this._localizations = {
      [this._eElementsTypes.EET_LOADING_LABEL]: `${OP_WRAPPER_LOCALIZATION_PREFIX}.tournaments.common.loading`,
    };

    this._isOverlayToggler = true;
    this._isSpinBlocker = true;
    this._tournamentToOpen = null;
    this._currentTournamentId = null;
    this._localizationTournamentId = null;
    this._activePromises = new Set();

    this.init(container);
    this.hide();
  }

  _afterInit() {
    super._afterInit();
    this.root = this.interactiveElements[this._eElementsTypes.EET_ROOT];
    disableDoubleTapToZoom(this.root);

    window.OPWrapperService.localizations.addLocalizationChangedCallback(this._tryToUpdateTournamentLocalizations.bind(this));
  }

  async show() {
    if (!this.isHidden) return;

    super.show();
    this._showSpinner();

    const infoPromise = window.OPWrapperService.serverManager.requestTournamentsInfo();
    this._activePromises.add(infoPromise);
    await infoPromise.then(() => this._activePromises.delete(infoPromise));

    this._tryToHideSpinner();
  }

  cleanup() {
    super.cleanup();
    this._tournamentToOpen = null;
  }

  handleArchiveBackClick() {
    if (this._data.active.length) {
      this.openCurrentTournament();
    }
  }

  openCurrentTournament() {
    this._tournamentToOpen = null;
    this._onDataUpdated();
  }

  openArchiveTournament(tournamentId) {
    this._tournamentToOpen = { type: eTournamentStatus.ETS_ARCHIVED, id: tournamentId };
    this._onDataUpdated();
  }

  openFutureTournament(tournamentId) {
    this._tournamentToOpen = { type: eTournamentStatus.ETS_FUTURE, id: tournamentId };
    this._onDataUpdated();
  }

  _addExtraControllersListeners() {
    const controllerHeader = this.controllers[this.controllerTypes.ETCT_HEADER];
    const controllerArchiveList = this.controllers[this.controllerTypes.ETCT_ARCHIVE_LIST];
    const controllerBonusDetails = this.controllers[this.controllerTypes.ETCT_BONUS_DETAILS];
    const controllerModalBonusDetails = this.controllers[this.controllerTypes.ETCT_MODAL_BONUS_DETAILS];
    const events = this.events[this.controllerType];

    controllerHeader.on(events[this.controllerTypes.ETCT_HEADER].CLOSE_CLICK, () => this.hide());
    controllerHeader.on(events[this.controllerTypes.ETCT_HEADER].ARCHIVE_CLICK, () => controllerArchiveList.show());
    controllerArchiveList.on(events[this.controllerTypes.ETCT_ARCHIVE_LIST].DETAILS_CLICK, (id) => this.openArchiveTournament(id));
    controllerArchiveList.on(events[this.controllerTypes.ETCT_ARCHIVE_LIST].BACK_CLICK, () => this.handleArchiveBackClick());
    controllerBonusDetails.on(events[this.controllerTypes.ETCT_BONUS_DETAILS].DETAILS_CLICK, () => controllerModalBonusDetails.show());
  }

  _onDataUpdated() {
    super._onDataUpdated();
    let tournamentDetails;
    let status;

    if (this._tournamentToOpen) {
      status = this._tournamentToOpen.type;
      tournamentDetails = this._data[status].find(tournament => tournament.uniqueId === this._tournamentToOpen.id);
    } else if (this._data.active.length) {
      status = eTournamentStatus.ETS_ACTIVE;
      tournamentDetails = this._data.active[this._data.active.length - 1];
    } else if (this._data.archived.length) {
      status = eTournamentStatus.ETS_ARCHIVED;
      tournamentDetails = this._data.archived[this._data.archived.length - 1];
    }

    const tournamentData = tournamentDetails ? this._data.data[tournamentDetails.uniqueId] : undefined;
    if (!tournamentData) {
      this._currentTournamentId = null;
      return this.hide();
    }

    if (this._currentTournamentId !== tournamentDetails.uniqueId) {
      this._currentTournamentId = tournamentDetails.uniqueId;
      this._localizationTournamentId = tournamentDetails.rulesLocalizationKey || this._currentTournamentId;
      this._requestTournamentLocalizations(this._localizationTournamentId);
    }

    this._updateTournamentTitle(tournamentDetails ? tournamentDetails.title : '');
    Object.values(this.controllers).forEach(controller => {
      if ('updateData' in controller)
        controller.updateData({
          details: tournamentDetails,
          data: tournamentData,
          archived: this._data.archived,
          userId: this._data.userId,
          tournamentId: tournamentDetails.uniqueId,
          status,
          localizationTournamentId: this._localizationTournamentId,
        })
    });
  }

  _updateTournamentTitle(title) {
    const titleTypes = [this._eElementsTypes.EET_TITLE_1, this._eElementsTypes.EET_TITLE_2];
    titleTypes.forEach(type => this.set(type, title));
  }

  _showSpinner() {
    this.controllers[this.controllerTypes.ETCT_SPINNER].show();
  }

  _hideSpinner() {
    this.controllers[this.controllerTypes.ETCT_SPINNER].hide()
  }

  _tryToHideSpinner() {
    if (!this._activePromises.size) this._hideSpinner();
  }

  _tryToUpdateTournamentLocalizations() {
    if (!this._localizationTournamentId) return;
    this._requestTournamentLocalizations(this._localizationTournamentId);
  }

  async _requestTournamentLocalizations(tournamentId) {
    this._showSpinner();
    const localizationPromise = window.OPWrapperService.localizations.tryLoadTournamentLocalizations(tournamentId);
    this._activePromises.add(localizationPromise);
    await localizationPromise.then(() => this._activePromises.delete(localizationPromise));
    this._tryToHideSpinner();
  }

  get controllerTypes() {
    return eTournamentControllerTypes;
  }

  get dataSelector() {
    return (data) => data;
  }

  set scaleData({ scaleData, rootScale }) {
    this._scaleData = scaleData;
    const orientation = this._scaleData.orientation;
    const scaleBeforeScaleManagerInitialized = Math.min(this._scaleData.innerWidth / this._defaultSizes[orientation].width, this._scaleData.innerHeight / this._defaultSizes[orientation].height);
    const uiScale = this._scaleData.scale || scaleBeforeScaleManagerInitialized;
    for (const key in this.controllers) {
      const controller = this.controllers[key];
      controller.scaleData = { scaleData: this._scaleData, rootScale: uiScale };
    }

    const currentSizes = ScaleManager.currentSizes ? ScaleManager.currentSizes : this._defaultSizes[orientation];
    this.root.setAttribute('data-orientation', formatOrientation(orientation));
    ScaleManager.makeScalingHTMLElement({
      HTMLElement: this.root,
      relativeScale: uiScale,
      defaultWidth: Math.min(currentSizes.width, this._scaleData.innerWidth / uiScale),
      defaultHeight: Math.min(currentSizes.height, this._scaleData.innerHeight / uiScale)
    });
  }

  _getMarkup() {
    return `<div id=${this.interactiveElementsIds[this._eElementsTypes.EET_ROOT]} class="system-ui-container__tournaments">
      <div id=${this.interactiveElementsIds[this._eElementsTypes.EET_CONTAINER_MODAL_DETAILS]}></div>
      
      <div class="system-ui-container__tournaments_main">
        <div id=${this.interactiveElementsIds[this._eElementsTypes.EET_CONTAINER_HEADER]}>
        </div>
        
        <div id=${this.interactiveElementsIds[this._eElementsTypes.EET_CONTAINER_SPINNER]} class="system-ui-container__tournaments_main__preloader">
          <span id=${this.interactiveElementsIds[this._eElementsTypes.EET_LOADING_LABEL]}>${this._getLocalization(this._localizations[this._eElementsTypes.EET_LOADING_LABEL])}</span>
        </div>

        <div class="system-ui-container__tournaments_main__title">
          <span id=${this.interactiveElementsIds[this._eElementsTypes.EET_TITLE_1]}>
            Golden Gates
          </span>
        </div>

        <div class="system-ui-container__tournaments_main__body">

          <div class="system-ui-container__tournaments_main__body_description">
            <div class="system-ui-container__tournaments_main__body_description_title">
              <span id=${this.interactiveElementsIds[this._eElementsTypes.EET_TITLE_2]}>
                Golden Gates
              </span>
            </div>
            
            <div class="system-ui-container__tournaments_main__body_description_promo_block">
              <div id=${this.interactiveElementsIds[this._eElementsTypes.EET_CONTAINER_BANNER]}></div>
              <div id=${this.interactiveElementsIds[this._eElementsTypes.EET_CONTAINER_DETAILS]}></div>
              <div id=${this.interactiveElementsIds[this._eElementsTypes.EET_CONTAINER_COMPANY_LOGO]}></div>
            </div>
          </div>

          <div class="system-ui-container__tournaments_main__body_content">
            <div id=${this.interactiveElementsIds[this._eElementsTypes.EET_CONTAINER_TABS]}>
            </div>
          </div>
        </div>
      </div>
      
      <div id=${this.interactiveElementsIds[this._eElementsTypes.EET_CONTAINER_ARCHIVE]}>
      </div>
      
    </div>`
  }
}
