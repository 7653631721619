import BaseState from "Engine/base/states/BaseState";
import GlobalDispatcher from "Engine/events/GlobalDispatcher";
import EntryPoint from "Engine/EntryPoint";
// import SpinTimeManager from "Base/spinTime/SpinTimeManager";
import ServerManager from '../server/ServerManager';
import SpinTimeManager from 'Engine/base/spinTime/SpinTimeManager';

export default class RequestRollingState extends BaseState {
    constructor(stateData) {
        super(stateData);

        this.startTime = 0;
    }

    addListeners() {
        super.addListeners();
        // GlobalDispatcher.add('model:getServerData', this.onGetServerData, this);
    }

    onGetServerData(data) {
        if (data.params === 'spin' && this.active) {
            /*setTimeout(()=>{
                this.complete();
            }, 3000 - (Date.now() - this.startTime));*/
            console.log('onGetServerData');
            this.complete();
        }
    }

    async start() {
        super.start();
        SpinTimeManager.start();
        this.startTime = Date.now();
        EntryPoint.GameSettings.quickStop = false;
        if (!EntryPoint.GameModel.isFreeSpinsMode) {
            OPWrapperService.freeBetsController.decreaseFreeBets();
            OPWrapperService.ControllerStatistic.bet = EntryPoint.GameModel.bet;
        }
        OPWrapperService.realityCheck.blockedRealityCheck = true;
        GlobalDispatcher.dispatch('rollingState:start');
        await ServerManager.onStartSpin();
        this.complete();
    }

    onEnd() {
        super.onEnd();
    }
    stop() {
        EntryPoint.GameSettings.quickStop = true;
    }
}
