import { OP_WRAPPER_LOCALIZATION_PREFIX } from '../../utils/constants';

export default class BaseInfoItem {
  constructor(container) {
    this.view = document.createElement('span');
    this.view.classList.add('opInfoItem');
    container.insertAdjacentElement('afterbegin', this.view);
    this.baseLocalizePath = `${OP_WRAPPER_LOCALIZATION_PREFIX}.infoPanel`
  }

  onStatisticUpdate() {

  }
}
