import BaseNotification from '../baseNotification';
import eNotificationTypes from '../eNotificationTypes';
import { OP_WRAPPER_LOCALIZATION_PREFIX } from '../../../../utils/constants';

export default class SystemNotification extends BaseNotification {
  constructor(data) {
    super({ type: eNotificationTypes.ENT_SYSTEM, _id: data._id });
    this._data = {
      CODE: 0,
      AUTO_HIDE: true,
      ...data
    };
    this._lifeTime = this._data.AUTO_HIDE ? 5000 : Infinity;

    this._eElementsTypes = {
      EET_CONTAINER_ROOT: `container_root_${this._id}`,
      EET_MESSAGE: `message_${this._id}`,
    };

    this._localizations = {
      [this._eElementsTypes.EET_MESSAGE]: `${OP_WRAPPER_LOCALIZATION_PREFIX}.notifications.notification_${this._data.CODE}`,
    }

    this._onAnimationEnd = this._onAnimationEnd.bind(this);
  }

  tryKill(data) {
    if (this._data.CODE === data.CODE) {
      this.kill();
      return true;
    } else {
      return false;
    }
  }

  compareWithData(data) {
    return data.CODE === this._data.CODE;
  }

  init(...props) {
    super.init(...props);
    this.root = this.interactiveElements[this._eElementsTypes.EET_CONTAINER_ROOT];
    this.root.onanimationend = this._onAnimationEnd;
  }

  _onAnimationEnd() {
    if (this._complete) {
      super._onLifeTimeComplete();
    } else {
      this._trackLifeTime();
    }
  }

  _onLifeTimeComplete() {
    this.root.classList.add('notification_hide');
  }

  _getMarkup() {
    return `<div id=${this.interactiveElementsIds[this._eElementsTypes.EET_CONTAINER_ROOT]} class="OPWrapper_service__notifications__wrapper__container">
              <div class="OPWrapper_service__notifications__wrapper__notification">
                <div class="system-ui-container__notifications__system_notification">
                  <span id=${this.interactiveElementsIds[this._eElementsTypes.EET_MESSAGE]}>${this._getLocalization(this._localizations[this._eElementsTypes.EET_MESSAGE])}</span>
                </div>
              </div>
            </div>`
  }
}
