import { EMReceiveMessageTypes } from './constants';

export default new class TestEveryMatrixMessageEvents {
  constructor() {
    window.TestEveryMatrixMessageEvents = this;

    for (let type in EMReceiveMessageTypes) {
      this[EMReceiveMessageTypes[type]] = (data) => {
        const message = {
          name: EMReceiveMessageTypes[type],
          sender: 'emwindow',
          data
        }
        window.postMessage(message, '*');
      }
    }
  }

  init() {
    window.addEventListener('message', (event) => console.warn(event.data));
  }
}
