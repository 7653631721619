import BaseController from './basePlayerController';

export default class ControllerInfo extends BaseController {
  constructor({ container, ...props }) {
    super({ ...props });
    this._eButtonsTypes = {
      EBT_INFO: 'info_button',
    };
    this._eEventTypes =  {
      INFO_CLICK: this.getEventName(this._eButtonsTypes.EBT_INFO),
    };
    this._eElementsTypes = {
      EET_INFO_BUTTON: this._eButtonsTypes.EBT_INFO,
    };

    this.init(container);
  }

  _getMarkup() {
    return `<button id=${this.interactiveElementsIds[this._eElementsTypes.EET_INFO_BUTTON]} data-type="svg" data-role="info">
            <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_3035_11592)">
                <path
                    d="M31.9987 58.6666C46.6654 58.6666 58.6654 46.6666 58.6654 31.9999C58.6654 17.3333 46.6654 5.33325 31.9987 5.33325C17.332 5.33325 5.33203 17.3333 5.33203 31.9999C5.33203 46.6666 17.332 58.6666 31.9987 58.6666Z"
                    stroke="white" stroke-width="4.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M32 42.6667V29.3334" stroke="white" stroke-width="4.5" stroke-linecap="round"
                      stroke-linejoin="round" />
                <path d="M31.9844 21.3333H32.0083" stroke="white" stroke-width="4.5" stroke-linecap="round"
                      stroke-linejoin="round" />
              </g>
              <defs>
                <clipPath id="clip0_3035_11592">
                  <rect width="64" height="64" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </button>`
  }
}
