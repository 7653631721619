import EntryPoint from "Engine/EntryPoint";
import Utils from "Engine/Utils";
import MoneyFormat from 'Engine/base/moneyFormat/MoneyFormat';

export default new class UrlSettingsManager {
    constructor() {
        this.gameCode = 'book_of_bamboo';
        this.platform = 'desktop';
        this.casinoToken = '123';
        this.currency = 'EUR';
        this.language = 'en';
        this.playForFun = 'false';
        this.playerUid = undefined; //c382df8f-3aa0-4f00-a941-baa1c8d1f9fd
        this.autoSpin = true;
        this.maxBet = true;
        this.realityCheckTime = null;

        this.customer = null;
        this.extParams = null;
    }

    init() {
        EntryPoint.UrlSettingsManager = this;
        this.parseUrlSettings();
    }

    parseUrlSettings() {
        this.gameCode = Utils.getUrlParam('game_code', this.gameCode);
        this.platform = Utils.getUrlParam('platform', this.platform);
        this.casinoToken = Utils.getUrlParam('casino_token', this.casinoToken);
        this.currency = Utils.getUrlParam('currency', this.currency);
        this.language = Utils.getUrlParam('language', this.language);
        this.playForFun = Utils.getUrlParam('play_for_fun', this.playForFun) !== 'false';
        this.playerUid = Utils.getUrlParam('player_uid', this.playerUid);
        this.autoSpin = Utils.getUrlParam('auto_spin', this.autoSpin) !== 'false';
        this.maxBet = Utils.getUrlParam('max_bet', this.maxBet) !== 'false';
        this.customer = Utils.getUrlParam('customer', this.customer);
        this.realityCheckTime = +Utils.getUrlParam('reality_check_time', this.realityCheckTime);

        this.sid = Utils.getUrlParam('sid');
        this.gid = Utils.getUrlParam('gid');


        this.getExtParams();

        //TODO: костыль, убрать отсюда
        MoneyFormat.gameCurrency = this.currency;
        window.OPWrapperService.localizations.currentLanguage = this.language;
        EntryPoint.localization = this.language;
    }

    getExtParams() {
        this.extParams = {};
        let re = /ext_params\[\w+]/gm;
        let data = null;
        while(data = re.exec(window.location.href)) {
            let param = data[0].split(/\[(.*)]/)[1];
            this.extParams[param] = Utils.getUrlParam(data[0]);
        }
    }
}
