import BaseEventManager from './baseEventManager';
import eEventTypes from './eEventTypes';
import { getUrlParam } from '../utils/url';
import { EMPostMessageTypes, EMReceiveMessageTypes } from '../platforms/everyMatrix/constants';
import { postMessageTypes, receiveMessageTypes } from '../messageManager/constants';

export default new class EventManager extends BaseEventManager {
  constructor() {
    super();
    this.types = eEventTypes;
    if (getUrlParam('pid') === '322') {
      this.receiveMessageTypes = EMReceiveMessageTypes;
      this.postMessageTypes = EMPostMessageTypes;
    } else {
      this.receiveMessageTypes = receiveMessageTypes;
      this.postMessageTypes = postMessageTypes;
    }
  }
}
