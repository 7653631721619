import BaseInfoItem from './BaseInfoItem';

export default class Clock extends BaseInfoItem{
  constructor(container, config) {
    super(container);
    // this.element = document.createElement('span');
    this.view.classList.add('opClock');
    // container.insertAdjacentElement('beforeend', this.element);

    // const properties = ['width', 'height', 'top', 'left', 'right', 'bottom', 'background', 'color', 'font-size', 'border-radius'];
    // for (let property of properties) {
    //   if (config.hasOwnProperty(property)) this.element.style.setProperty(property, config[property]);
    // }

    setInterval(this._updateTime.bind(this), 1000);
  }

  _updateTime() {
    this.view.innerText = new Date().toLocaleTimeString();
  }
}
