import { SocketController } from './ws';
import eBaseWS from './eBaseWS';

export default class BaseManagerWS {
  constructor({ url, onMessage, reconnectTimeoutDelay = (5 * 60000), authorizationData, autoReconnect = true }) {
    this.reconnectTimeout = null;
    this.reconnectTimeoutDelay = reconnectTimeoutDelay;
    this.socketUrl = url;
    this.authorizationData = authorizationData;
    this.autoReconnect = autoReconnect;

    this.send = this.send.bind(this);
    this.disconnect = this.disconnect.bind(this);
    this.onMessage = this.onMessage.bind(this);
    this.onOpen = this.onOpen.bind(this);
    this.onClose = this.onClose.bind(this);
    this.externalOnMessage = onMessage;

    this.connect();
  }

  connect() {
    this.ws = new SocketController({ url: this.socketUrl, onMessage: this.onMessage, onOpen: this.onOpen, onClose: this.onClose, authorizationData: this.authorizationData });
  }

  async onMessage(wsIncome) {
    this.restartReconnectTimeout();

    const { id } = wsIncome;

    if (id === eBaseWS.EBWS_PING) {
      this.pong();
    }

    if (this.externalOnMessage) this.externalOnMessage(wsIncome);
  }

  pong() {
    this.send({ 'id': eBaseWS.EBWS_PONG });
    this.restartReconnectTimeout();
  }

  restartReconnectTimeout() {
    this.dropReconnectTimeout();
    this.startReconnectTimeout();
  }

  onOpen() {
    this.startReconnectTimeout();
  }

  onClose() {
    this.dropReconnectTimeout();
  }

  startReconnectTimeout() {
    if (this.autoReconnect) {
      this.reconnectTimeout = setTimeout(this.reconnect.bind(this), this.reconnectTimeoutDelay);
    }
  }

  dropReconnectTimeout() {
    if (!this.reconnectTimeout) return;
    clearTimeout(this.reconnectTimeout);
    this.reconnectTimeout = null;
  }

  reconnect() {
    this.ws.reconnect(this.authorizationData);
  }

  send(message) {
    return this.ws.send(message);
  }

  disconnect() {
    this.dropReconnectTimeout();
    this.ws.close();
  }
}
