import BaseTournamentController from '../baseTournamentController';
import {OP_WRAPPER_LOCALIZATION_PREFIX} from "../../../../../utils/constants";

export default class ControllerNominationsView extends BaseTournamentController {
  constructor(props) {
    super(props);
    this._eElementsTypes = {
      EET_LIST_CONTAINER: 'list_container',
    };

    this._rows = [];
  }

  _onDataUpdated() {
    super._onDataUpdated();
    if (!this._data) return console.error('No data for nominations');
    this._updateList(this._data.nominations, this._data.currency);
  }

  _updateList(list, currency) {
    const container = this.interactiveElements[this._eElementsTypes.EET_LIST_CONTAINER];
    const newRows = [];

    if (list.length < this._rows.length) {
      const rowsToRemove = this._rows.splice(list.length);
      rowsToRemove.forEach(row => row.remove());
    }

    for (let i = 0; i < list.length; i++) {
      const item = list[i];
      const row = this._rows[i];

      if (row) {
        this._updateRow(row, item, currency);
      } else {
        const newRow = this._createRow(item, currency);
        this._rows.push(newRow);
        newRows.push(newRow);
      }
    }

    if (newRows.length) {
      container.append(...newRows);
    }
  }

  _createRow({ nomination, reward }, currency) {
    const row = document.createElement('div');
    row.classList.add('system-ui-container__tournaments__stat_row');

    row.innerHTML = `<span class="system-ui-container__tournaments__stat_cell">
                       ${this._getLocalization(OP_WRAPPER_LOCALIZATION_PREFIX.concat('.tournaments.nominations.', nomination))}
                     </span>
                     <span class="system-ui-container__tournaments__stat_cell">
                       ${reward} ${currency}
                     </span>`;

    return row;
  }

  _updateRow(row, data, currency) {
    const [nominationSpan, rewardSpan] = row.querySelectorAll('span');
    const { nomination, reward } = data;
    nominationSpan.innerText = this._getLocalization(OP_WRAPPER_LOCALIZATION_PREFIX.concat('.tournaments.nominations.', nomination));
    rewardSpan.innerText = `${reward} ${currency}`;
  }

  beforeRemove() {
    super.beforeRemove();
    this._rows = [];
  }

  get dataSelector() {
    return (data) => ({
      nominations: data.data.nominations,
      currency: data.details.currency ? data.details.currency.toUpperCase() : 'EUR'
    });
  }

  _getMarkup() {
    return `<div id=${this.interactiveElementsIds[this._eElementsTypes.EET_LIST_CONTAINER]} class="system-ui-container__tournaments__stat_list tournament_nominations">
            </div>`
  }
}
