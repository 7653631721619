import { getUrlParam } from '../utils/url';

const api = getUrlParam('api');
if (api && !/.stage/.test(api) && !/.dev/.test(api)) {
  // Global site tag (gtag.js) - Google Analytics
  if (getUrlParam('pid') === '1') {
    const googleAnalyticsScript = document.createElement('script');
    googleAnalyticsScript.async = true;
    googleAnalyticsScript.src = 'https://www.googletagmanager.com/gtag/js?id=G-LVFG6W832F';
    document.documentElement.append(googleAnalyticsScript);

    window.dataLayer = window.dataLayer || [];
    function gtag(){window.dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', 'G-LVFG6W832F');

  } else {
    const googleAnalyticsScript = document.createElement('script');
    googleAnalyticsScript.async = true;
    googleAnalyticsScript.src = 'https://www.googletagmanager.com/gtag/js?id=G-3Q3K7Y2D5L';
    document.documentElement.append(googleAnalyticsScript);

    window.dataLayer = window.dataLayer || [];
    function gtag(){window.dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', 'G-3Q3K7Y2D5L');

  }
  //
}
