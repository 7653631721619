import FlashLib from "flashlib_onlyplay";
import EntryPoint from '../libs/game-engine/src/EntryPoint';
import GlobalDispatcher from '../libs/game-engine/src/events/GlobalDispatcher';

export default class LogoContainer extends FlashLib.MovieClip {
    constructor(data, displayData) {
        super(data, displayData);
        this.addListeners();
    }

    changeState(value) {
        const width = Math.min(window.innerWidth, window.outerWidth);
        const height = Math.min(window.innerHeight, window.outerHeight);
        if (value === 2 && EntryPoint.mobile &&  height > width) {
            this.goToFrame(3);
        } else {
            this.goToFrame(value);
        }
    }

    addListeners() {
        window.OPWrapperService.eventManager.add(
          window.OPWrapperService.eventManager.types.EET_SCALE_MANAGER_RESIZE,
          this.onGameResized,
          this
        );
    }

    onGameResized(data) {
        if ( this.currentFrameIndex === 2 && EntryPoint.mobile && data.orientation === 'V') {
            this.goToFrame(3);
        } else if (this.currentFrameIndex === 3 && EntryPoint.mobile &&  data.orientation === 'H')  {
            this.goToFrame(2);
        }
    }

}
