export const postMessageTypes = {
  PMT_LOAD_PROGRESS: 'loadProgress',
  PMT_LOAD_COMPLETED: 'loadCompleted',
  PMT_ROUND_START: 'roundStart',
  PMT_ROUND_END: 'roundEnd',
  PMT_BET_CHANGE: 'betChange',
  PMT_CLOSED: 'closed',
  PMT_ERROR_MESSAGE: 'errorMessage',
};

export const receiveMessageTypes = {
  RMT_UPDATE_BALANCE: 'updateBalance',
  RMT_STOP_AUTO_SPINS: 'stopAutoSpins',
  RMT_SET_AUDIO: 'setAudio',
  RMT_SHOW_HELP: 'showHelp',
  RMT_TOGGLE_PAYTABLE: 'togglePaytable',
};

 export const messageConfig = {
   loadProgress: ['progress'],
   loadCompleted: ['betAmount', 'balance', 'currency'],
   roundStart: ['balance', 'currency'],
   roundEnd: ['balance', 'currency'],
   betChange: ['betAmount', 'currency'],
   closed: ['pid', 'sid'],
   errorMessage: ['code', 'message']
}
