export const shadowText = {
  dropShadow: true,
  dropShadowAlpha: 0.8,
  dropShadowBlur: 4
};

export const whiteYellowOrangePurchase = {
  fill: [
    'white',
    'yellow',
    '#ffa200'
  ],
  fillGradientStops: [
    0.4,
    0.5,
    0.7
  ]
};
