import BaseInfoItem from '../BaseInfoItem';
import eGameStateTypes from './eGameStateTypes';
import LocalizationManager from '../../../i18n/LocalizationManager';

export default class GameState extends BaseInfoItem {
  constructor(container) {
    super(container);
    this.localizeGameStatesPath = this.baseLocalizePath + '.gameStates.'
    this.setGameState(eGameStateTypes.EGST_REGULAR)
  }
  setGameState(state) {
    this.view.innerText = `${LocalizationManager.getLocalizedText(this.baseLocalizePath + '.gameStateTitle')} ${LocalizationManager.getLocalizedText(this.localizeGameStatesPath + state)}`
  }
}
