export default class Event {
  constructor() {
    this._listeners = [];
    this._processingIndex = null;
  }

  add(listener) {
    if (!listener) {
      console.error('listener is undefined');
      console.trace();
      return;
    }
    this._listeners.push(listener);
    return listener;
  }

  remove(listener) {
    const listenerIndex = this._listeners.indexOf(listener);
    if (listenerIndex === -1) return;

    if (this._processingIndex !== null && listenerIndex <= this._processingIndex) {
      this._processingIndex--;
    }

    this._listeners.splice(listenerIndex, 1);
  }

  call(...args) {
    for (this._processingIndex = 0; this._processingIndex < this._listeners.length; this._processingIndex++) {
      this._listeners[this._processingIndex](...args);
    }

    this._processingIndex = null;
  }
}
