import BaseSlotLine from 'Engine/base/lines/BaseSlotLine';
import Animation from "Engine/base/animation/Animation";
import {gsap, Linear} from "gsap";

export default class WinLineAnimated extends BaseSlotLine {
    constructor(data, displayData) {
        super(data, displayData);
        this.positions = [
            {x: 550, y: -120},
            {x: 550, y: 322},
            {x: 550, y: 100},
            {x: 550, y: 322},
            {x: 550, y: 322},
            {x: 550, y: 100},
            {x: 550, y: 100},
            {x: 550, y: 322},
            {x: 550, y: 322},
            {x: 550, y: 322},
        ];

        this.createTweens();
    }

    init() {
        super.init();
        this.animation = new Animation('lines_spine');
        this.addChild(this.animation);
    }

    createTweens() {
        this.tween = gsap.fromTo(this, {duration: 1, alpha:0}, {alpha:1, ease:Linear.easeNone, repeat:1, yoyo:true, paused:true});
    }

    show(winning) {
        super.show();
        this.goToFrame(1);

        this.animation.x = this.positions[this.id].x;
        this.animation.y = this.positions[this.id].y;
        this.animation.play(true, `${this.id + 1}`);
        // this.tween.play(0);
        // this.play(false, false, 24);
    }

    hide() {
        this.animation.stop();
        super.hide();
        //this.tween.pause();
        //this.stop();
    }
}
