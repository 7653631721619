export default class Queue {
  constructor(executionCallback) {
    this._queue = [];
    this._inProgress = false;
    this._paused = false;
    this._resolvePause = null;
    this._pausePromise = null;
    this._executionCallback = executionCallback;

    this._resolveQueueExecution = null;
    this._queueExecutionPromise = null;
  }

  add(item) {
    this._queue.push(item);
    this._tryStart();
  }

  remove(item) {
    this._queue = this._queue.filter(el => el !== item);
  }

  contains(item) {
    return this._queue.some(el => el === item);
  }

  _tryStart() {
    if (this._paused || this._inProgress || !this._queue.length) return;
    this._start();
  }

  async _start() {
    this._inProgress = true;
    while (this._queue.length) {
      if (this._paused) await this._pausePromise;

      const item = this._queue.shift();
      await this._execute(item);
    }

    this._inProgress = false;
  }

  async _execute(item) {
    this._tryToCreateQueuePromise();
    if (this._executionCallback) await this._executionCallback(item);
    this._tryToResolveQueuePromise();
  }

  _tryToCreateQueuePromise() {
    if (!this._queueExecutionPromise) this._queueExecutionPromise = new Promise(resolve => {
      this._resolveQueueExecution = () => {
        this._queueExecutionPromise = null;
        this._resolveQueueExecution = null;
        resolve();
      }
    })
  }

  _tryToResolveQueuePromise() {
    if (!this.length) this._resolveQueueExecution();
  }

  pause() {
    if (this._paused) return;

    this._paused = true;
    this._pausePromise = new Promise(resolve => {
      this._resolvePause = resolve;
    })
  }

  continue() {
    this._paused = false;
    this._resolvePause && this._resolvePause();
    this._tryStart();
  }

  get length() {
    return this._queue.length;
  }

  get executionPromise() {
    return this._queueExecutionPromise;
  }
}
