import BasePlayerUi from '../player/basePlayerUI';

export default class LandscapeUi extends BasePlayerUi {
  constructor({ config, partnerConfig }) {
    super({ config, partnerConfig });

    this._config = {
     ...this._config,
      offsets: {
        right_panel: {
          top: 0,
          left: 0,
        },
        bottom_panel: {
          top: 0,
          left: 0,
        },
        tournaments_panel: {
          top: 0,
          left: 0,
        }
      }
    };

    if (config && config.offsets) {
      this._config.offsets.right_panel = { ...this._config.offsets.right_panel, ...config.offsets.right_panel };
      this._config.offsets.bottom_panel = { ...this._config.offsets.bottom_panel, ...config.offsets.bottom_panel };
      this._config.offsets.tournaments_panel = { ...this._config.offsets.tournaments_panel, ...config.offsets.tournaments_panel };
    }

    this.init();
  }

  _getConstantMarkup() {
    return `<div class="ui-container__tournaments_label_container" data-orientation="landscape" style="top: ${this._config.offsets.tournaments_panel.top}px; left: ${this._config.offsets.tournaments_panel.left}px">
      <div id=${this.controllersContainersIds[this.controllerTypes.ECT_TOURNAMENTS_LABEL]}>
      </div>
    </div>`
  }

  _getMarkup() {
    return `
<!--    modals    -->
    <div class="ui-container__bet_selector_container" data-orientation="landscape" style="padding: 0 ${-this._config.offsets.right_panel.left}px ${-this._config.offsets.bottom_panel.top}px 0">
      <div id=${this.controllersContainersIds[this.controllerTypes.ECT_BET_SELECTOR_TABLE]}>
      </div>
    </div>
    
    ${this._getConstantMarkup()}
<!--    controls    -->
    <div class="ui-container__control_buttons">
      <div class="ui-container__control_buttons_container ui-container__panel" style="top: ${this._config.offsets.right_panel.top}px; left: ${this._config.offsets.right_panel.left}px">
        <!--    fullscreen    -->
        <div id=${this.controllersContainersIds[this.controllerTypes.ECT_FULL_SCREEN]}>
        </div>
        <!--    sound    -->
        <div id=${this.controllersContainersIds[this.controllerTypes.ECT_SOUND]}>
        </div>
        <!--    autoplay    -->
        <div id=${this.controllersContainersIds[this.controllerTypes.ECT_AUTO_SPIN]}>
        </div>
        <!--    spin    -->
        <div id=${this.controllersContainersIds[this.controllerTypes.ECT_SPIN]}>
        </div>
        <!--    max bet   -->
        <div id=${this.controllersContainersIds[this.controllerTypes.ECT_MAX_BET]}>
        </div>
      </div>
    </div>

<!--    panels    -->
    <div class="ui-container__control_panels">
      <div class="ui-container__control_panels_container" style="top: ${this._config.offsets.bottom_panel.top}px; left: ${this._config.offsets.bottom_panel.left}px">
        <div class="ui-container__control_panels_container__main ui-container__panel">
          <!--      menu button    -->
          <div id=${this.controllersContainersIds[this.controllerTypes.ECT_MENU]} data-orientation="landscape">
          </div>
          <!--      info    -->
          <div id=${this.controllersContainersIds[this.controllerTypes.ECT_INFO]}>
          </div>
          <!--      balance     -->
          <div id=${this.controllersContainersIds[this.controllerTypes.ECT_BALANCE]}>
          </div>
          <!--      win / last win     -->
          <div id=${this.controllersContainersIds[this.controllerTypes.ECT_WIN]}>
          </div>
          <!--      bet     -->
            <div id=${this.controllersContainersIds[this.controllerTypes.ECT_BET]}>
            </div>
        </div>
        <!--      coins button     -->
        <div class="ui-container__control_panels_container__sub ui-container__panel">
          <div id=${this.controllersContainersIds[this.controllerTypes.ECT_BET_SELECT]}>
          </div>
        </div>
        <!--     total win     -->
        <div class="ui-container__control_panels_container__sub ui-container__control_panels_container__total_win">
          <div id=${this.controllersContainersIds[this.controllerTypes.ECT_TOTAL_WIN]}>
          </div>
        </div>
      
      </div>
    </div>`
  }
}
