import FlashLib from "flashlib_onlyplay";
import GlobalDispatcher from "Engine/events/GlobalDispatcher";
import EntryPoint from "Engine/EntryPoint";

export default class ExpandingSymbolsContainer extends FlashLib.MovieClip {
    constructor(data, displayData) {
        super(data, displayData);

        this.containers = [];
        this.symbols = [];
        this.positions = [];
        this.placeIndex = -1;
        this.delay = 250;
        this.timeout = null;

        this.init();
        this.addListeners();
    }

    init() {
        let configData = EntryPoint.configData;
        let reelsConfig = configData.reels;
        for (let i = 0; i < reelsConfig.reelsCount; i++) {
            for (let j = 0; j < reelsConfig.symbolsInReel; j++) {
                this[`place_${i}_${j}`] = this.getChildByName(`place_${i}_${j}`);
            }
        }
    }

    addListeners() {
        GlobalDispatcher.add('stateStart:expandSymbolsState', this.onShowExpandedWinStateStart, this);
        GlobalDispatcher.add('stateEnd:expandSymbolsState', this.onExpandSymbolsStateEnd, this);
        GlobalDispatcher.add('stateEnd:showExpandedWinState', this.onShowExpandedWinStateEnd, this);
    }

    onShowExpandedWinStateStart() {
        this.positions = [];
        this.placeIndex = -1;
        EntryPoint.GameModel.specialData.positions.forEach((path) => {
            for (let i = 0; i < 3; i++) {
                this.positions.push([path[0], i]);
            }
        });
        this.placeNextSymbol();
    }

    placeNextSymbol() {
        this.placeIndex++;
        if (this.placeIndex < this.positions.length) {
            this.placeSymbol(this.placeIndex);
            this.timeout = setTimeout(() => {
                this.placeNextSymbol(this.placeIndex);
            }, this.delay);
        } else {
            GlobalDispatcher.dispatch('allExpandedSymbolShowed');
        }
    }

    placeSymbol(index) {
        let path = this.positions[index];
        const container = this[`place_${path[0]}_${path[1]}`];
        let symbol = FlashLib.createItemFromLibrary(`Symbol`, 'GameFlashLib');
        symbol.changeSymbol(EntryPoint.GameModel.specialData.symbol);

        symbol.x = -symbol._width / 2;
        symbol.y = -symbol._height / 2 - 1;
        container.goToFrame(2);
        container.addChild(symbol);
        this.symbols.push(symbol);
        this.containers.push(container);
    }

    onExpandSymbolsStateEnd() {
        clearTimeout(this.timeout);
        for (let i = this.placeIndex + 1; i < this.positions.length; i++) {
            this.placeSymbol(i);
        }
        this.reset();
    }

    onShowExpandedWinStateEnd() {
        this.reset();
    }

    reset() {
        clearTimeout(this.timeout);
        this.symbols.forEach((symbol) => {
            symbol.destroy({children: true});
        });
        this.containers.forEach(el => el.goToFrame(1));
        this.containers = [];
        this.symbols = [];
        this.positions = [];
        this.placeIndex = -1;
        //this.removeChildren();
    }
}
