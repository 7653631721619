export default {
  ESI_WILD_BOOK: 1,
  ESI_INGOT: 2,
  ESI_LANTERN: 3,
  ESI_FAN: 4,
  ESI_BAMBOO: 5,
  ESI_A: 6,
  ESI_K: 7,
  ESI_Q: 8,
  ESI_J: 9,
  ESI_10: 10
}
