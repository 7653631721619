import gameConfig from '../../assets/config';
import starTexture from '../../assets/images/star_particle_fred_small.png';
import dotTexture from '../../assets/images/dot.png';

import UbuntuBold from '../../assets/fonts/ubuntu/UBUNTU-B.TTF';
import UbuntuRegular from '../../assets/fonts/ubuntu/UBUNTU-R.TTF';
import InterExtraBold from '../../assets/fonts/inter/Inter-ExtraBold.woff2';
import InterSemiBold from '../../assets/fonts/inter/Inter-SemiBold.woff2';
import { getAssetsScale } from 'Engine/utils/getAssetsScale';

import flashlib from '../../assets/exported/gameMobile/FlashLib.json';
import { soundsPreloaderConfig } from '../enums/eSounds';
import Config from '../libs/op-wrapper-service/scaleManager/Config';
import animationTypes from '../enums/animationTypes';

const availableScales = ['0.7', '0.75', '0.8', '0.9'];
const scale = getAssetsScale
({
  availableScales,
  safeWidthPortrait: Config.SAFE_WIDTH_PORTRAIT,
  safeHeightPortrait: Config.SAFE_HEIGHT_PORTRAIT,
  safeWidthLandscape: Config.SAFE_WIDTH_LANDSCAPE,
  safeHeightLandscape: Config.SAFE_HEIGHT_LANDSCAPE,
})

const assets = `flashlib/gameMobile/AssetsCombined@${scale}x.json`;


export default {
  assets: [
    { name: 'GameAssets', path: assets, extension: 'json' },
    { name: 'GameFlashLib', path: flashlib, extension: 'json' },
    { name: 'gameConfig', path: gameConfig, extension: 'json' },
    { name: animationTypes.SYMBOLS, path: 'animations/symbols/book_of_bamboo.json', extension: 'json' },
    {
      name: animationTypes.PURCHASE_BUTTON,
      path: 'animations/purchase/bonus_purchase_button/button_bones_pro.json',
      extension: 'json'
    },
    { name: animationTypes.LINES_SPINE, path: 'animations/winlines/winlines_pro.json', extension: 'json' },
    { name: animationTypes.FREE_SPINS_WINDOW_SPINE, path: 'animations/freeSpinsBook/book_won.json', extension: 'json' },
    { name: animationTypes.FREE_SPINS_SAKURA, path: 'animations/freeSpinsSakura/b_o_p_freespins_sakura_pro.json', extension: 'json' },
    { name: 'bob-font', path: 'fonts/bob-font/bob-font.xml', extension: 'xml' },
    { name: 'starsParticle', path: starTexture, extension: 'png' },
    { name: 'dotParticle', path: dotTexture, extension: 'png' }
  ],
  delayedAssets: [
    { name: animationTypes.BIG_WIN, path: 'animations/bigWins/win_screens.json', extension: 'json' },
    { name: animationTypes.COIN, path: 'animations/coins/skeleton.json', extension: 'json' },
    { name: animationTypes.ANTISIPATOR, path: 'animations/antisipator/antisipator_pro.json', extension: 'json' },
    { name: animationTypes.PURCHASE_ANIMATION, path: 'animations/purchase/bonus_purchase/purchase_bonuses.json', extension: 'json' },
  ],
  fonts: [
    {
      name: 'UbuntuBold',
      path: UbuntuBold,
    },
    {
      name: 'UbuntuRegular',
      path: UbuntuRegular,
    },
    {
      name: 'InterExtraBold',
      path: InterExtraBold,
    },
    {
      name: 'InterSemiBold',
      path: InterSemiBold,
    },
  ],
  sounds: soundsPreloaderConfig,
}
