import FlashLib from 'flashlib_onlyplay';

export default class LocalizeTextField extends FlashLib.TextField {
    constructor(data, displayData) {
        super(data, displayData);

        const prefferedFontFamily = this.style.fontFamily;
        let fallback = 'InterExtraBold';
        if (/regular|medium/i.test(prefferedFontFamily)) fallback = 'InterSemiBold';

        this.style.fontFamily = [ prefferedFontFamily, fallback ];
        this.localizationManager = window.OPWrapperService.localizations
        this.localizationManager.addTextField(this);
    }

    setTextParams(...params) {
        this.text = this.localizationManager.getLocalizedText(this.name, params);
    }

    destroy(options) {
        this.localizationManager.removeTextField(this);
        super.destroy(options);
    }
}
